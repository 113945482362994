import { createStore, applyMiddleware, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createLogger } from 'redux-logger';
import { environment } from '../configs/environments';
import { vAgent } from './reducers';
import aiResultScore from './reducers/aiResultScore'
import examResultScore from './reducers/examResultScore'
import startRolePlay from './reducers/startRolePlay'
import startExam from './reducers/startExam'
import startChat from './reducers/startChat'
import sttResult from "./reducers/speechToTextResult";
import logout from './reducers/logout'
import maintenanceScreen from './reducers/maintenanceScreen'

const logger = createLogger({});

let middleware = [];

if (environment === 'development') {
    middleware = [...middleware, logger];
}

const persistConfig = {
    key: 'root',
    storage: storage,
    blacklist: []
}

const rootReducers = combineReducers({
    vAgent,
    aiResultScore,
    examResultScore,
    startRolePlay,
    startExam,
    startChat,
    sttResult,
    logout,
    maintenanceScreen
})

const persistreducer = persistReducer(persistConfig, rootReducers);

const store = createStore(persistreducer, applyMiddleware(...middleware));

export const persistor = persistStore(store);

export default store;
