import React from 'react';

import styles from './styles.module.css';

const GeneralTextbox = ({ placeholder, className, style, onChange, text, inputtype, autoFocus, id, icon, onKeyPress, disabled,required = false,accept,name }) => {
    return (
        <div className={`${styles.general_textbox} ${className}`}  style={style}>
            {disabled ?
                <input id={id} name={name} className={`${styles.input} ${className}`}  style={style} disabled placeholder={placeholder} onChange={onChange} value={text} type={inputtype} autoFocus={autoFocus} onKeyPress={onKeyPress} />
                : <input id={id} name={name} className={`${styles.input} ${className}`}   style={style} placeholder={placeholder} onChange={onChange} value={text} type={inputtype} autoFocus={autoFocus} onKeyPress={onKeyPress} required={required} accept={accept}/>
            }
            <img src={icon} className={styles.icon} id={`${id}_icon`} alt="icon" />
        </div>
    )
}

export default GeneralTextbox;