import ExcelJS from "exceljs";
import encoding from "encoding-japanese";
import moment from 'moment'
import GeneralButton from '../../../components/Buttons/GeneralButton'
const MaintenanceScreenExport = ({selectedFileExtension,columnsData,data,fileName}) => {
    const dateFormat = "YYYY-MM-DD"
    var currentDate = new Date();
    let currentDateFormat = moment(currentDate).format(dateFormat);
    const handlerClickDownloadButton = async (e,format,charcode) => {
            e.preventDefault();
        
            const workbook = new ExcelJS.Workbook();
            workbook.addWorksheet("sheet1");
            const worksheet = workbook.getWorksheet("sheet1");
        
            worksheet.columns = columnsData;
            worksheet.addRows(data);
        
            const uint8Array =
            format === "xlsx"
                ? await workbook.xlsx.writeBuffer()
                : charcode === "UTF8"
                ? await workbook.csv.writeBuffer()
                : new Uint8Array(
                    encoding.convert(await workbook.csv.writeBuffer(), {
                    from: "UTF8",
                    to: "SJIS"
                    })
                );
            const blob = new Blob([uint8Array], {
            type: "application/octet-binary"
            });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `${fileName} ${currentDateFormat}.` + format;
            a.click();
            a.remove();
        };
    return (
        <div>
            <GeneralButton title="出力" className="cmn-btn font-weight-normal" onClick={(e) => handlerClickDownloadButton(e, selectedFileExtension.name, "SJIS")}/>
        </div>
    )
}
export default MaintenanceScreenExport;