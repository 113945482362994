import Moment from 'moment';
export function changeDateformat(date) {
    return date.replaceAll('-', '/')
}

export function getLastString(pdf, syntax = '/') {
    let pos = pdf.lastIndexOf(syntax);
    return pdf.substring(pos + 1, pdf.length)

}

export function geFileExtension(filename) {
    return filename.split('.').pop();
}

export function padLeadingZeros(num, size) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
}

export function changeLeadingZeroInTime(data) {
    let pos = data.lastIndexOf(':');
    let min = data.substring(0, pos - 1)
    let sec = data.substring(pos + 1)
    min = padLeadingZeros(min, 2)
    sec = padLeadingZeros(sec, 2)
    return min + ":" + sec;



}
export function timeFormat(mmss) {
    //no need this transformation in UI going forward
    return mmss;
}

export function stringFormat(str) {
    var split_string = str && str.split('(');
    var result = split_string && split_string.map((value, key) => <span key={key}>{key !== 0 ? "(" : ""}{value}<br /></span>)
    return result;
}

export function split(url, syntax = ',') {
    return url.split(syntax)
}

export function longestString(arr) {
    var longest = arr && arr.reduce(function (a, b) { return a.name.length > b.name.length ? a : b; }).name.length;
    return longest;
}

export function max(arr) {
    var max = arr && arr.reduce(function (a, b) { return parseInt(a.name) > parseInt(b.name) ? a : b; }).name;
    return parseInt(max);
}

export function defaultCriteriaOption(value) {
    switch (value) {
        case 1:
            return [{ id: 1, name: "ー" }, { id: 2, name: "x" }, { id: 4, name: "〇" }];
            break;
        case 2:
            return [{ id: 2, name: "x" }, { id: 4, name: "〇" }];
            break;
        case 3:
            return [{ id: 2, name: "x" }, { id: 3, name: "△" }, { id: 4, name: "〇" }];
            break;
        case 4:
            return [{ id: 3, name: "△" }, { id: 4, name: "〇" }];
            break;

        default:
            break;
    }
}

export function getASCodeCompany(ascode) {
    if (ascode !== undefined) {
        var split_company_name = ascode.split('/');
        return split_company_name;
    } else {
        return ['', ''];
    }

}

export function formatYearMonth(data) {
    if (data !== null && data !== 0) {
        data = data.toString();
        var year = parseInt(data.slice(0, 4));
        var month = parseInt(data.slice(4));
        return { year: year, month: month }
    } else {
        return { year: 0, month: 0 };
    }

}
export function format(data) {
    if (data !== null) {
        data = data.toString();
        var year = parseInt(data.slice(0, 4));
        var month = parseInt(data.slice(4));
        return { year: year, month: month }
    } else {
        return { year: 0, month: 0 };
    }

}
export function joinDateFormatYearMonth(data) {
    if (data !== null) {
        data = data.split('-');
        var year = parseInt(data[0]);
        var month = parseInt(data[1]);
        return { year: year, month: month }
    } else {
        return { year: 0, month: 0 };
    }

}
export function joinDateFomat(data) {
    if (data !== null) {
        data = data.split('-');
        var year = parseInt(data[0]);
        var month = padLeadingZeros(parseInt(data[1]), 2);
        return year + '/' + month
    } else {
        return null;
    }
}

export function trainingPlanYearMonthFormat(data) {
    if (data !== null && data !== 0) {
        data = data.toString();
        var year = data.slice(0, 4);
        var month = data.slice(4);
        return year + '/' + month
    } else {
        return null;
    }

}

export function minOrMax(arr, flag = true) {
    var data = 0;
    if (flag) {
        data = arr && arr.reduce(function (a, b) { return parseInt(a.sortingOrder) > parseInt(b.sortingOrder) ? a : b; }).sortingOrder
    } else {
        data = arr && arr.reduce(function (a, b) { return parseInt(a.sortingOrder) < parseInt(b.sortingOrder) ? a : b; }).sortingOrder
    }
    return data;
}

export function isApigwEnv() {
    let domain = document.location.origin
    return domain.indexOf("platformerfuji") > -1
}
export function monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
}
export function getUTF8Text(text) {
    return (JSON.stringify(text)?.replace(/['"]+/g, ''))
}

export function changeMinutesFormat(startMinute, interval, dataLength) {
    let data = [];
    let startInterval = 10;
    for (let index = 0; index < dataLength; index++) {
        let minute = 0;
        if (startInterval <= interval) {
            minute = startInterval / 60;
        } else {
            minute = interval / 60;
        }
        data.push(startMinute + minute);
        startInterval += 10;
    }
    return data;
}

export function getPeriod(value) {
    switch (value) {
        case 1:
            return ['monthly', '月別'];
        case 2:
            return ['quarterly', '四半期'];
        case 3:
            return ['halfyearly', '半期'];
        case 4:
            return ['yearly', '年度'];
        default:
            break;
    }
}

export function getDurationTime(value, obj, text = 'from', syntax = false) {
    let time = '', limit = '';
    switch (value) {
        case 1:
            time = syntax ? obj.year + '-' + obj.month : obj.year + '' + zeroPad(obj.month, 2)
            return time;
        case 2:
            switch (obj.month) {
                case 1: limit = text === 'from' ? 1 : 3; break;
                case 2: limit = text === 'from' ? 4 : 6; break;
                case 3: limit = text === 'from' ? 7 : 9; break;
                case 4: limit = text === 'from' ? 10 : 12; break;
                default:
                    break;
            }
            time = syntax ? obj.year + '-' + limit : obj.year + '' + zeroPad(limit, 2)
            return time;
        case 3:
            switch (obj.month) {
                case 1: limit = text === 'from' ? 1 : 6; break;
                case 2: limit = text === 'from' ? 7 : 12; break;
                default:
                    break;
            }
            time = syntax ? obj.year + '-' + limit : obj.year + '' + zeroPad(limit, 2)
            return time;
        case 4:
            limit = text === 'from' ? 1 : 12;
            time = syntax ? obj.year + '-' + limit : obj.year + '' + zeroPad(limit, 2)
            return time;
        default:
            break;
    }
}

export function isEmpty(obj) {
    for (var prop in obj) {
        if (obj.hasOwnProperty(prop))
            return false;
    }
    return true;
}

export function sortByKey(unordered) {
    return Object.keys(unordered).sort().reduce(
        (obj, key) => {
            obj[key] = unordered[key];
            return obj;
        },
        {}
    );
}

export function getSumByKey(arr, key) {
    return arr.reduce((accumulator, current) => accumulator + Number(current[key]), 0)
}

export function zeroPad(number, count) {
    var my_string = '' + number;
    while (my_string.length < count) {
        my_string = '0' + my_string;
    }
    return my_string;
}

export function timeStampToDate(str) {
    var timestamp = parseInt(str, 10);
    var date = new Date(timestamp);
    return date.toISOString().substr(0, 10);
}

export function getUserSatus(value) {
    switch (value) {
        case 'Graduated':
            return '修了生';
        case 'Inactive':
            return '非アクティブ';
        default:
            break;
    }
}
export function groupBy(arr, prop) {
    const map = new Map(Array.from(arr, obj => [obj[prop], []]));
    arr.forEach(obj => map.get(obj[prop]).push(obj));
    return Array.from(map.values());
}
export function groupByKey(arr, prop) {
    return arr
        .reduce((hash, obj) => {
            if (obj[prop] === undefined) return hash;
            return Object.assign(hash, { [obj[prop]]: (hash[obj[prop]] || []).concat(obj) })
        }, {})
}
export function getCountOverall(data) {
   let countAsCode = groupBy(data,'ascode');
   let count = 0;
   countAsCode.map(item => {
       count += groupBy(item,'branchCode').length;
   })
   return count;
}
export function sumData(data, fieldName) {
    return Math.round(data.reduce((a, b) => a + (b[fieldName] || 0), 0));
}
export function sumDataByObject(data) {
    let totalArray = [];
    data.map(item => totalArray.push(parseInt(item.AF社員) + parseInt(item.AS管理者)))
    return (Math.max(...totalArray)).toFixed(2)
}
export function millisToHours(nanosec) {
    // let minutes = (ms / (1000 * 60)) % 60;
    // let hours = (ms / (1000 * 60 * 60)) % 24;
    let sec = nanosec / 1000000000.0;
    let hours = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
    return parseFloat((hours + minutes / 60).toFixed(2));
}

export function deleteCookie(name) {
    document.cookie = name + '=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie = name + '=; path=/apigw/va2roleplay/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export function isLoggedOut() {
    return document.location.pathname.includes('/Logout')
}

export function getLast13MonthsWithYear(month = 12) {
    var monthName = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
    var d = new Date();
    d.setDate(1);
    d.setMonth(d.getMonth() - 1);

    var res = [];
    for (let i = 0; i <= month; i++) {
        res.push({ month: monthName[d.getMonth()], year: d.getFullYear() });
        d.setMonth(d.getMonth() - 1);
    }
    return res;
}

export function getLast12MonthsInterval(value) {
    let limit = '', res = [];
    let months = getLast13MonthsWithYear(11);    
    let firstAndLastInterval = [months[0], months[months.length - 1]];
    switch (value) {
        case 1:
            return firstAndLastInterval && firstAndLastInterval;
        case 2:
            for (var i of [months[0]]) {
                if (i.month > 0 && i.month < 4) { limit = 1; }
                else if (i.month > 3 && i.month < 7) { limit = 2; }
                else if (i.month > 6 && i.month < 10) { limit = 3; }
                else if (i.month > 9) { limit = 4; }
                if (limit !== '') {
                    for (let index = 0; index < 4; index++) {
                        let diff = limit - index;
                        res.length === 0
                            ? res.push({ month: limit, year: i.year })
                            : res.push({ month: (diff === 0) ? 4 : (diff === -1) ? 3 : (diff === -2) ? 2 : (diff), year: (diff < 1) ? (i.year - 1) : i.year })
                    }
                }
            }
            return res.length > 0 && [res[0], res[res.length - 1]];
        case 3:
            for (var i of [months[0]]) {
                if (i.month > 0 && i.month < 7) { limit = 1; }
                else if (i.month > 6) { limit = 2; }
                if (limit !== '') {
                    for (let index = 0; index < 2; index++) {
                        let diff = limit - index;
                        res.length === 0
                            ? res.push({ month: limit, year: i.year })
                            : res.push({ month: (diff === 0) ? 2 : (diff), year: (diff < 1) ? (i.year - 1) : i.year })
                    }
                }
            }
            return res.length > 0 && res;
        case 4:
            return firstAndLastInterval && firstAndLastInterval;
        default:
            break;
    }
}
export function GetGenerateYear(selectedTimePeriod,toMonth,dataLength){
    let today = new Date();
    let fullYear = today.getFullYear();
    let allYear = [];
    // let limit = toMonth-1 === dataLength-1?5:6;
    let limit = 6;
    for (let index = 0; index < limit; index++) {
        const year = fullYear-index;
        allYear.push(year);
    }
    return allYear.reverse();
}
export function getLastPeriod(key,currentMonth,selectedTimePeriod){
   
    switch (selectedTimePeriod) {
        case 1:
            return key >= currentMonth? true:false;
            break;
        case 2:
            let findMonth;
            if (0 >= currentMonth <=2) {
                findMonth =0;
            }
            if (3 >= currentMonth <=5) {
                findMonth =1;
            }
            if (6 >= currentMonth <=8) {
                findMonth =2;
            }
            if (9 >= currentMonth <=11) {
                findMonth =3;
            }
            return key >= findMonth ? true:false;
            break;
        case 3:
            let findIndex;
            if (0 >= currentMonth <=5) {
                findIndex =0;
            }
            if (6 >= currentMonth <=11) {
                findIndex =1;
            }
            return key >= findIndex+1 ? true:false;
            break;
    
        default:
            break;
    }
}
export function getFirstPeriod(key,currentMonth,selectedTimePeriod){
   
    switch (selectedTimePeriod) {
        case 1:
            return key < currentMonth? true:false;
            break;
        case 2:
            let findMonth;
            if (0 >= currentMonth <=2) {
                findMonth =0;
            }
            if (3 >= currentMonth <=5) {
                findMonth =1;
            }
            if (6 >= currentMonth <=8) {
                findMonth =2;
            }
            if (9 >= currentMonth <=11) {
                findMonth =3;
            }
            return  key < findMonth ? true:false;
            break;
        case 3:
            let findIndex;
            if (0 >= currentMonth <=5) {
                findIndex =0;
            }
            if (6 >= currentMonth <=11) {
                findIndex =1;
            }
            return key < findIndex ? true:false;
            break;
    
        default:
            break;
    }
}