import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";
import Sidebar from "../../../components/Sidebar";
import LogoutButton from "../../../components/Buttons/LogoutButton";
import Breadcrumbs from "../../../components/Breadcrumbs";
import GeneralButton from "../../../components/Buttons/GeneralButton";
import classes from "../AgencyInformation/styles.module.css";
import {
  isAflacMember,
  isTrainerManagerOrTrainee,
} from "../../../configs/constants";
import { userInfoData, staticRoleData } from "../../../configs/staticData";
import BackButton from "../../../components/Buttons/BackButton";
import agencyInformationService from "../../../api/agencyInformation.service";
import { padLeadingZeros } from '../../../utils/General';

const PreviewBeforeDelete = ({ isOpen, access_token }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { state } = useLocation();
  const [reset, makeAllReset] = useState(false);
  const [importagencyData, setImportagencyData] = useState([]);
  useEffect(() => {
    //set preview data from state
    console.log("state", state);
    setImportagencyData(state?.data);
  }, []);

  const goPreviousPage = ()=>{
    let checkItem = state.data;
    history.push({
        pathname : state.previousPath,
        state : {stateCheckedItem : checkItem}
    })
}


  const deleteUserData = () => {
    try {
      const filteredId = importagencyData.reduce(
        (a, o) => (a.push(o.id), a),
        []
      );
      let params = { ids: filteredId };
      agencyInformationService
        .removeSelectedAgency(params)
        .then((res) => {
          if (res.status === 200) {
            history.push('/agencyInformation',{
              isAlert : true,
              message : res.data.message
          })

          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    } catch (error) {}
  };

  return (
    <>
      <Sidebar />
      <div
        id="main-content"
        className={`${isOpen ? "content-large" : "content-small"}`}
      >
        <div
          className="main-content-inr maintenanceScreen"
          id="maintenanceScreen"
        >
          <Row>
            <Col lg="12" className="cmn-logout-btn-sec">
              <h3 className="text-left d-flex justify-content-between">
              選択した代理店情報を削除
              </h3>
              <LogoutButton
                title={t("general.button.logout")}
                className={"font-14"}
                id="logout"
              />
            </Col>
          </Row>
          <Row>
            <div className="ml-2">
              <Breadcrumbs homeLink="MaintenanceScreen" />
            </div>
            <Col lg="12">
              <div className="cmn-bg-box">
                <div className="cmn-bg-box-inr mb-4">
                  <p>
                  選択した代理店を削除しますがよろしいですか?
                  </p>
                  <h4>概要</h4>
                  <ul>
                    <li>削除代理店数：{importagencyData.length}</li>
                  </ul>
                  <h4>削除対象代理店一覧</h4>
                  <table className="table table-bordered mb-0">
                    <thead className="cmn-table">
                      <tr>
                        <th scope="col" className="align-middle">
                          代理店コード 
                        </th>
                        <th scope="col" className="align-middle">
                          代理店名
                        </th>
                        <th scope="col" className="align-middle">
                          出先コード
                        </th>
                        <th scope="col" className="align-middle">
                          出先名
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {importagencyData.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{padLeadingZeros(item.companyCode, 7)}</td>
                            <td>{item.companyName}</td>
                            <td>{item.branchCode}</td>
                            <td>{item.branchName}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <Row>
                    <Col className="text-right m-3">
                      <BackButton
                        title="戻る"
                        className={`cmn-btn font-weight-normal mt-md-0 mt-2 mr-2  ${classes.btn_w_120}`}
                        onClick={goPreviousPage}
                      />
                      <GeneralButton
                        title="はい"
                        type="submit"
                        onClick={deleteUserData}
                        className={`cmn-btn font-weight-normal ${classes.btn_w_120}`}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

const stateToProps = (state) => {
  return {
    isOpen: state.vAgent.isOpen,
    access_token: state.vAgent.access_token,
  };
};
export default connect(stateToProps, null)(PreviewBeforeDelete);
