var encoding = require('encoding-japanese');
export function encodeBase64(params) {
    if (params) {
        return window.btoa(params);
    }
    return '';
}
export function UTF8ToShiftJs(str) {
    let strtoCode = encoding.stringToCode(str);
    var encoded = encoding.base64Encode(strtoCode);
    console.log('encoded',encoded);
    var isSJIS = encoding.detect(encoded, 'SJIS');
    console.log('isSJIS',isSJIS);
    if (isSJIS) {
        console.log('Encoding is SJIS');
      }
    return encoded;
  }