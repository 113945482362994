import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { Link, useHistory ,useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Sidebar from '../../../components/Sidebar';
import LogoutButton from '../../../components/Buttons/LogoutButton';
import GeneralTextbox from '../../../components/Textboxes/GeneralTextbox';
import { GeneralDropdown } from '../../../components/Dropdowns/GeneralDropdown';
import {staticRoleData} from '../../../configs/staticData'
import Breadcrumbs from '../../../components/Breadcrumbs';
import ErrorMessage from '../../../components/ErrorMessage';
import GeneralButton from '../../../components/Buttons/GeneralButton';
import BackButton from '../../../components/Buttons/BackButton';
import classes from '../UserInformation/styles.module.css';

import userInfoApi from '../../../api/userInformation.service';
import { isAflacMember, isTrainerManagerOrTrainee } from '../../../configs/constants';
import { isApigwEnv } from '../../../utils/General';
import Alert from '../components/Alert';
import store from '../../../store'

const UserInformationAdd = ({isOpen,access_token}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const  location = useLocation();
    const storeUserData = store.getState().maintenanceScreen.userData;
    const [reset, makeAllReset] = useState(false);
    const [roleData, setRoleData] = useState(staticRoleData)
    const [selectedRole, setSelectedRole] = useState()
    const [roleRequired, setRoleRequired] = useState(false)
    const [empId, setEmpId] = useState('')
    const [empIdRequired, setEmpIdRequired] = useState(false)
    const [userName, setuserName] = useState('')
    const [email, setEmail] = useState('')
    const [emailRequired, setEmailRequired] = useState(false)
    const [createMode, setCreateMode] = useState(
        location.pathname.includes("add_user_info")
    )
    const [updateUserId, setUpdateUserId] = useState()
    const [updateAlert, setUpdateAlert] = useState({isAlert : false,message : ''});
    const initialErrors ={
        role : "権限が設定されていません",
        empId : "募集人コードの入力が必要です。 ",
        upn : "UserPrincipalNameの入力が必要です。"
    }
    const [errors, setErrors] = useState(initialErrors)
    const [oldUserData, setOldUserData] = useState()

    useEffect(() => {
        window.scrollTo(0, 0)
        if (!isApigwEnv() && (access_token === '' || access_token === undefined)) {
            history.push("/Logout");
        }
        if (isTrainerManagerOrTrainee() || isAflacMember()) {
            makeAllReset(!reset)
            return
        }
    }, []);
    useEffect(() => {
        if (!createMode && storeUserData && storeUserData?.id) {
            setUpdateUserId(storeUserData?.id);
            setEmpId(storeUserData?.empId);
            setuserName(storeUserData?.userName);
            setEmail(storeUserData?.upn);
            setSelectedRole({id : storeUserData?.roleId,name : staticRoleData.find(({id}) => id === storeUserData.roleId)?.name})
        }
        let data ={
            "empId": storeUserData?.empId,
            "userName": storeUserData?.userName,
            "roleId": storeUserData?.roleId,
            "upn": storeUserData?.upn
          }
          setOldUserData(data)  
    }, [storeUserData]);
    const handleRole = (el) => {
        setSelectedRole({id : el.value,name : el.textContent})
    }
    const handleEmpIdChange = (event)=>{
        event.target.value.length > 0 && setEmpIdRequired(false)
        try {
            let params = {empId : event.target.value};
            let url = createMode ?'backend/maintenance/validation/':'backend/maintenance/validation/user/'+updateUserId;
            userInfoApi.checkValidation(url,params).then(res =>{
                console.log('res',res);
                if (res.status === 200) {
                    setEmpIdRequired(false);
                    setErrors({...errors,empId : "募集人コードの入力が必要です。"})
                }
            }).catch(error =>{
                console.log('error',error);
                if (error.response.status === 400) {
                    setEmpIdRequired(true);
                    setErrors({...errors,empId : error.response.data.empId})
                }
            })
        } catch (error) {
            console.log('error',error);
        }
        setEmpId(event.target.value);
    }
    const handleuserNameChange = (event)=>{
        setuserName(event.target.value);
    }
    const handleEmailChange = (event)=>{
        try {
            let params = {upn : event.target.value};
            let url = createMode ?'backend/maintenance/validation/':'backend/maintenance/validation/user/'+updateUserId;
            userInfoApi.checkValidation(url,params).then(res =>{
                console.log('res',res);
                if (res.status === 200) {
                    setEmailRequired(false);
                }
            }).catch(error =>{
                console.log('error',error);
                if (error.response.status === 400) {
                    setEmailRequired(true);
                    setErrors({...errors,upn : error.response.data.upn})
                }
            })
        } catch (error) {
            console.log('error',error);
        }
        setEmail(event.target.value);
    }
    const resetFormData = () =>{
        setEmpId('');
        setuserName('');
        setSelectedRole();
        setEmail('');
        setEmpIdRequired(false);
        setEmailRequired(false);
        setRoleRequired(false);
        setErrors(initialErrors);
    }

    const showUpdateMessage = (data) =>{
        if(oldUserData.empId === data.empId && oldUserData.userName === data.userName 
            && oldUserData.roleId === data.roleId && oldUserData.upn === data.upn){
            return true
        }
        return false
    }

    const checkEmpIdUpdate = (data) => {
        if(oldUserData.empId != data.empId){
            return (`募集人コード ${oldUserData?.empId == undefined ? '' : oldUserData?.empId} が ${data?.empId == undefined ? '' : data?.empId} に変更されました。\n`)
        }else{
            return ''
        }
    }

    const checkUserNameUpdate = (data) => {
        if(oldUserData.userName != data.userName){
            return (`募集人名 ${oldUserData?.userName == undefined ? '' : oldUserData?.userName } が ${data?.userName === undefined ? '' : data?.userName} に変更されました。\n`)
        }else{
            return ''
        }
    }

    const checkRoleUpdate = (data) => {
        if(oldUserData.roleId != data.roleId){
            let oldRoleData = staticRoleData.find(item => item.id === oldUserData.roleId)?.name
            let updateRoleData = staticRoleData.find(item => item.id === data.roleId)?.name
            return (`権限 ${oldRoleData} が ${updateRoleData} に変更されました。\n`)
        }else{
            return ''
        }
    }

    const checkUPNUpdate = (data) => {
        if(oldUserData.upn != data.upn){
            return (`UserPrincipalName ${oldUserData?.upn == undefined? '' : oldUserData?.upn} が ${data?.upn == undefined? '' : data?.upn} に変更されました。`)
        }else{
            return ''
        }
    }

    const handleSubmit = (type)=>(evt) => {
        evt.preventDefault();
       empId === ""  && setEmpIdRequired(true);
       email === ""  && setEmailRequired(true);
       selectedRole === undefined  && setRoleRequired(true);
        console.log('ddd',!empId && initialErrors.empId);
        setErrors({...errors,
            role :selectedRole === undefined && initialErrors.role,
            empId :(empId === "" && empIdRequired)? initialErrors.empId:errors.empId,
            upn :(email === "" && emailRequired) ? initialErrors.upn:errors.upn,
        })
        let data ={
            "empId": empId,
            "userName": userName,
            "roleId": selectedRole?.id,
            "upn": email
          }
        console.log('data',data);
        if (empId && email && selectedRole && !empIdRequired && !emailRequired) {
            //form submit api call
            console.log('create',createMode);
                if (createMode) {
                    try {
                        userInfoApi.create(data).then(res => {
                            if (res.status === 200) {
                                if (type === "keep") {
                                    history.push('/userInformation',{
                                        isAlert : true,
                                        message : res.data.message
                                    })
                                    setCreateMode(true);
                                } else if(type === "keepandanother") {
                                    resetFormData()
                                    setCreateMode(true);
                                    setUpdateAlert({isAlert : true,message : `募集人コード${data.empId}を追加しました。別のユーザー情報を以下から追加できます。`})
                                } else {
                                    setCreateMode(false);
                                    setUpdateAlert({isAlert : true,message : `募集人コード ${data.empId} を追加しました。以下で再度編集できます。`})
                                }
                                setOldUserData(data)
                                setEmailRequired(false);
                                setEmpIdRequired(false);
                                setRoleRequired(false);
                                setUpdateUserId(res.data.id)
                            }
                        }).catch(error => {
                            console.log('error =>',error.response);
                            if (error.response.status === 400 ) {
                                error.response?.data?.empId ? setEmpIdRequired(true):setEmpIdRequired(false);
                                error.response?.data?.upn ? setEmailRequired(true):setEmailRequired(false);
                                setErrors({...errors,
                                    empId : error.response?.data?.empId ?error.response?.data?.empId:initialErrors.empId,
                                    upn : error.response?.data?.upn ?error.response?.data?.upn:initialErrors.upn,
                                })
                            }
                        })
                    } catch (error) {
                        console.log('error',error);
                    }
                } else {
                    try {
                        userInfoApi.update(data,updateUserId).then(res => {
                            if (res.status === 200) {
                                if (type === "keep") {
                                    history.push('/userInformation',{
                                        isAlert : true,
                                        message : res.data.message
                                    })
                                    setCreateMode(true);
                                } else if(type === "keepandanother") {
                                    resetFormData()
                                    setCreateMode(true);
                                    setUpdateAlert({isAlert : true,message : showUpdateMessage(data) ? `${res.data?.message}` : `${checkEmpIdUpdate(data)} ${checkUserNameUpdate(data)} ${checkRoleUpdate(data)} ${checkUPNUpdate(data)} 別のユーザー情報を以下から追加できます。`})
                                    setOldUserData(data)
                                    history.push(
                                        {
                                        pathname :'/userInformation/add_user_info'
                                        }
                                    );
                                } else {
                                    setCreateMode(false);
                                    setUpdateAlert({isAlert : true,message : showUpdateMessage(data) ? `${res.data?.message}` : `${checkEmpIdUpdate(data)} ${checkUserNameUpdate(data)} ${checkRoleUpdate(data)} ${checkUPNUpdate(data)}  以下で再度編集できます。`})
                                    setOldUserData(data)
                                }
                                setEmailRequired(false);
                                setEmpIdRequired(false);
                                setRoleRequired(false); 
                            }
                        }).catch(error => {
                            console.log('error =>',error.response);
                            if (error.response.status === 400 ) {
                                error.response?.data?.empId ? setEmpIdRequired(true):setEmpIdRequired(false);
                                error.response?.data?.upn ? setEmailRequired(true):setEmailRequired(false);
                                setErrors({...errors,
                                    empId : error.response?.data?.empId ?error.response?.data?.empId:initialErrors.empId,
                                    upn : error.response?.data?.upn ?error.response?.data?.upn:initialErrors.upn,
                                })
                            }
                        })
                    } catch (error) {
                        console.log('error',error);
                    }
                }
            //reset data after submit
            // resetFormData();
        }
    }
    const handleDeleteData =() =>{
        let data = [
            storeUserData
        ]
        history.push({
            pathname: '/userInformation/edit_user_info/editpreviewdelete',
            state: {data:data, previousPath : history.location.pathname}
        });
    }
    console.log('empIdRequired',empIdRequired);
    console.log('errors',errors);
    return(
        <>
            <Sidebar />
            <div id="main-content" className={`${isOpen ? 'content-large' : 'content-small'}`}>
                <div className="main-content-inr maintenanceScreen" id="maintenanceScreen">
                    <Row>
                        <Col lg="12" className="cmn-logout-btn-sec">
                            <h3 className="text-left d-flex justify-content-between">
                                {
                                    createMode ?
                                    "ユーザー情報 を追加"
                                    :
                                    "ユーザー情報を変更"
                                }
                            </h3>
                            <LogoutButton title={t('general.button.logout')} className={'font-14'} id="logout"/>
                        </Col>
                    </Row>                   
                    <Row>
                        <Col xs="12" className="mb-3"><Breadcrumbs homeLink="MaintenanceScreen" createMode={createMode}/></Col>
                        <Col xl="8">
                            <div className="cmn-bg-box"> 
                                {
                                    updateAlert.isAlert &&
                                    <Alert message = {updateAlert.message} closeHandle ={()=>setUpdateAlert({...updateAlert,isAlert : false})} alertType="alert-success"/>
                                }     
                                <div className="cmn-bg-box-inr mb-4">
                                {(empIdRequired || emailRequired || roleRequired) && <ErrorMessage message={"下記のエラーを修正してください。"} className="mb-3"/>}
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group row">
                                        <label htmlFor="EmpIdLabel" className="col-sm-2 col-form-label">募集人コード:</label>
                                        <div className="col-sm-10">
                                            <GeneralTextbox text={empId} name="emp_id" onChange={handleEmpIdChange} id="emp_id" className={`${classes.textinput_size}`}/>
                                            {empIdRequired && <ErrorMessage message={errors.empId}/>}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="userNameLabel" className="col-sm-2 col-form-label">募集人名:</label>
                                        <div className="col-sm-10">
                                            <GeneralTextbox text={userName} name="user_name" onChange={handleuserNameChange} className={`${classes.textinput_size}`} id="user_name"/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="roleLabel" className="col-sm-2 col-form-label">権限:</label>
                                        <div className="col-sm-10">
                                            <GeneralDropdown
                                            isStatic={true}
                                            staticData={roleData}
                                            onSelect={(el) => handleRole(el)}
                                            selectedData={selectedRole && selectedRole.name}
                                            placeholder="----"
                                            dropdown_id="role_dropdown"
                                            className={`${classes.dropdown_padding} ${classes.input_h_40}`}/>
                                            {roleRequired && <ErrorMessage message={errors.role}/>}
                                        </div>  
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="EmailLabel" className={`${classes.upn_text_warp} col-sm-2 col-form-label `}>UserPrincipalName:</label>
                                        <div className="col-sm-10">
                                            <GeneralTextbox text={email} name="Email" onChange={handleEmailChange} className={`${classes.textinput_size}`} id="Email"/>
                                            {emailRequired && <ErrorMessage message={errors.upn}/>}
                                        </div>
                                    </div>
                                    <Row>
                                        <Col className={`${classes.btn_gp} text-md-right`}>
                                            <GeneralButton title="保存" type="submit" onClick={handleSubmit('keep')} className="cmn-btn font-weight-normal mr-3"/>
                                            <BackButton title="保存してもう一つ追加" type="submit" onClick={handleSubmit("keepandanother")} className="cmn-btn font-weight-normal px-3 w-auto mr-3 my-md-0 my-2"/>
                                            <BackButton title="保存して編集を続ける" type="submit"  onClick={handleSubmit("keepandcontinueedit")} className="cmn-btn font-weight-normal px-3 w-auto"/>
                                        </Col>
                                    </Row>
                                </form>
                                </div>
                                {
                                    !createMode &&
                                    <GeneralButton id="user_info_keep_btn" className={`cmn-btn font-weight-normal ${classes.btn_keep} ${classes.btn_h_36}`} title="削除" onClick={handleDeleteData}/>
                                }                                                                      
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}
const stateToProps = state => {
    return {
        isOpen: state.vAgent.isOpen,
        access_token: state.vAgent.access_token
    }
}
export default connect(stateToProps,null)(UserInformationAdd)