import React, { useState } from 'react';
import { Dropdown } from '../../Dropdowns/PassFailDropdown'
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

const GeneralTable01 = ({ ColumnHeader, TableData, TableClassName, TableDivClassName, table_id }) => {
    const { t } = useTranslation();
    let index = 0;
    TableData = TableData && TableData.sort((a, b) => a.deleteFlag - b.deleteFlag)
    return (
        <>
            <div className={TableDivClassName}>
                <table className={`${TableClassName} cmn-table table mb-3`} id={table_id}>
                    <thead>
                        <tr key='heading'>
                            <th className="no-fill" key={index++} id={`${table_id}_heading_${index}`}></th>
                            <th key={'head_1'} id={`${table_id}_heading_${index}`}> {t('associate_summary.study_theme_headers.study_theme')}  </th>
                            <th key={'head_2'} id={`${table_id}_heading_${index}`}> {t('associate_summary.study_theme_headers.estimate_time')}  </th>
                            <th key={'head_3'} id={`${table_id}_heading_${index}`}> {t('associate_summary.study_theme_headers.estimate_period')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {TableData && TableData.map((data, index) => {
                            let loop = 0;
                            return (
                                <tr key={index} id={`${table_id}_body_${index}`} className={data.deleteFlag && styles.grey}>
                                    <td key={index + loop} id={`${table_id}_body_${index}${loop++}`} className='font-weight-bold'>{t(`step ${index + 1}`)}</td>
                                    <td key={index + loop} id={`${table_id}_body_${index}${loop++}`} className="text-left">{data.name}</td>
                                    <td key={index + loop} id={`${table_id}_body_${index}${loop++}`} >{data.targetTime}</td>
                                    <td key={index + loop} id={`${table_id}_body_${index}${loop++}`} >{data.targetPeriod}{t('associate_summary.month')} </td>
                                </tr>

                            )
                        }
                        )}
                    </tbody>
                </table>
            </div>
        </>

    );
};

export {
    GeneralTable01
};