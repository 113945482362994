import React, { useEffect } from 'react';

import Routes from './routes/routes.js';
import SnackBar from './components/Snackbar';
import Cookies from 'universal-cookie';
import store from './store'
import { me } from './api/api'
import { connect } from 'react-redux';
import { setSelectedUserName, setSelectedAgentCompany, setSelectedEmployeeId, setSelectedRole, setSelectedUserId, setSyncStatus } from './store/actions';
import EventBus from './components/EventBus/index';
import { isApigwEnv , isLoggedOut } from './utils/General'
import { isAflacMember, isSystemOwner, isTrainerManagerOrTrainee } from './configs/constants.js';
import { syncForTrainerOrTrainee, syncForAflacMember } from './utils/syncState'

const App = ({ username, setSelectedUserName, setSelectedAgentCompany, employee_id, setSelectedEmployeeId, setSelectedRole, setSelectedUserId }) => {

  const cookies = new Cookies();

  if (isApigwEnv() && !isLoggedOut()) {
    if (username === '' && employee_id === '') {
      let value = ''
      me().then(res => {
        value = res.data
        if (res.status === 200 && value !== '' && value.name !== undefined && value.agentCompany.name !== undefined) {
          EventBus.dispatch('close_snack')
          setSelectedUserId(value.id);
          setSelectedUserName(value.name);
          setSelectedEmployeeId(value.empId);
          setSelectedRole(value.role);
          setSelectedAgentCompany(value.agentCompany);
          store.dispatch({type: 'IS_LOGOUT',is_logout : false})
          localStorage.removeItem("_expiredTime");
          localStorage.setItem("_isTimeout", false)
          if (isTrainerManagerOrTrainee()) {
            // syncForTrainerOrTrainee(value)
          }
          if (isAflacMember()  || isSystemOwner()) {
            // syncForAflacMember()
          }
        }
      })
    }
  }

  useEffect(() => {
    // if (cookies.get('tabCount') === undefined) {
    //   cookies.set('tabCount', 1, { path: '/' });
    //   store.dispatch({ type: 'RESET_STORE_VAGENT' });
    //   store.dispatch({ type: 'RESET_NEW_ROLEPLAY' });
    //   store.dispatch({ type: 'RESET_NEW_EXAM' });
    //   store.dispatch({ type: 'RESET_AI_SCORE_RESULT' });
    //   store.dispatch({ type: 'RESET_EXAM_SCORE_RESULT' });
    //   store.dispatch({ type: 'RESET_EXAM_SCORE_RESULT' });
    //   store.dispatch({ type: 'resetSpeechToText' });
    //   store.dispatch({ type: 'RESET_START_CHAT' });
    // }
  }, [])

  return (
    <>
      <SnackBar />
      <Routes />
    </>
  );
}
const stateToProps = state => {
  return {
    username: state.vAgent.username,
    employee_id: state.vAgent.employee_id,
  }
}

const dispatchToProps = dispatch => {
  return {
    setSelectedUserId: (user_id) => {
      dispatch(setSelectedUserId(user_id));
    },
    setSelectedUserName: (username) => {
      dispatch(setSelectedUserName(username));
    },
    setSelectedEmployeeId: (employee_id) => {
      dispatch(setSelectedEmployeeId(employee_id));
    },
    setSelectedRole: (role) => {
      dispatch(setSelectedRole(role));
    },
    setSelectedAgentCompany: (agent_company) => {
      dispatch(setSelectedAgentCompany(agent_company));
    }
  }
}

export default connect(stateToProps, dispatchToProps)(App);
