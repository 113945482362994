import service from './service';
import qs from 'qs';
const getAll =() =>{
    return service({
        url : '/backend/maintenance/agentCompanies',
        method : 'get'
    })
}
const create = (data)=> {
    return service({
        method : 'POST',
        url : 'backend/maintenance/agentCompany',
        data: data
    })
}
const update = (data,id) =>{
    return service({
        url : 'backend/maintenance/agentCompany/'+id,
        method : 'PUT',
        data: data
    })
}

const updateMultiAgency = (data) =>{
    return service({
        url : 'backend/maintenance/agentCompanies',
        method : 'PUT',
        data: data
    })
}

const remove = (data)=>{
    return service({
        url : 'agencyInformations',
        method : 'DELETE',
        data: JSON.stringify(data)
    }) 
}
const removeSelectedAgency = (params)=>{
    return service({
        url : 'backend/maintenance/agentCompanies',
        method : 'DELETE',
        data : params
        // paramsSerializer: () => qs.stringify(params, { arrayFormat: 'repeat' }),
    }) 
}

const importAgency = (data) =>{
    console.log('data',data);
    return service({
        url : 'backend/maintenance/agentCompanies/import',
        method : 'POST',
        data: data,
        headers: {
            'Content-Type': 'text/csv;charset=Shift_JIS'
        }
    })
}

const checkValidation = (params) =>{
    return service({
        url : 'backend/maintenance/validation/',
        method : 'GET',
        params : params
    })
}

const agencyInformationService = {
    getAll,
    create,
    update,
    updateMultiAgency,
    remove,
    removeSelectedAgency,
    importAgency,
    checkValidation
}

export default agencyInformationService;