import React, { useState, useEffect,useRef } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import moment from 'moment'
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from "react-router-dom";
import Sidebar from '../../../components/Sidebar';
import LogoutButton from '../../../components/Buttons/LogoutButton';
import {GeneralDropdown} from '../../../components/Dropdowns/GeneralDropdown';
import Breadcrumbs from '../../../components/Breadcrumbs';
import {fileExtensionData,companyInfoData} from '../../../configs/staticData'
import Export from '../../../components/Exports/CSVExport';
import { isApigwEnv } from '../../../utils/General';
import { isAflacMember, isTrainerManagerOrTrainee } from '../../../configs/constants';
import MaintenanceScreenExport from '../../../components/Exports/MaintenanceScreenExport';
import classes from '../AgencyInformation/styles.module.css';
import agencyInformationService from '../../../api/agencyInformation.service';

const AgencyExport = ({isOpen,access_token}) => {
    const {t} = useTranslation();
    const history = useHistory();
    const [reset, makeAllReset] = useState(false);
    const [data, setData] = useState([]);
    const [selectedFileExtension, setSelectedFileExtension] = useState('')
    const [companyInfoData, setCompanyInfoData] = useState([]);
    const columnsData = [
        { header: "代理店コード", key: "companyCode" },
        { header: "代理店名", key: "companyName" },
        { header: "出先コード", key: "branchCode" },
        { header: "出先名", key: "branchName" },
        ];
    
    useEffect(() => {
        window.scrollTo(0, 0)
        if (!isApigwEnv() && (access_token === '' || access_token === undefined)) {
            history.push("/Logout");
        }
        if (isTrainerManagerOrTrainee() || isAflacMember()) {
            makeAllReset(!reset)
            return
        }
        agencyInformationService.getAll().then(res =>{
            setCompanyInfoData(res.data)
        })
    }, []);
    useEffect(() => {
        let formatData = [];
        companyInfoData.map((item)=>{
            formatData.push(
                {
                companyCode: item.companyCode,
                companyName: item.companyName,
                branchCode: item.branchCode,
                branchName : item.branchName
                }
            );
        })
        setData(formatData);
    }, [companyInfoData]);
    const handleFileExtension = (event)=>{
        console.log('event',event);
        setSelectedFileExtension({id : event.value,name : event.textContent});
    }

    return(
        <>
            <Sidebar />
            <div id="main-content" className={`${isOpen ? 'content-large' : 'content-small'}`}>
                <div className="main-content-inr maintenanceScreen" id="maintenanceScreen">
                    <Row>
                        <Col lg="12" className="cmn-logout-btn-sec">
                            <h3 className="text-left d-flex justify-content-between">
                            エクスポート
                            </h3>
                            <LogoutButton title={t('general.button.logout')} className={'font-14'} id="logout"/>
                        </Col>
                    </Row>                   
                    <Row>
                        <Col xs="12" className="mb-3"><Breadcrumbs homeLink="MaintenanceScreen"/></Col>                      
                        <Col xl="8">
                            <div className="cmn-bg-box">      
                                <div className="cmn-bg-box-inr">
                                    <div className="form-group row">
                                        <label htmlFor="recruiterCodeLabel" className="col-lg-2 col-form-label">フォーマット:</label>
                                        <div className="col-lg-10">
                                            <GeneralDropdown
                                                isStatic={true}
                                                staticData={fileExtensionData}
                                                onSelect={(el) => handleFileExtension(el)}
                                                selectedData={selectedFileExtension && selectedFileExtension.name}
                                                placeholder="----"
                                                dropdown_id="file_extension_dropdown"
                                                className={`${classes.input_h_40}`}/>
                                        </div>
                                    </div>
                                    <Row>
                                        <Col className='text-right'>
                                            <MaintenanceScreenExport selectedFileExtension={selectedFileExtension} columnsData={columnsData} data={data} fileName="Agency Information"/>
                                        </Col>
                                    </Row>
                                </div>                                                                         
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

const stateToProps = state => {
    return {
        isOpen: state.vAgent.isOpen,
        access_token: state.vAgent.access_token
    }
}
export default connect(stateToProps,null)(AgencyExport)