import {
    USERNAME,
    ACTIVE,
    OPEN,
    SCENARIO,
    AGENT_COMPANY,
    EMPLOYEE_ID,
    ROLE,
    USER_ID,
    RECENT_PATH,
    RECRUITER,
    RECRUITER_ID,
    RESET_STORE_VAGENT,
    ACCESS_TOKEN,
    COMPANY_CODE,
    BRANCH_CODE,
    CURRENT_BRANCH_CODE,
    ACTIVE_CODE,
    COMPANY,
    SELECTED_COMPANY_AT_SELECTION_OF_RECRUITER,
    LABEL_AT_ANALYSIS,
    REQUEST_HEADER_GROUP_ID,
    REQUEST_HEADER_USER_ID,
    SYNC_STATUS,
    RECRUITER_DETAIL,
    COMPANY_AT_RECRUITER_DETAIL,
    SELECTED_COMPANY_AT_LEARNING_THEME,
    // IS_LOGOUT
} from '../constants';

/** to store login user info **/
export function setSelectedUserId(user_id) {
    return { type: USER_ID, user_id }
}

export function setSelectedUserName(username) {
    return { type: USERNAME, username }
}

export function setSelectedEmployeeId(employee_id) {
    return { type: EMPLOYEE_ID, employee_id }
}

export function setSelectedRole(role) {
    return { type: ROLE, role }
}

export function setSelectedAgentCompany(agent_company) {
    return { type: AGENT_COMPANY, agent_company }
}

/** sidebar **/
export function setIsActive(isActive) {
    return { type: ACTIVE, isActive }
}

export function setIsOpen(isOpen) {
    return { type: OPEN, isOpen }
}

/** roleplay **/
export function setScenario(scenario) {
    return { type: SCENARIO, scenario }
}

/** to store recent path **/
export function setRecentPath(recent_path) {
    return { type: RECENT_PATH, recent_path }
}

/** to store selected recuiter **/
export function setRecruiter(recruiter) {
    return { type: RECRUITER, recruiter }
}
export function setRecruiterId(recruiter_id) {
    return { type: RECRUITER_ID, recruiter_id }
}

export function resetStoreVAgent() {
    return { type: RESET_STORE_VAGENT }
}

export function accessToken(access_token) {
    return { type: ACCESS_TOKEN, access_token }
}

/** to store AS code **/
export function setSevenDigitsCode(company_code) {
    return { type: COMPANY_CODE, company_code }
}

export function setTenDigitsCode(branch_code) {
    return { type: BRANCH_CODE, branch_code }
}
export function setCurrentBranchCode(current_branch_code) {
    return { type: CURRENT_BRANCH_CODE, current_branch_code }
}

export function setActiveCode(active_code) {
    return { type: ACTIVE_CODE, active_code }
}

export function setSelectedCompanyCode(company) {
    return { type: COMPANY, company }
}

export function setSelectedCompanyCodeAtSelectionOfRecruiter(company_at_selection_of_recruiter) {
    return { type: SELECTED_COMPANY_AT_SELECTION_OF_RECRUITER, company_at_selection_of_recruiter }
}

export function setLabelAtAnalysisPages(label_at_analysis) {
    return { type: LABEL_AT_ANALYSIS, label_at_analysis }
}

export function setRequestHeaderUserId(request_header_user_id) {
    return { type: REQUEST_HEADER_USER_ID, request_header_user_id }
}

export function setRequestHeaderGroupId(request_header_group_id) {
    return { type: REQUEST_HEADER_GROUP_ID, request_header_group_id }
}
export function setSyncStatus(syncStatus) {
    return { type: SYNC_STATUS, syncStatus }
}
/** to store selected recuiter detail for associate by summary  page**/
export function setRecruiterForDetail(recruiter_detail) {
    return { type: RECRUITER_DETAIL, recruiter_detail }
}
export function setSelectedCompanyForDetail(company_at_recruiter_detail) {
    return { type: COMPANY_AT_RECRUITER_DETAIL, company_at_recruiter_detail }
}

export function setSelectedCompanyCodeAtEditLearningTheme(company_at_learning_theme) {
    return { type: SELECTED_COMPANY_AT_LEARNING_THEME, company_at_learning_theme }
}

// export function setIsLogout(is_logout) {
//     return { type: IS_LOGOUT,is_logout }
// }