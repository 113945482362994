import ExcelJS from "exceljs";
import encoding from "encoding-japanese";
import moment from 'moment'
import { staticRoleData } from "../../configs/staticData";
import { padLeadingZeros } from '../../utils/General';

export const isItemChecked = (value,checkedItem) =>{
    return checkedItem.some(item => item.id === value.id)
}

export const isAllItemChecked = (currentItem,checkedItem) =>{
    return currentItem.every(item => checkedItem.includes(item));
}

export const getDifferenceUser = (arr1,arr2)=> {
  return arr2.filter(object1 => {
    return arr1.some(object2 => {
      return object1.empId=== object2.empIdOrg && (object1.userName!== object2.userNameOrg || object1.roleId!== object2.roleIdOrg|| object1.upn!== object2.upnOrg)
    });
  });
}

export const getUserFormatData = (data) =>{
  let formatData = [];
    data.map((item)=>{
        formatData.push(
            {
            empId: item.empIdOrg,
            userName: item.userNameOrg,
            roleName: staticRoleData.filter(roleItem =>roleItem.id === item.roleIdOrg)[0]?.name,
            email : item.upnOrg
            }
        );
    })
  return formatData;
}
export const getSelectedUserFormatData = (data) =>{
  let formatData = [];
    data.map((item)=>{
        formatData.push(
            {
            empId:item.empId,
            userName: item?.userName,
            roleName: staticRoleData.filter(roleItem =>roleItem.id === item?.roleId)[0]?.name,
            email : item?.upn
            }
        );
    })
  return formatData;
}
export const handlerClickDownloadButton = async (e,format,charcode,columnsData,data,fileName) => {
  e.preventDefault();
  console.log('data',data);

  let dateFormat = "YYYY-MM-DD"
  let currentDate = new Date();
  let currentDateFormat = moment(currentDate).format(dateFormat);

  const workbook = new ExcelJS.Workbook();
  workbook.addWorksheet("sheet1");
  const worksheet = workbook.getWorksheet("sheet1");

  worksheet.columns = columnsData;
  worksheet.addRows(data);
  console.log('worksheet',worksheet);
  const uint8Array =
  format === "xlsx"
      ? await workbook.xlsx.writeBuffer()
      : charcode === "UTF8"
      ? await workbook.csv.writeBuffer()
      : new Uint8Array(
          encoding.convert(await workbook.csv.writeBuffer(), {
          from: "UTF8",
          to: "SJIS"
          })
      );
  const blob = new Blob([uint8Array], {
  type: "application/octet-binary"
  });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `${fileName} ${currentDateFormat}.` + format;
  a.click();
  a.remove();
};

export const getDifferenceAgency = (arr1,arr2)=> {
  return arr2.filter(object1 => {
    return arr1.some(object2 => {
      return object1.id=== object2.idOrg && (object1.companyName!== object2.companyNameOrg || object1.branchCode!== object2.branchCodeOrg|| object1.branchName!== object2.branchNameOrg)
    });
  });
}

export const getAgencyFormatData = (data) =>{
  let formatData = [];
    data.map((item)=>{
        formatData.push(
            {
            companyCode: item.companyCodeOrg?padLeadingZeros(item.companyCodeOrg, 7)+ "\t":padLeadingZeros(item.companyCode, 7)+ "\t",
            companyName: item.companyNameOrg?item?.companyNameOrg:item?.companyName,
            branchCode: item.branchCodeOrg?item?.branchCodeOrg + "\t" :item?.branchCode + "\t",
            branchName : item.branchNameOrg?item?.branchNameOrg:item?.branchName
            }
        );
    })
  return formatData;
}
