import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import Step, { Line } from './../../components/Navigations/Step';
import { Row, Col } from 'reactstrap';
import LogoutButton from './../../components/Buttons/LogoutButton';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Sidebar from './../../components/Sidebar';
import styles from './styles.module.css';
import { setRecentPath, setRecruiter, setRecruiterId, setSelectedCompanyCode } from '../../store/actions'
import EventBus from '../../components/EventBus'
import { sectionCriteriaList, getUserList, getTrainerEmailCheck, getTraineeEmailCheck } from '../../api/api'
import { isTrainerManager, isAflacMemberOrBranchManager, isTrainee, isSystemOwner } from '../../configs/constants'
import { GeneralDropdown } from '../../components/Dropdowns/GeneralDropdown'
import { longestString, getASCodeCompany, isApigwEnv,padLeadingZeros } from '../../utils/General'
import { COMPANY_CODES_URL, COMPANY_WITH_BRANCHES_URL } from "../../configs/urlConstants";
import Toggle from '../../components/Toggle';

const StartNewRolePlayPage = ({ isOpen, location, agent_company, user_id, setRecentPath, recruiter, setRecruiter, recruiter_id, setRecruiterId, themeId, scenarioId, SET_NEW_ROLEPLAY, access_token, role, recent_path, company, current_branch_code,setSelectedCompanyCode, syncStatus }) => {

    const { t } = useTranslation();
    const history = useHistory();
    var longestCompanyname = 0, longestBranchName = 0;
    const onRecruiterSummary = () => {
        history.goBack();
    }

    SET_NEW_ROLEPLAY({ currentSectionIndex: 0 })

    const [uniqueCode, setUniqueCode] = useState();
    const [sevenCode, setSevenCode] = useState();
    const [threeCode, setThreeCode] = useState();
    const [disabled, setDisabled] = useState(true);
    const [longest, setLogest] = useState();
    const [uniqueFilterCode, setUniqueFilterCode] = useState();
    const [sevenFilterCode, setSevenFilterCode] = useState();
    const [threeFilterCode, setThreeFilterCode] = useState();
    const [selectedCompanyName, setSelectedCompanyName] = useState();
    const [flag, setFlag] = useState(false);
    const [reset, makeAllReset] = useState(false)
    const [isBack, setBack] = useState(false);

    // step1
    const [trainee, setTrainee] = useState('')
    const [policy, setPolicy] = useState('');
    const [insurance, setInsurance] = useState('');
    const [isClicked, setIsClicked] = useState('');
    const [activeSlider, setActiveSlider] = useState();
    const [url, setUrl] = useState()
    const [sevenCodePolicy, setSevenCodePolicy] = useState('');
    const [uniqueCodePolicy, setUniqueCodePolicy] = useState('');
    const [agentCompanyCode, setAgentCompanyCode] = useState('');
    const [branchCode, setBranchCode] = useState('');
    const [sevenCodeRecruiter, setSevenCodeRecruiter] = useState('');
    const [uniqueCodeRecruiter, setUniqueCodeRecruiter] = useState('');
    const [changedASCode, setChangedASCode] = useState();
    const [trainerEmail, setTrainerEmail] = useState();
    const [traineeEmail, setTraineeEmail] = useState();

    useEffect(() => {
        if (recent_path === '/RecruiterSummaryDetail') {
            setFlag(true)
            company && setUniqueFilterCode({ id: company[0].id, name: company[0].name })
            company && setSevenFilterCode({ id: company[0].id, name: company[0].name })
            company && setThreeFilterCode({ id: '', name: '' })
            // company && setSevenCode([{ id: company[0].id, name: company[0].name, branches: company[1].branch }])
            setUniqueCodeRecruiter({ value: recruiter_id, textContent: recruiter })
            setSevenCodeRecruiter({ value: '', textContent: '' })
            setTrainee(recruiter_id)
        }
        // setTraineeName(recruiter)

        if(isAflacMemberOrBranchManager() || isSystemOwner()) { getUserList(COMPANY_CODES_URL).then(res => {
            setUniqueCode(res.data.map(item => { return { id: item.id, name: item.name, companyName: getASCodeCompany(item.name)[1] } }).sort((a, b) => parseInt(a.id) - parseInt(b.id)))
        })}

        if(isAflacMemberOrBranchManager() || isSystemOwner()) { getUserList(COMPANY_WITH_BRANCHES_URL).then(res => {
            setSevenCode(res.data.map(item => { return { id: item.code, name: padLeadingZeros(item.code,7) + '/' + item.name, companyName: item.name, branches: item.branches } }).sort((a, b) => parseInt(a.id) - parseInt(b.id)))
        })}
        window.scrollTo(0, 0)

        if (!isApigwEnv() && (access_token === '' || access_token === undefined)) {
            history.push("/Logout");
        }
        if (isTrainee()) {
            makeAllReset(!reset)
            return
        }
        setRecentPath(location.pathname);

        getTrainerEmailCheck().then(res => {
            setTrainerEmail(true)
        }).catch(err => {
            if (err.response.status === 404) {
                setTrainerEmail(false)
                EventBus.dispatch('show_snack', { message: "ログインユーザーのUPN IDが利用できません", type: "error" })
            } else {
                EventBus.dispatch('show_snack', { message: "問題が発生しています", type: 'error' })
            }
        })
    }, [syncStatus])

    useEffect(() => {
        setBack(false);
        activeSlider === 2 && setThreeFilterCode(undefined)
    }, [activeSlider])

    useEffect(() => {
        let currentPolicy = activeSlider === 1 ? (uniqueCodePolicy !== '' && uniqueCodePolicy) : (sevenCodePolicy !== '' && sevenCodePolicy)
        currentPolicy && setPolicy(currentPolicy)
    }, [activeSlider, uniqueCodePolicy, sevenCodePolicy])

    useEffect(() => {
        longestCompanyname = longestString(sevenCode && sevenCode.map(item => { return { name: item.name } }))
        longestBranchName = longestString(sevenCode && sevenCode[0].branches.map(val => { return { name: val.id + '/' + val.name } }))
        longestCompanyname = longestCompanyname > longestBranchName ? longestCompanyname : longestBranchName;
        setLogest(longestCompanyname < 15 ? '6' : longestCompanyname >= 15 ? '7' : longestCompanyname >= 20 ? '8' : longestCompanyname >= 25 ? '8' : '9')
    }, [uniqueCode, sevenCode, threeCode])

    const onUniqueCodeChange = (el) => {
        setUniqueFilterCode({ id: el.getAttribute("value"), name: el.textContent })
        setBack(false);
        setFlag(false)
        setPolicy('')
        setUniqueCodePolicy('')
        setBranchCode('')
        setChangedASCode(true)
        onRecruiter({ value: '', textContent: '' })
        setSevenFilterCode({ id: el.getAttribute("value"), name: el.textContent })
        setThreeFilterCode(undefined)
        setThreeCode(sevenCode.filter(item => item.id === el.value)[0].branches.map(val => { return { id: val.code, name: val.code + '/' + val.name } }).sort((a, b) => parseInt(a.id) - parseInt(b.id)));
        setSevenCodeRecruiter({ value: '', textContent: '' })
        setSevenCodePolicy('')
    }

    const onSevenCodeChange = async (el) => {
        setSevenFilterCode({ id: el.getAttribute("value"), name: el.textContent })
        setThreeCode(sevenCode.filter(item => item.id === el.value)[0].branches.map(val => { return { id: val.code, name: val.code + '/' + val.name } }).sort((a, b) => parseInt(a.id) - parseInt(b.id)));
        setThreeFilterCode(undefined)
        setBack(false);
        setFlag(false)
        setPolicy('')
        setSevenCodePolicy('')
        setBranchCode('')
        setChangedASCode(true)
        onRecruiter({ value: '', textContent: '' })
        setUniqueFilterCode({ id: el.getAttribute("value"), name: el.textContent })
        setUniqueCodeRecruiter({ value: '', textContent: '' })
        setUniqueCodePolicy('')
    }

    const onThreeCodeChange = (el) => {
        setThreeFilterCode({ id: el.getAttribute("value"), name: el.textContent })
        setBack(false);
        setFlag(false)
        setBranchCode(el.getAttribute("value"))
        setChangedASCode(true)
        onRecruiter({ value: '', textContent: '' })
        setSevenCodePolicy('')
    }
    // console.log('current_branch_code',current_branch_code);
    // console.log('branchCode',branchCode);
    useEffect(() => {
        if (!flag) {
            // console.log('state 1');
            if (sevenFilterCode !== undefined && threeFilterCode !== undefined && activeSlider === 2) {
                setDisabled(false)
                setSelectedCompanyName(sevenCode.filter(item => item.id === parseInt(sevenFilterCode.id)))
                sevenFilterCode && setAgentCompanyCode(sevenFilterCode.id)
                threeFilterCode && setBranchCode(threeFilterCode.id)
            }
            else if (uniqueFilterCode !== undefined && activeSlider === 1) {
                // console.log('state 2');
                setDisabled(false)
                setSelectedCompanyName(uniqueCode.filter(item => item.id === parseInt(uniqueFilterCode.id)))
                uniqueFilterCode && setAgentCompanyCode(uniqueFilterCode.id)
                setBranchCode(current_branch_code)
            }
            else {
                // console.log('state 3');
                (activeSlider === 2 && threeFilterCode === undefined) || (uniqueFilterCode === undefined && activeSlider === 1) ? setDisabled(true) : setDisabled(false)
                setSelectedCompanyName([{ id: agent_company.code, name: agent_company.code + '/' + agent_company.name, companyName: agent_company.name }])
                setBranchCode(current_branch_code)
            }
        } else {
            // console.log('state 4');
            (activeSlider === 2 && threeFilterCode === undefined) || (uniqueFilterCode === undefined && activeSlider === 1) ? setDisabled(true) : setDisabled(false)
            sevenCode && setThreeCode(sevenCode.filter(item => item.id === sevenFilterCode.id)[0].branches.map(val => { return { id: val.code, name: val.code + '/' + val.name } }).sort((a, b) => parseInt(a.id) - parseInt(b.id)));
            setSelectedCompanyName([{ id: company[0].id, name: company[0].name, companyName: getASCodeCompany(company[0].name)[1] }])
            console.log('threeFilterCode',threeFilterCode);
            sevenFilterCode && setAgentCompanyCode(sevenFilterCode.id)
            threeFilterCode?.id && setBranchCode(threeFilterCode.id)
        }

        if (isTrainerManager()) {
            setDisabled(false);
        }
    }, [activeSlider, uniqueFilterCode, sevenFilterCode, threeFilterCode, sevenCode])
    useEffect(() => {
        if (isTrainerManager()) {
            setSevenFilterCode({ id: agent_company.code })
            setThreeFilterCode({ id: agent_company.branch.code })
            setAgentCompanyCode(agent_company.code)
            setBranchCode(agent_company.branch.code)
        }
    }, [])

    useEffect(() => {
        if (!isTrainerManager()) {
            // activeSlider === 2 && !isBack && setThreeFilterCode(undefined)
            if (!isBack && ((uniqueFilterCode === '' && activeSlider === 1) || activeSlider === 2)) {
                (changedASCode || threeFilterCode === undefined) && onRecruiter({ value: '', textContent: '' })
            }
            else if (!isBack && (uniqueFilterCode !== '' && activeSlider === 1)) {
                onRecruiter(
                    {
                        value: activeSlider === 1 ? uniqueCodeRecruiter.value : '',
                        textContent: activeSlider === 1 ? uniqueCodeRecruiter.textContent : ''
                    })
            }
        }
    }, [isBack, activeSlider, changedASCode, threeFilterCode])

    const onRecruiter = (el) => {
        setTrainee(el.value)
        setRecruiterId(el.value)
        setRecruiter(el.textContent)
        isTrainerManager() ?
            setSelectedCompanyCode([
                { id: agent_company.id, name: agent_company.code + '/' + agent_company.name },
                { branch: [{ code: agent_company.branch.code, name: agent_company.branch.code + '/' + agent_company.branch.name }] }
            ])
            : sevenFilterCode && threeFilterCode && activeSlider === 2 ?
                setSelectedCompanyCode([sevenFilterCode, { branch: [{ code: threeFilterCode.id, name: threeFilterCode.name }] }])
                : setSelectedCompanyCode([uniqueFilterCode, { branch: [{ code: null, name: null }] }])
        EventBus.dispatch('close_snack');
        (!isTrainerManager() && activeSlider === 1)
            ? setUniqueCodeRecruiter({ value: el.value, textContent: el.textContent })
            : setSevenCodeRecruiter({ value: el.value, textContent: el.textContent })
    }

    useEffect(() => {
        (trainee !== '' && trainee !== undefined) && getTraineeEmailCheck(trainee).then(res => {
            setTraineeEmail(true)
        }).catch(err => {
            if (err.response.status === 404) {
                setTraineeEmail(false)
                EventBus.dispatch('show_snack', { message: "選択されたユーザーのUPN IDは利用できません", type: "error" })
            } else {
                EventBus.dispatch('show_snack', { message: "問題が発生しています", type: 'error' })
            }
        })
    }, [trainee])
    // step2
    const [sectionCriteria, setSectionCriteria] = useState([]);

    const [step1, setStep1] = useState(true);
    const [step2, setStep2] = useState(false);
    const [step3, setStep3] = useState(false);
    const showStep1 = () => {
        setStep1(true);
        setStep2(false);
        setStep3(false);
        setBack(true);
    }

    const showStep2 = () => {
        if (!trainee) {
            EventBus.dispatch('show_snack', { message: "募集人を選択してください", type: "error" })
            return
        }
        if (sectionCriteria.length > 0 && (themeId !== policy || scenarioId !== insurance)) {
            let filterData = activeSlider === 2 ?
                {
                    agentCompanyCode: sevenFilterCode,
                    branchCode: threeFilterCode
                }
                : {
                    agentCompanyCode: uniqueFilterCode,
                    // branchCode: threeFilterCode
                }


            sectionCriteriaList(policy, insurance, user_id, trainee, filterData).then(res => {
                let newArray = res.data.map((val, index) => (
                    {
                        sectionId: val.sectionId,
                        sectionName: val.sectionName,
                        criteria: val.criteria,
                        checked: val.checked ? true : false
                    }
                ));
                let saved = newArray.filter(value => value.checked);
                if (saved.length < 1) {
                    newArray = newArray.map(it => {
                        it.checked = true;
                        return it;
                    });
                } else {
                    newArray = newArray.map(it => {
                        it.checked = saved.includes(it);
                        return it;
                    });
                }
                setSectionCriteria(newArray)
            })
        }
        SET_NEW_ROLEPLAY({ themeId: policy, scenarioId: insurance })
        setStep1(false);
        setStep2(true);
        setStep3(false);
    }

    const showSetp3 = () => {
        if (sectionCriteria.filter(item => item.checked).length === 0) {
            EventBus.dispatch('show_snack', { message: "シナリオを選択してください", type: "error" })
            return
        }
        setStep1(false);
        setStep2(false);
        setStep3(true);
    }

    const [isLoadingOpen, setLoadingOpen] = React.useState(false);

    return (
        <>
            <Sidebar />
            <div id="main-content" className={`${isOpen ? 'content-large' : 'content-small'}`} name="main_content">
                <div className={`${styles.loading_box} ${isLoadingOpen ? styles.show : styles.hide} `} >
                    <p className="font-20" > Loading... </p>
                </div>
                <div className="main-content-inr" id="start-of-new-roll-playing" >
                    <Row>
                        <Col lg="12" className="cmn-logout-btn-sec">
                            < h3 className="text-left d-flex justify-content-between" id="start_of_new_roleplay" name="start_of_new_roleplay">
                                {t('roleplay.title')}
                            </h3>
                            <LogoutButton title={t('general.button.logout')} className={'font-14'} reset={reset} id="logout_btn" name="logout_btn" />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12" md="12">
                            <div className={`mb-32 ${styles.nav}`}>
                                <div className={`${styles.sub_nav} col-12 mb-2`} >
                                    <div className={styles.steps} id="roleplay_step_1" name="roleplay_step_1" >
                                        <div className={styles.sub_step} >
                                            < Step text="1" className={styles.active} />
                                            <Line active={(step2 || step3) ? true : false} />
                                        </div>
                                    </div>
                                    <div className={styles.steps} id="roleplay_step_2" name="roleplay_step_2">
                                        <div className={styles.sub_step} >
                                            <Step text="2" className={step2 || step3 ? styles.active : ""} />
                                            <Line active={(step3) ? true : false} />
                                        </div> </div>
                                    <div className="steps" id="roleplay_step_3" name="roleplay_step_3" >
                                        <div className="sub_step" >
                                            <Step text="3" className={step3 ? styles.active : ""} />
                                        </div>
                                    </div>
                                </div>
                                <div className={`${styles.sub_nav} col-sm-12 p-0 justify-space-between`} >
                                    <p className={`${styles.label} font-weight-bold`} id="step_1" name="step_1"> {t('roleplay.step 1.title')} </p>
                                    <p className={`${styles.label}  ${step2 || step3 ? 'font-weight-bold' : styles.inactive}`} id="step_2" name="step_2"> {t('roleplay.step 2.title')} </p>
                                    <p className={`${styles.label}  ${step3 ? 'font-weight-bold' : styles.inactive}`} id="step_3" name="step_3"> {t('roleplay.step 3.title')} </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {step1 && (isAflacMemberOrBranchManager() || isSystemOwner()) &&
                        <Row className="mb-4">
                            <Col lg={`${isOpen && activeSlider === 1 ? parseInt(longest) + 1 - 2 : isOpen && activeSlider === 2 ? parseInt(longest) + 1 : !isOpen && activeSlider === 1 ? longest - 2 : longest} `} className={`${activeSlider === 1 ? styles.unique_code_dropdown : styles.AS_code_dropdown}`}>
                                <div className="cmn-bg-box-inr p-3">
                                    <div className="AS_code_box">
                                        <label className="font-weight-bold font-14 mb-0">{t('associate_summary.AS_code')}</label>
                                        <Toggle
                                            activeSlider={activeSlider}
                                            setActiveSlider={setActiveSlider}
                                            className="mr-0"
                                        />
                                    </div>
                                    {activeSlider === 1 ?
                                        <GeneralDropdown
                                            isStatic={true}
                                            staticData={uniqueCode}
                                            onSelect={onUniqueCodeChange}
                                            selectedData={uniqueFilterCode && uniqueFilterCode.name}
                                            className="font-10 recruiter"
                                            placeholder={t('associate_summary.agency_code')}
                                            dropdown_id="company_code_dropdown"
                                        />

                                        : <div className="d-flex">
                                            <GeneralDropdown
                                                isStatic={true}
                                                staticData={sevenCode}
                                                onSelect={onSevenCodeChange}
                                                selectedData={sevenFilterCode && sevenFilterCode.name}
                                                className="font-10 recuiter-dropdown mr-2"
                                                placeholder={t('associate_summary.agency_code')}
                                                dropdown_id="seven_code_dropdown"
                                            />

                                            <GeneralDropdown
                                                isStatic={true}
                                                staticData={threeCode}
                                                onSelect={onThreeCodeChange}
                                                selectedData={threeFilterCode && threeFilterCode.name}
                                                className="font-10 recuiter-dropdown"
                                                placeholder={t('associate_summary.whereabouts_code')}
                                                dropdown_id="three_code_dropdown"
                                            />
                                        </div>
                                    }

                                </div>
                            </Col>
                        </Row>
                    }
                    <Row >
                        <Col lg={step1 ? 11 : step2 ? 9 : 9} md="12" > {step1 ?
                            <Step1
                                onBack={onRecruiterSummary}
                                onNext={showStep2}
                                setTrainee={setTrainee}
                                policy={policy}
                                setPolicy={setPolicy}
                                insurance={insurance}
                                setInsurance={setInsurance}
                                isClicked={isClicked}
                                setIsClicked={setIsClicked}
                                agent_id={agent_company.id}
                                agent_company={agent_company}
                                recruiter={recruiter}
                                setRecruiter={setRecruiter}
                                setRecruiterId={setRecruiterId}
                                setSelectedCompanyCode={setSelectedCompanyCode}
                                disabled={disabled}
                                selectedCompanyName={selectedCompanyName}
                                role={role}
                                uniqueFilterCode={uniqueFilterCode}
                                sevenFilterCode={sevenFilterCode}
                                threeFilterCode={threeFilterCode}
                                activeSlider={activeSlider}
                                flag={flag}
                                url={url}
                                setUrl={setUrl}
                                isBack={isBack}
                                uniqueCodePolicy={uniqueCodePolicy}
                                setUniqueCodePolicy={setUniqueCodePolicy}
                                sevenCodePolicy={sevenCodePolicy}
                                setSevenCodePolicy={setSevenCodePolicy}
                                setBranchCode={setBranchCode}
                                onRecruiter={onRecruiter}
                                trainerEmail={trainerEmail}
                                traineeEmail={traineeEmail}
                            />
                            :
                            step2 ?
                                <Step2
                                    onBack={showStep1}
                                    onNext={showSetp3}
                                    policy={policy}
                                    insurance={insurance}
                                    sectionCriteria={sectionCriteria}
                                    setSectionCriteria={setSectionCriteria}
                                    trainerId={user_id}
                                    traineeId={trainee}
                                    selectedCompanyName={selectedCompanyName}
                                    recruiter={recruiter}
                                    agent_company={agent_company}
                                    role={role}
                                    uniqueFilterCode={uniqueFilterCode}
                                    sevenFilterCode={sevenFilterCode}
                                    threeFilterCode={threeFilterCode}
                                    activeSlider={activeSlider}
                                />
                                :
                                <Step3
                                    onBack={showStep2}
                                    isLoadingOpen={isLoadingOpen}
                                    setLoadingOpen={setLoadingOpen}
                                    trainee={trainee}
                                    policy={policy}
                                    insurance={insurance}
                                    isClicked={isClicked}
                                    sectionCriteria={sectionCriteria}
                                    selectedCompanyName={selectedCompanyName}
                                    recruiter={recruiter}
                                    agentCompanyCode={parseInt(agentCompanyCode)}
                                    branchCode={branchCode}
                                />
                        }
                        </Col>
                    </Row>

                </div>
            </div>
        </>
    )
}

const stateToProps = state => {
    return {
        isOpen: state.vAgent.isOpen,
        agent_company: state.vAgent.agent_company,
        user_id: state.vAgent.user_id,
        recruiter: state.vAgent.recruiter,
        recruiter_id: state.vAgent.recruiter_id,
        themeId: state.startRolePlay.themeId,
        scenarioId: state.startRolePlay.scenarioId,
        access_token: state.vAgent.access_token,
        role: state.vAgent.role,
        company: state.vAgent.company,
        current_branch_code: state.vAgent.current_branch_code,
        recent_path: state.vAgent.recent_path,
        syncStatus: state.vAgent.syncStatus,
    }
}
const dispatchToProps = dispatch => {
    return {
        setRecentPath: (recent_path) => {
            dispatch(setRecentPath(recent_path));
        },
        setRecruiter: (recruiter) => {
            dispatch(setRecruiter(recruiter));
        },
        setRecruiterId: (recruiter_id) => {
            dispatch(setRecruiterId(recruiter_id));
        },
        setSelectedCompanyCode: (company) => {
            dispatch(setSelectedCompanyCode(company));
        },
        SET_NEW_ROLEPLAY: (res) => dispatch({ type: 'SET_NEW_ROLEPLAY', paramData: res })
    }
}

export default connect(stateToProps, dispatchToProps)(StartNewRolePlayPage);
