import ReactPaginate from "react-paginate";
import styles from './styles.module.css'

const Pagination = ({itemPerPage,totalData,setPageNumber}) =>{
    const pageCount = totalData/itemPerPage;
    const changePage = ({selected}) =>{
        setPageNumber(selected);
    }
    return(
        <ReactPaginate 
            previousLabel={"<<"}
            nextLabel={">>"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={styles.paginationBttns}
            previousLinkClassName="previousBttn"
            nextLinkClassName="nextBttn"
            disabledClassName="paginationDisabled"
            activeClassName={styles.paginationActive}
        />
    )
}
export default Pagination;