import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { useHistory,useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Sidebar from '../../../components/Sidebar';
import LogoutButton from '../../../components/Buttons/LogoutButton';
import GeneralTextbox from '../../../components/Textboxes/GeneralTextbox';
import {staticRoleData, userColumnsData} from '../../../configs/staticData'
import Breadcrumbs from '../../../components/Breadcrumbs';
import GeneralButton from '../../../components/Buttons/GeneralButton';
import BackButton from '../../../components/Buttons/BackButton';
import Checkbox from '../../../components/Checkbox';
import { FormControlLabel } from '@material-ui/core';
import styles from './styles.module.css'
import Pagination from '../../../components/Pagination';
import classes from '../UserInformation/styles.module.css';
import { isApigwEnv } from '../../../utils/General';
import { isAflacMember, isTrainerManagerOrTrainee } from '../../../configs/constants';

import SearchTextbox from '../../../components/Textboxes/SearchTextBox';
import search_icon from '../../../assets/images/search_icon.png'
import reset_icon from '../../../assets/images/reset_icon.png'
import { getDifferenceUser, getSelectedUserFormatData, getUserFormatData, handlerClickDownloadButton, isAllItemChecked, isItemChecked } from '../Helper';
import userInformationService from '../../../api/userInformation.service';
import Alert from '../components/Alert';
import store from '../../../store';
import ErrorMessage from '../../../components/ErrorMessage';


const UserInformationEditList = ({isOpen,access_token}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { state } = useLocation();
    const storeData = store.getState().maintenanceScreen;
    const [reset, makeAllReset] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState('')
    const [roleData, setRoleData] = useState(staticRoleData);
    const [oldData, setOldData] = useState([]);
    const [userInfoData, setUserInfoData] = useState([]);
    const [orgUserInfoData, setOrgUserInfoData] = useState([]);
    const [updatedData, setUpdatedData] = useState([]);
    const [reloading, setReloading] = useState(true);
    const [submitting, setSubmitting] = useState(true);
    const [isDeleteFilter, setIsDeleteFilter] = useState(true);
    const [checkUpnDuplicate, setCheckUpnDuplicate] = useState([]);
    const [duplicatedItem, setDuplicateItem] = useState([]);
    const [updateAlert, setUpdateAlert] = useState({isAlert : false,message : '',alertType :'alert-success'});
    const [pageNumber, setPageNumber] = useState(0)
    const [itemPerPage, setItemPerPage] = useState(50)
    const pageVisited = pageNumber * itemPerPage;
    const [userInfo, setUserInfo] = useState(userInfoData.slice(pageVisited,pageVisited + itemPerPage))

    useEffect(() => {
        const updatePageVisited = parseInt(pageNumber * itemPerPage);
        const updateLastPage = parseInt(updatePageVisited+parseInt(itemPerPage))
        let tempData = [...userInfoData]
        tempData = userInfoData.slice(updatePageVisited, updateLastPage)
        setUserInfo(tempData)
    }, [pageNumber,itemPerPage,userInfoData])
    useEffect(() => {
        setUpdateAlert({isAlert : state?.isAlert,message : state?.message,alertType :'alert-success'})
    }, [state])
    const [checkedItem, setcheckedItem] = useState(state?.stateCheckedItem?state.stateCheckedItem:[])

    useEffect(() => {
        window.scrollTo(0, 0)
        if (!isApigwEnv() && (access_token === '' || access_token === undefined)) {
            history.push("/Logout");
        }
        if (isTrainerManagerOrTrainee() || isAflacMember()) {
            makeAllReset(!reset)
            return
        }
    }, []);
    useEffect(() => {
       searchKeyword.length === 0 && setReloading(true);
    }, [searchKeyword]);
    useEffect(() => {
        let dataDiff =  getDifferenceUser(orgUserInfoData,userInfoData);
        setUpdatedData(dataDiff);
    }, [userInfo]);
    useEffect(() => {
        const uniqueIds = [];

        const unique = checkUpnDuplicate.length > 0 ? checkUpnDuplicate.filter(element => {
        const isDuplicate = uniqueIds.includes(element.id);

        if (!isDuplicate) {
            uniqueIds.push(element.id);

            return true;
        }
        }):[];
        setDuplicateItem(unique);
    }, [checkUpnDuplicate]);

    useEffect(() => {
        reloading && userInformationService.getAll().then(res =>{
            if (res.status === 200) {
                setReloading(false)
                setIsDeleteFilter(true);
                setUserInfoData(res.data)
                setOldData(res.data)
                let orgData =[];
                res.data.map(item =>{
                    orgData.push({
                        empIdOrg : item.empId,
                        userNameOrg : item.userName,
                        roleIdOrg : item.roleId,
                        upnOrg : item.upn
                    })
                })
                setOrgUserInfoData(orgData)
            }
        })
    }, [reloading]);
    const SearchByKeyword = (event) => {
        let filterData = [...oldData];
        let search = searchKeyword.toLowerCase();
        let filterItems = {'empId' : search,'userName':search,'upn':search}
        if (searchKeyword.length > 0) {
            console.log('filterData',filterData);
            let filtered =  filterData.filter(item => 
                item?.empId?.toLowerCase().includes(filterItems.empId)
                || item?.userName?.toLowerCase().includes(filterItems.userName)
                || item?.upn?.toLowerCase().includes(filterItems.upn)
            );
            setUserInfoData(filtered)
        }
    }
    const handleCheckboxChange = (index) => (event) =>{
        let findByItem = userInfo.find(item => item.id === index);
        if (event.target.checked) {
            setcheckedItem([...new Set([...checkedItem,findByItem])])
        } else {
            setcheckedItem([...checkedItem.filter(item => item.id !== index)])
        }
    }
    const handleAllCheckboxChange = (event) =>{
        let currentEventChecked = event.target.checked;
        if (currentEventChecked) {
            setcheckedItem([...new Set([...checkedItem,...userInfo])])
        } else {
            setcheckedItem([...checkedItem.filter(item => !userInfo.includes( item ))])
        }
    }
    const handleRole = (index) => (event) =>{
        // console.log('event',event.target.value);
        const cloneUserInfo = [...userInfo];
        cloneUserInfo[index].roleId = parseInt(event.target.value);
        setUserInfo(cloneUserInfo);
    }
    const handleUserName = (index) =>(event) => {
        const cloneUserInfo = [...userInfo];
        cloneUserInfo[index].userName = event.target.value;
        setUserInfo(cloneUserInfo);
    }
    const handleEmail = (index) =>(event) => {
        const cloneUserInfo = [...userInfo];
        try {
            let params = {upn : event.target.value};
            let url = 'backend/maintenance/validation/user/'+cloneUserInfo[index].id;
            let data = userInformationService.checkValidation(url,params).then(res =>{
                console.log('res',res);
                if (res.status === 200) {
                    let filterData = checkUpnDuplicate.filter(item =>item.id !== cloneUserInfo[index].id);
                    setCheckUpnDuplicate(filterData);
                }
            }).catch(error =>{
                setCheckUpnDuplicate(checkUpnDuplicate =>[...checkUpnDuplicate,cloneUserInfo[index]]);
            })
            cloneUserInfo[index].upn = event.target.value;
            setUserInfo(cloneUserInfo);
        } catch (error) {
            console.log('error',error);
        }
    }

    const handleDeleteData = () =>{
        // store.dispatch({type: 'USER_SELECTED_DATA',userSelectedData : checkedItem})
        history.push({
            pathname: 'userInformation/userPreviewdelete',
            state: {data:checkedItem,previousPath : history.location.pathname}
          });
    }

    const resetSearch = () =>{
        setSearchKeyword('');
        setReloading(true);
        setItemPerPage(itemPerPage);
        setPageNumber(0);
    }

    const showUpnValidationError = (userItem) =>{
        let findItem = updatedData.find(item => item.id === userItem.id);
        return (findItem && findItem.upn === "")?true : false;
    }
    const showUpnDuplicateError = (userItem) =>{
        let findItem = duplicatedItem.find(item => item.id === userItem.id);
        return findItem ?true: false;
    }

    const UpnValidation = (data) =>{
        let checkData =data.map(obj =>{
            for (var key in obj) {
                if (obj['upn'] !== null && obj['upn'] != "")
                    return false;
            }
            return true;
        })

        return checkData;
    }

    const updateData =()=>{
       let dataDiff =  getDifferenceUser(orgUserInfoData,userInfoData);
       let chkValidation = UpnValidation(dataDiff);
       let checker = chkValidation.every(v => v === false);//all item is valid(true) or not(false)
       try {
                let data =[];
                dataDiff.length > 0 && dataDiff.map(item =>{
                    data.push({
                        "id": item.id,
                        "empId": item.empId,
                        "userName": item.userName,
                        "roleId": item.roleId,
                        "upn": item.upn
                    })
                })
                !checker && setUpdateAlert({isAlert : true,message : "下記のエラーを修正してください。",alertType : 'alert-danger'})
                checker && setSubmitting(false);
                submitting && checker && userInformationService.updateMultiUsers(data).then(res=>{
                    if (res.status === 200) {
                        setSubmitting(true);
                        let orgData =[];
                        userInfoData.map(item =>{
                            orgData.push({
                                empIdOrg : item.empId,
                                userNameOrg : item.userName,
                                roleIdOrg : item.roleId,
                                upnOrg : item.upn
                            })
                        })
                        setOrgUserInfoData(orgData)
                        setUpdatedData([]);
                        setUpdateAlert({isAlert : true,message : res.data.message,alertType : 'alert-success'})
                    }
                }).catch(error =>{
                    if (error.response.status === 400) {
                        setSubmitting(true);
                        setUpdateAlert({isAlert : true,message : error.response.data.message,alertType :'alert-danger'})
                    }
                    console.log('error',error);
                })
       } catch (error) {
           console.log('error')
       }
    }
    const filterDeletedData = () =>{
        setIsDeleteFilter(false);
        let cloneData = [...oldData];
        let filtered = cloneData.filter(item => item.status === 3);
        setUserInfoData(filtered);
        setItemPerPage(itemPerPage);
        setPageNumber(0);
    }
    const resetDeleted = () =>{
        setReloading(true);
    }
    const exportAll = (event) =>{
        let formatData = getUserFormatData(orgUserInfoData);
        handlerClickDownloadButton(event,'csv','SJIS',userColumnsData,formatData,"User Detail")
    }
    const exportSelectedData = (event) =>{
        let formatData = getSelectedUserFormatData(checkedItem);
        handlerClickDownloadButton(event,'csv','SJIS',userColumnsData,formatData,"User Detail")
    }
    const handlePaginationDropdown =(event) =>{
        setItemPerPage(event.target.value)
        setPageNumber(0)
    }
    const editUser = (user) =>{
        store.dispatch({type: 'USER_NAME',userData : user})
        history.push(
            {
            pathname :'/userInformation/edit_user_info'
            }
        );
    }
    return(
        <>
            <Sidebar />
            <div id="main-content" className={`${isOpen ? 'content-large' : 'content-small'}`}>
                <div className="main-content-inr maintenanceScreen" id="maintenanceScreen">
                    <Row>
                        <Col lg="12" className="cmn-logout-btn-sec">
                            <h3 className="text-left d-flex justify-content-between">
                            変更する ユーザー情報 を選択
                            </h3>
                            <LogoutButton title={t('general.button.logout')} className={'font-14'} id="logout"/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="mb-3"><Breadcrumbs homeLink="MaintenanceScreen"/></Col>
                        <Col lg="12">
                            <div className="cmn-bg-box">
                                {
                                    updateAlert.isAlert &&
                                    <Alert message = {updateAlert.message} closeHandle ={()=>setUpdateAlert({...updateAlert,isAlert : false})} alertType={updateAlert.alertType}/>
                                }
                               <Row className="mb-4">
                                    <Col lg={9} md={9} className={`${classes.btn_gp}`}>
                                        <GeneralButton title="インポート" className={`cmn-btn font-weight-normal mr-3 ${classes.btn_w_120}`} onClick={() => history.push('/userInformation/import_user_info')}/>
                                        <BackButton title="エクスポート" className="cmn-btn font-weight-normal w-auto mr-3 mt-md-0 mt-2" onClick={exportAll}/>
                                        <BackButton title="+ ユーザー情報を追加" className="cmn-btn font-weight-normal w-auto mt-md-0 mt-2" onClick={() => history.push('/userInformation/add_user_info')}/>
                                   </Col>
                                   <Col className='text-right'>
                                        <GeneralButton id="delete_filter_button" className={`cmn-btn font-weight-normal w-100 m-auto ${classes.btn_keep} ${classes.btn_h_36}`} onClick={isDeleteFilter ?filterDeletedData :resetDeleted} title={isDeleteFilter?"削除対象のユーザーを表示する" :"一覧表示に戻る"}/>
                                   </Col>
                               </Row>
                               <div className="cmn-bg-box-inr">
                                    <Row className="smallest-padding-box8 mb-4 align-items-center">
                                        <Col lg="3" className="mb-2 mb-lg-0">
                                            <SearchTextbox text={searchKeyword} onChange={(e)=>setSearchKeyword(e.target.value)} icon={search_icon} placeholder="" reset_icon={searchKeyword && searchKeyword.length > 0 && reset_icon} resetOnClick={resetSearch} id="inp_search_keyword"  className={`py-1 ${classes.custom_search_btn} ${classes.input_h_36}`}/>
                                        </Col>
                                        <Col lg="2" md="3" className="mb-2 mb-md-0">
                                            <GeneralButton id="user_info_delete_btn" className={`cmn-btn font-weight-normal ${classes.btn_h_36}`} title="検索" onClick={SearchByKeyword}/>
                                        </Col>  
                                        <Col lg="7" md="9" className="text-right">
                                            {
                                                checkedItem.length > 0 ?
                                                <div>
                                                    <GeneralButton id="user_info_keep_btn" className={`cmn-btn font-weight-normal mr-3 ${classes.btn_export} ${classes.btn_h_36}`} title="選択ユーザー エクスポート" onClick={exportSelectedData}/>
                                                    <GeneralButton id="user_info_keep_btn" className={`cmn-btn font-weight-normal ${classes.btn_keep} ${classes.btn_h_36}`} title="削除" onClick={handleDeleteData}/>
                                                </div>
                                                :
                                                <GeneralButton disabled = {submitting ? false : true} id="user_info_keep_btn" onClick={updateData} className={`cmn-btn font-weight-normal ml-3 ${submitting? "" :classes.btn_disabled} ${classes.btn_h_36}`} title="保存" />
                                            }
                                        </Col>                                     
                                        
                                    </Row>
                                    <div className="table-responsive">
                                        <table className={`table table-bordered w-100 mb-4`}>
                                            <thead className={`cmn-table ${classes.head_cells}`}>
                                                <tr >
                                                    <th scope="col" className='text-center py-3'>
                                                        <FormControlLabel className='mx-0 ' color='primary' control={<Checkbox name={`userInfoCheckAll`} id={`userInfoAll`} color='primary' checked={isAllItemChecked(userInfo,checkedItem)?true:false} onChange={handleAllCheckboxChange}/>} />
                                                    </th>
                                                    <th scope="col" width="120" className='align-middle text-nowrap py-3 pl-3'>募集人コード</th>
                                                    <th scope="col" className='align-middle py-3 pl-3'>募集人名</th>
                                                    <th scope="col" className='align-middle py-3 pl-3'>権限</th>
                                                    <th scope="col" className='align-middle py-3 pl-3'>UserPrincipalName</th>
                                                    <th scope="col" className='align-middle py-3 pl-3'>マスターDBにて 削除扱い</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    !reloading ?
                                                    userInfo.map((item,index) => {
                                                        return (
                                                            <tr key={index} className={isItemChecked(item,checkedItem) && classes.tr_check}>
                                                                <th scope="row" className='text-center py-1'>
                                                                    <FormControlLabel className='mx-0' color='primary' control={<Checkbox name={`userInfoCheck${index}`} color='primary' checked={isItemChecked(item,checkedItem)?true:false} id={`userInfoRow${index}`}  onChange={handleCheckboxChange(item.id)}/>} />
                                                                </th>
                                                                <td className='py-1'>
                                                                    <span  style={{cursor : 'pointer',color: "#00A5D9",textDecorationLine : 'underline',lineHeight : '21px'}} onClick={()=>editUser(item)}>{item.empId}</span>
                                                                </td>
                                                                <td className='py-1'>
                                                                    {
                                                                        item.status === 3?<span className='pl-4'>{item.userName}</span>:
                                                                        <GeneralTextbox text={item.userName} onChange={handleUserName(index)} className={`${styles.general_text_box_py_8} ${classes.input_h_36} py-1 border-0`}/>
                                                                    }
                                                                </td>
                                                                <td className='py-1'>
                                                                    {
                                                                        item.status === 3?<span className={`${classes.td_adjust_height}`}>
                                                                            {
                                                                                roleData.find( ({ id }) => id === item.roleId ).name
                                                                            }
                                                                        </span>:
                                                                        <select className={`form-control ${classes.select_box} border-0`} value={item.roleId} onChange={handleRole(index)}>
                                                                            {
                                                                                roleData.map((roleItem,index)=>{
                                                                                    return(
                                                                                    <option key={index} value={roleItem.id}>{roleItem.name}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                            
                                                                        </select>
                                                                    }
                                                                </td>
                                                                <td className='py-1'>
                                                                    {
                                                                        item.status === 3?<span className='pl-4'>{item?.upn?.slice(0,20).concat('...')}</span>:
                                                                        <>
                                                                        {showUpnValidationError(item) && <ErrorMessage message={"UserPrincipalNameの入力が必要です。"} />}
                                                                        {showUpnDuplicateError(item) && <ErrorMessage message={"同じUPNを使用している募集人が既にいるため。"} />}
                                                                        <GeneralTextbox text={item.upn} onChange={handleEmail(index)} className={`${styles.general_text_box_py_8} ${classes.input_h_36} py-1 border-0`}/>
                                                                        </>
                                                                    }
                                                                </td>
                                                                <td className='py-1'>
                                                                    {
                                                                        item.status === 3?"O":""
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr><td colSpan={6} className="text-center">Loading.....</td></tr>
                                                }
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                    <Row>
                                        <Col>
                                            <div className="text-right">
                                                <div className="d-inline-block">
                                                    <Pagination itemPerPage={itemPerPage} className='mb-0' totalData={userInfoData.length} setPageNumber={(v) => {
                                                        setPageNumber(v);
                                                        console.log(v, 'v')
                                                    }}/>                                            
                                                </div>
                                                <label className='ml-2 mr-3 mb-0'>{userInfoData.length}件</label>  
                                                <select className={`${classes.paginate_select_box}`} defaultValue={itemPerPage} onChange={handlePaginationDropdown}>
                                                    <option value={10}>10</option>
                                                    <option value={20}>20</option>
                                                    <option value={50}>50</option>
                                                    <option value={100}>100</option>
                                                    <option value={userInfoData.length}>全て</option>
                                                </select>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}
const stateToProps = state => {
    return {
        isOpen: state.vAgent.isOpen,
        access_token: state.vAgent.access_token
    }
}
export default connect(stateToProps,null)(UserInformationEditList)