import service from './service';
import qs from 'qs';
const getAll =() =>{
    return service({
        url : '/backend/maintenance/users',
        method : 'get'
    })
}
const create = (data)=> {
    return service({
        method : 'POST',
        url : 'backend/maintenance/user',
        data: data
    })
}
const update = (data,empId) =>{
    return service({
        url : 'backend/maintenance/user/'+empId,
        method : 'PUT',
        data: data
    })
}
const updateMultiUsers = (data) =>{
    return service({
        url : 'backend/maintenance/users',
        method : 'PUT',
        data: data
    })
}
const remove = (data)=>{
    return service({
        url : 'userInformations',
        method : 'DELETE',
        data: JSON.stringify(data)
    }) 
}
const removeSelectedUser = (params)=>{
    return service({
        url : 'backend/maintenance/users',
        method : 'DELETE',
        data : params
        // paramsSerializer: () => qs.stringify(params, { arrayFormat: 'repeat' }),
    }) 
}
const importUser = (data) =>{
    console.log('data',data);
    return service({
        url : 'backend/maintenance/users/import',
        method : 'POST',
        data: data,
        headers: {
            'Content-Type': 'text/csv;charset=Shift_JIS'
        }
    })
}

const checkValidation = (url,params) =>{
    return service({
        url : url,
        method : 'GET',
        params : params
    })
}
const userInformationService = {
    getAll,
    create,
    update,
    updateMultiUsers,
    remove,
    removeSelectedUser,
    importUser,
    checkValidation
}

export default userInformationService;