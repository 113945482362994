import {useState,useEffect} from 'react'
import { List,AutoSizer,CellMeasurer,CellMeasurerCache} from "react-virtualized";
import 'react-virtualized/styles.css';
import { GeneralTable01 } from '../../../components/Table/GeneralTable01';
import { getASCodeCompany } from '../../../utils/General';
const VirtualizeTest =(props) =>{
    const {learningTheme,uniqueCode} = props;
    const [data, setData] = useState([])
    useEffect(() => {
      learningTheme && setData(learningTheme);
    }, [learningTheme])
    const cache = new CellMeasurerCache({
        defaultHeight: 30,
        fixedWidth: true,
        keyMapper: () => 1,
      });
    const renderRow = ({ index, key,parent, style }) => {
             var filterLearningThemePattern = data[index].patterns && data[index].patterns.filter((item) => item.id === data[index].selectedPatternId);
             var array = [...uniqueCode]
             var agentCompanyName = data[index] && array.length > 0 && array.filter(item => item.id === data[index].code)
        return (
            <CellMeasurer
                cache={cache}
                columnIndex={0}
                key={key}
                parent={parent}
                rowIndex={index}
                >
                     {({ measure, registerChild }) => (
                        // 'style' attribute required to position cell (within parent List)
                        <div style={style}  ref={registerChild}>
                            {filterLearningThemePattern && <div className="cmn-scroll-inr" key={data[index].selectedPatternId} >
                                {agentCompanyName.length > 0 && <p className="font-14 mb-3" name="study_theme">アソシエイツ名：{agentCompanyName ? getASCodeCompany(agentCompanyName[0].name)[1] : ''}</p>}
                                <GeneralTable01 TableData={filterLearningThemePattern[0].themes} TableClassName="text-center study-theme-table" TableDivClassName="table-responsive" table_id="study_theme_table" />
                            </div>}
                        </div>
                    )}
                </CellMeasurer>
                )
    }
    const getRowHeight =({index}) =>{
        var filterLearningThemePattern = data[index].patterns && data[index].patterns.filter((item) => item.id === data[index].selectedPatternId);
        switch (filterLearningThemePattern && filterLearningThemePattern[0].themes.length) {
            case 3:
                return 240
                break;
            case 4:
                return 300
                break;
        
            case 5:
                return 350
                break;
            case 6:
                return 350
                break;
        
            default:
                return 220;
                break;
        }
    }
    const getHeight = () =>{
        switch (data.length) {
            case 1:
                // return 350;
                var filterLearningThemePattern = data[0].patterns && data[0].patterns.filter((item) => item.id === data[0].selectedPatternId);
                switch (filterLearningThemePattern && filterLearningThemePattern[0].themes.length) {
                    case 3:
                        return 240
                        break;
                    case 4:
                        return 300
                        break;
                
                    case 5:
                        return 350
                        break;
                    case 6:
                        return 350
                        break;
                
                    default:
                        return 220;
                        break;
                }
                break;
        
            default:
                return 450;
                break;
        }
    }
    return(
        <AutoSizer disableHeight>
               {({width}) => (
                <List
                    width={width}
                    height={getHeight()}
                    deferredMeasurementCache={cache}
                    rowRenderer={renderRow}
                    rowCount={data.length}
                    rowHeight={getRowHeight}
                ></List>
                )}
        </AutoSizer>
    )
}
export default VirtualizeTest;