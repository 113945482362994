import {useState,useEffect} from 'react'
import { List,AutoSizer } from "react-virtualized";
import 'react-virtualized/styles.css';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import StyledCheckbox from '../Checkbox'
const VirtualizeDropdownData =(props) =>{
    const {listData,dropdown_id,itemClick} = props;
    const [data, setData] = useState([])
    useEffect(() => {
        listData && setData(listData);
    }, [listData])
    // const data = new Array(1000).fill().map((value, id) => (({
    //     id: id,
    //     title: "test",
    //     body: "test body"
    //   })))
    const renderRow = ({ index, key, style }) => {
        return (
            <li key={index} value={data[index].id} id={`${dropdown_id}${data[index].id}`} style={style}>
                <FormControlLabel
                    style={styles.root}
                    control={<StyledCheckbox
                        value={data[index].id}
                        id={data[index].name}
                        onClick={itemClick}
                        checked={data[index].checked}
                    />}
                    label={<span style={styles.label} id={`${dropdown_id}_item_${index}`}>{data[index].name}</span>}
                />
            </li>
                )
    }
    return(
        <AutoSizer disableHeight>
               {({width}) => (
                <List
                    width={width}
                    height={150}
                    rowRenderer={renderRow}
                    rowCount={data.length}
                    rowHeight={40}
                ></List>
                )}
        </AutoSizer>
    )
}
const styles = {
    root: {
        marginLeft: 0,
        marginBottom: 0
    },
    label: {
        marginButtom: 0,
        fontSize: '14px',
        paddingLeft: '8px',
        fontFamily: 'RobotoRegular'
    },
    selectAllLabel: {
        marginButtom: 0,
        fontSize: '14px',
        paddingLeft: '8px',
        fontFamily: 'MeiryoRegular'
    }
};
export default VirtualizeDropdownData;