import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, NavLink, useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { setIsActive, setIsOpen, setSelectedCompanyCodeAtSelectionOfRecruiter, setSelectedCompanyCodeAtEditLearningTheme } from '../../store/actions';
import { isAflacMemberOrBranchManagerOrTrainerManager, isAflacMemberOrBranchManager, isSystemOwner } from '../../configs/constants'
import { sidebarBgClass, environment, baseDirectory, redirectMaintenanceScreenURL } from '../../configs/environments'
import logo from '../../assets/images/logo1.png';
import sidebaricon01 from '../../assets/images/sidebar_icon/sidebaricon01.svg';
import sidebarDashboard from '../../assets/images/sidebar_icon/sidebarDashboard.svg';
import sidebarSetting from '../../assets/images/sidebar_icon/sidebarSetting.svg';
import sidebarRoleplay from '../../assets/images/sidebar_icon/sidebarRoleplay.svg';
import sidebarAnalysis from '../../assets/images/sidebar_icon/sidebarAnalysis.svg';
import OpenSlideBar from '../../assets/images/sidebar_icon/OpenSlideBar.svg';
import CloseSlideBar from '../../assets/images/sidebar_icon/CloseSlideBar.svg';
import Agency_information_management from '../../assets/images/sidebar_icon/Agency_information_management.svg';
import './styles.css';
import IdleTimer from "../../IdleTimer";
import baseUrl from '../../api/baseUrl';
import axios from 'axios';
import EventBus from '../EventBus';

const Sidebar = ({ setIsOpen, isOpen, isActive, setIsActive, className, style, role, setSelectedCompanyCodeAtSelectionOfRecruiter, setSelectedCompanyCodeAtEditLearningTheme }) => {

    const elementRef = useRef(null);
    // const [isSubMenuIsOpen, setSubMenuIsOpen] = useState(false);
    const [isSubMenuIsOpen, setSubMenuIsOpen] = useState(true);
    const [clear, setClear] = useState(false);

    const { t } = useTranslation();
    const history = useHistory();

    const handleMouseLeave = () => {
        setIsOpen(false);
        setSubMenuIsOpen(false);
    };
    const onCloseMenu = () => {
        setIsOpen(!isOpen);
        // setSubMenuIsOpen(isSubMenuIsOpen);
    };
    let lastId = 0;

    const autoId = (prefix = 'sidebar-') => {
        lastId++;
        return `${prefix}${lastId}`;
    }

    localStorage.getItem("_isTimeout") === null && localStorage.setItem("_isTimeout", false)
    localStorage.setItem("_condition", localStorage.getItem("_isTimeout") === "true" ? 'timeout' : 'logout')
    const [isTimeout, setIsTimeout] = useState(false);
    useEffect(() => {
        const timer = new IdleTimer({
            timeout: 1800, //expire after 30 mins
            onTimeout: () => {
                localStorage.setItem("_isTimeout", true)
                setIsTimeout(!isTimeout);
            },
            onExpired: () => {
                localStorage.setItem("_isTimeout", true)
                setIsTimeout(!isTimeout);
                // return window.location.href = '/Logout';
            }
        });

        return () => {
            timer.cleanUp();
        }
    }, [])

    var refreshExpiredTime = setInterval(() => {
        if (localStorage.getItem("_isTimeout") === "true") {
            localStorage.setItem("_condition", 'timeout')
            setClear(true)
            // history.push('/Logout');
            // return window.location.href = baseDirectory +'/Logout'
        }
    }, 1000)

    useEffect(() => {
        if (clear) {
            if (environment === 'production' || environment === 'staging') {
                axios({
                    url: baseUrl + '/',
                    method: 'POST',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                    data: 'session_logout'
                }).then((response) => {
                    history.push('/Logout');
                    EventBus.dispatch('close_snack')
                }).catch((error) => {
                    console.log('Error response: ', error);
                    if(error?.response?.status === 403){
                        history.push('/Logout');
                        EventBus.dispatch('close_snack')
                    }else{
                        axios({
                            url: baseUrl + '/',
                            method: 'POST',
                            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                            data: 'session_logout'
                        }).then((response) => {
                            history.push('/Logout');
                            EventBus.dispatch('close_snack')
                        }).catch((error) => {
                            console.log('2 nd Error response',error);
                            if(error?.response?.status === 403){
                                history.push('/Logout');
                                EventBus.dispatch('close_snack')
                            }else{
                                localStorage.setItem("_isTimeout", false)
                                EventBus.dispatch('show_snack', { message: 'ログアウト失敗しました。', type: "error" })
                                return
                            }
                        });
                    }
                    
                });
            } else {
                history.push('/Logout');
            }
            clearInterval(refreshExpiredTime)
        }
    }, [clear])

    const onSelect = () => {
        setIsActive('active');
        setSelectedCompanyCodeAtSelectionOfRecruiter('')
        setSelectedCompanyCodeAtEditLearningTheme('')
    };
    const redirectToMaintenanceScreen = () => {
        localStorage.clear();
        let targetUrl = redirectMaintenanceScreenURL;
        window.open(targetUrl, '_blank');
    }

    return (
        <>
            <div className={`sidebar-wrap ${isOpen ? 'sidebar-wrap-large' : 'sidebar-wrap-small'}`} ></div>
            <div id="sidebar" className={`${isOpen ? 'sidebar-large' : 'sidebar-small'} over-height ${sidebarBgClass}`} onMouseEnter={() => setIsOpen(!isOpen)} onMouseLeave={handleMouseLeave} >
                <div>
                    <div className="logo-sec">
                        <Link to="/" className="logo_link" id="logo"><img src={logo} className="mw-100 d-inline" alt="logo" /> <span className="font-18 RobotoRegular">{t('siderbar.roleplay support')}</span></Link>
                    </div>
                    <ul className="sidebar-menu" ref={elementRef}>
                        <li id="sidebar_sub_menu" name="sidebar_sub_menu" onClick={() => setIsActive('active')} className={`${isActive === 'active' ? "active" : ""}`}>
                            {/* <a id="roleplay_evaluation" onClick={() => setSubMenuIsOpen(!isSubMenuIsOpen)} to="" > */}
                            <a id="roleplay_evaluation" to="" className={`${environment === 'staging' ? 'bg-darkGreen' : 'bg-darkBlue'}`}>
                                {!isOpen && <img src={sidebaricon01} alt="sidebar icon small-con" id="sub_menu_icon" />}
                                <span id="sub_menu_list"> {t('siderbar.roleplay evaluation')}</span>
                            </a>
                            {/* <div className={`sidebar-submenu ${isSubMenuIsOpen ? "show" : "hide"} ${isOpen ? '' : 'submenu-small'}`}> */}
                            <div className={`sidebar-submenu  ${isOpen ? '' : 'submenu-small'}`}>
                                <p id="roleplay" className="font-weight-bold"><span><img src={sidebarRoleplay} alt="roleplay" /></span>{t('siderbar.roleplay')}</p>
                                <ul>
                                    {(isAflacMemberOrBranchManagerOrTrainerManager() || isSystemOwner()) ? <li id="start_of_new_role_playing" ><NavLink id="start_of_new_role_playing_link" to="/StartOfNewRolePlay" onClick={onSelect} >{t('siderbar.start of new roll playing')}</NavLink></li> : ''}
                                    <li id="confirm_customer_attributes" name="confirm_customer_attributes"><NavLink id="confirm_customer_attributes_link" to="/ConfirmCustomerAttributes" onClick={onSelect} >{t('siderbar.confirm customer attributes')}</NavLink></li>
                                </ul>
                                <p id="dashboard" className="font-weight-bold"><span><img src={sidebarDashboard} alt="dashboard" /></span>{t('siderbar.assessment results')}</p>
                                <ul>
                                    <li id="associates_summary" name="associates_summary"><NavLink id="associates_summary_link" to="/RecruiterSummary" onClick={onSelect}>{t('siderbar.associates summary')}</NavLink></li>
                                    <li id="favorite_list" name="favorite_list" ><NavLink id="favorite_list_link" to="/FavoriteList" onClick={onSelect}>お気に入りロープレ</NavLink></li>
                                </ul>
                                {isAflacMemberOrBranchManager() ||isSystemOwner() ?
                                    <p id="setting" className="font-weight-bold"><span><img src={sidebarSetting} alt="setting" /></span>{t('siderbar.setting')}</p>
                                    : ''}
                                {isAflacMemberOrBranchManager() ||isSystemOwner() ?
                                    <ul>
                                        <li id="edit_selection_of_recruiter" name="edit_selection_of_recruiter"><NavLink id="edit_selection_of_recruiter_link" to="/SelectionOfRecruiter" onClick={onSelect}>{t('siderbar.selection of target recruiters')}</NavLink></li>
                                        <li id="training_plan" name="training_plan"><NavLink id="training_plan_link" to="/TrainingPlan" onClick={onSelect} onMouseDown={onSelect}>{t('siderbar.edit training plan')}</NavLink></li>
                                        <li id="edit_learning_theme" name="edit_learning_theme"><NavLink id="edit_learning_theme_link" to="/EditLearningTheme" onClick={onSelect}>{t('siderbar.edit study theme')}</NavLink></li><li id="edit_check_criteria" name="edit_check_criteria" ><NavLink id="edit_check_criteria_link" to="/EditCheckCriteria" onClick={onSelect}>{t('siderbar.edit check criteria')}</NavLink></li>
                                        {
                                            isSystemOwner() && <li id="edit_keywords" name="edit_keywords"><NavLink id="edit_keywords_link" to="/EditKeywords" onClick={onSelect}>{t('siderbar.keyword editing')}</NavLink></li>
                                        }
                                        {
                                            isSystemOwner() && <li id="edit_keyword_condition" name="edit_keyword_condition" ><NavLink id="edit_keyword_condition_link" to="/KeywordSelection" onClick={onSelect}>{t('siderbar.editing scoring criteria')}</NavLink></li>
                                        }
                                        {
                                            isSystemOwner() && <li id="edit_synonym_by_keyword" name="edit_synonym_by_keyword"><NavLink id="edit_synonym_by_keyword_link" to="/SynonymByKeyword" onClick={onSelect}>{t('siderbar.synonym registration')}</NavLink></li>
                                        }
                                        
                                    </ul>
                                    : ''}
                                {isAflacMemberOrBranchManagerOrTrainerManager() ||isSystemOwner() ?
                                    <p id="analysis" className="font-weight-bold"><span><img src={sidebarAnalysis} alt="analysis" /></span>{t('siderbar.access analysis')}</p>
                                    : ''}
                                {isAflacMemberOrBranchManagerOrTrainerManager() ||isSystemOwner() ?
                                    <ul>
                                        {isSystemOwner() && <li id="number_of_users" name="number_of_users" ><NavLink id="number_of_users_link" to="/UserAccount" onClick={onSelect}>{t('siderbar.number of users')}</NavLink></li>}
                                        {isSystemOwner() && <li id="roleplay_count_and_duration" name="roleplay_count_and_duration" ><NavLink id="roleplay_count_and_duration_link" to="/RoleplayCountAndDuration" onClick={onSelect}>{t('siderbar.total number and time of role-playing')}</NavLink></li>}
                                        {(isAflacMemberOrBranchManagerOrTrainerManager() || isSystemOwner()) && <li id="number_and_time_of_implementation_by_learning theme" name="number_and_time_of_implementation_by_learning theme" ><NavLink to="/TimeAndCountLearningTheme" onClick={onSelect}>{t('siderbar.number and time of implementation by learning theme')}</NavLink></li>}
                                        {isSystemOwner() && <li id="digitalization" name="digitalization"><NavLink to="/Digitalization" onClick={onSelect}>{t('siderbar.digitalization')}</NavLink></li>}
                                        {(isAflacMemberOrBranchManagerOrTrainerManager() ||isSystemOwner()) && <li id="keyword_used_percentage" name="keyword_used_percentage"><NavLink id="keyword_used_percentage_link" to="/KeywordAccuracy" onClick={onSelect}>{t('siderbar.keyword match rate')}</NavLink></li>}
                                    </ul>
                                    : ''}
                                    {isSystemOwner() ?
                                     <p id="Agency_information_management" className="font-weight-bold"><span><img src={Agency_information_management} alt="Agency_information_management" /></span>代理店情報管理</p>
                                     :''}
                                    {isSystemOwner() ? 
                                     <ul>
                                        <li id="edit_agency_information_list" name="edit_agency_information_list">
                                            {/* <a id="edit_agency_information"onClick={redirectToMaintenanceScreen}>代理店情報の編集</a> */}
                                            <NavLink to="/MaintenanceScreen" onClick={onSelect}>代理店情報の編集</NavLink>
                                        </li>
                                     </ul>
                                     :''}
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="sidebar-collapse" onClick={onCloseMenu} id="close"  ><div><img id="close_btn" src={isOpen ? OpenSlideBar : CloseSlideBar} alt="Open Sidebar" /><span id={autoId()} className={isOpen ? "show" : "hide"}> {t('siderbar.close menu')}</span></div></div>
            </div>

        </>
    )
}

const stateToProps = state => {
    return {
        isActive: state.vAgent.isActive,
        isOpen: state.vAgent.isOpen,
        role: state.vAgent.role
    }
}

const dispatchToProps = dispatch => {
    return {
        setIsActive: (isActive) => {
            dispatch(setIsActive(isActive));
        },
        setIsOpen: (isOpen) => {
            dispatch(setIsOpen(isOpen));
        },
        setSelectedCompanyCodeAtSelectionOfRecruiter: (company_at_selection_of_recruiter) => {
            dispatch(setSelectedCompanyCodeAtSelectionOfRecruiter(company_at_selection_of_recruiter));
        },
        setSelectedCompanyCodeAtEditLearningTheme: (company_at_learning_theme) => {
            dispatch(setSelectedCompanyCodeAtEditLearningTheme(company_at_learning_theme));
        }
    }
}


export default connect(stateToProps, dispatchToProps)(Sidebar);