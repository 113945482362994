import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import logout_icon from '../../../assets/images/logout_icon.png';
import styles from './styles.module.css';
import axios from 'axios';
import EventBus from '../../EventBus';
import baseUrl from '../../../api/baseUrl';
import { environment } from '../../../configs/environments';
import store from '../../../store';

const LogoutButton = (props) => {
    const history = useHistory();
    const { id, title, className, style, reset } = props

    useEffect(() => {
        if (reset) {
            onChanged()
        }
    }, [reset])

    const onLogout = () => {
        onChanged()
        // history.push('/Logout');
    }

    const onChanged = () => {
        if (environment === 'production' || environment === 'staging') {
            axios({
                url: baseUrl + '/',
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                data: 'session_logout'
            }).then((response) => {
                store.dispatch({type: 'IS_LOGOUT',is_logout : true})
                history.push('/Logout');
                EventBus.dispatch('close_snack')
            }).catch((error) => {
                console.log('clicked Error response: ', error);
                if(error?.response?.status === 403){
                    store.dispatch({type: 'IS_LOGOUT',is_logout : true})
                    history.push('/Logout');
                    EventBus.dispatch('close_snack')
                }else{
                    axios({
                        url: baseUrl+ '/',
                        method: 'POST',
                        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                        data: 'session_logout'
                    }).then((response) => {
                        store.dispatch({type: 'IS_LOGOUT',is_logout : true})
                        history.push('/Logout');
                        EventBus.dispatch('close_snack')
                    }).catch((error) => {
                        console.log('2 nd clicked Error response: ', error);
                        if(error?.response?.status === 403){
                            store.dispatch({type: 'IS_LOGOUT',is_logout : true})
                            history.push('/Logout');
                            EventBus.dispatch('close_snack')
                        }else{
                            EventBus.dispatch('show_snack', { message: 'ログアウト失敗しました。', type: "error" })
                        }
                    });
                }
                
            });
        } else {
            store.dispatch({type: 'IS_LOGOUT',is_logout : true})
            history.push('/Logout');
        }
    }
    return (
        <button
            id={id}
            className={`logout_btn ${styles.logout_btn} ${className}`}
            style={style}
            onClick={onLogout} >
            <img src={logout_icon} alt="logout" />
            {title}
        </button>
    )
}


export default LogoutButton;