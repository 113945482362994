import React, { useState, useEffect } from 'react';
import { CSVLink } from "react-csv";
import GeneralButton from '../../../components/Buttons/GeneralButton'
import { getPeriod, getUTF8Text } from '../../../utils/General';

const Export = (props) => {
    const { data, multiselectedASCode, selectedTimePeriod, from, to, timePeriodData, visibleTab, id, className, title } = props;
    const [selectedText, setSelectedText] = useState({});
    const [exportData, setExportData] = useState([]);
    useEffect(() => {
        if (selectedTimePeriod.id !== 4) {
            setSelectedText({ from: `${from.year}/${timePeriodData[from.month - 1]}`, to: to ? `${to.year}/${timePeriodData[to.month - 1]}` : "" });
        } else {
            setSelectedText({ from: `${from.year}`, to: `${to.year}` });
        }        
    }, [from, to])


    const sumData = (fieldName) => {
        return data.reduce((a, b) => a + (b[fieldName] || 0), 0);
    }

    useEffect(() => {
        const csvData = [];
        csvData.push([getUTF8Text("Filter By ")])
        const allASCodes = multiselectedASCode.length > 0 && multiselectedASCode.map((x) => x.name).join(', ');
        csvData.push([getUTF8Text("表示形式"), getUTF8Text(getPeriod(selectedTimePeriod.id)[1])])
        csvData.push([getUTF8Text("抽出期間"), getUTF8Text(selectedText.from + "~" + selectedText.to)])
        csvData.push([getUTF8Text("AS コード"), getUTF8Text(allASCodes)])
        csvData.push([])
        csvData.push([visibleTab === 1 ? getUTF8Text("＜延べロープレ回数＞") : getUTF8Text("＜延べロープレ時間＞")])
        csvData.push(['', getUTF8Text('単品編'), getUTF8Text('併売編'), getUTF8Text('総合保障編'), getUTF8Text('アフターフォロー編'), getUTF8Text('団体編'), getUTF8Text("合計")])

        if (data && data.length > 0) {
            for (let i = 0; i < data.length; i += 1) {
                csvData.push([
                    getUTF8Text(data[i].date),
                    getUTF8Text(data[i].単品編 ? data[i].単品編 : 0),
                    getUTF8Text(data[i].併売編 ? data[i].併売編 : 0),
                    getUTF8Text(data[i].総合保障編 ? data[i].総合保障編 : 0),
                    getUTF8Text(data[i].アフターフォロー編 ? data[i].アフターフォロー編 : 0),
                    getUTF8Text(data[i].団体編 ? data[i].団体編 : 0),
                    (parseInt(data[i].単品編 ? data[i].単品編:0 ) + parseInt(data[i].併売編 ? data[i].併売編 : 0) + parseInt(data[i].総合保障編?data[i].総合保障編:0) + parseInt(data[i].アフターフォロー編?data[i].アフターフォロー編:0) + parseInt(data[i].団体編?data[i].団体編:0))
                ]);
            }

            csvData.length > 0 && setExportData(csvData)
            console.log('csvData', csvData);
        }
    }, [data])

    const exportCSV = {
        filename: `${visibleTab === 1 ? 'アクセス解析- 延べロープレ回数.csv' : 'アクセス解析 -延べロープレ時間.csv'}`,
        data: exportData
    }
    return (
        <CSVLink {...exportCSV}>
            <GeneralButton id={`${id ? id : 'export_csv'}`} className={`cmn-btn font-weight-normal ${className}`} title={title ? title : "出力"} />
        </CSVLink>
    )

}
export default Export;