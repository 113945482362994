import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import Sidebar from '../../components/Sidebar';
import LogoutButton from '../../components/Buttons/LogoutButton';
import styles from './styles.module.css';
import { setRecentPath, setLabelAtAnalysisPages } from '../../store/actions'
import { GeneralDropdown } from '../../components/Dropdowns/GeneralDropdown';
import { MultiselectDropdown } from '../../components/Dropdowns/MultiSelectDropdown';
import { longestString, isApigwEnv, getPeriod, getDurationTime, isEmpty, sortByKey, split, getLast13MonthsWithYear, getLast12MonthsInterval } from '../../utils/General';
import { getUserList, getUserCountForAnalysis } from '../../api/api';
import { COMPANY_CODES_URL, ACCESS_ANALYSIS_GET_TOTAL_USER_COUNT_FOR_THEME, ACCESS_ANALYSIS_GET_ACTIVE_USER_COUNT_FOR_THEME, ACCESS_ANALYSIS_GET_INACTIVE_USER_COUNT_FOR_REPORT } from "../../configs/urlConstants";
import BackButton from '../../components/Buttons/BackButton';
import Tab from '../../components/Tabs/AssociateTab';
import BarChart from '../../components/Charts/BarChart';
import {isAflacMemberOrBranchManagerOrTrainerManagerOrSystemOwner } from '../../configs/constants';
import { TimePeriodDropdown } from '../../components/Dropdowns/TimePeriodDropdown';
import filterData from '../../staticData/TimePeriod.json';
import EventBus from '../../components/EventBus';
import Export from './Export';

const UserAccountPage = ({ access_token, isOpen, location, setRecentPath, label_at_analysis, setLabelAtAnalysisPages }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const tabList = [{ id: 1, name: 'ユーザー数' }, { id: 2, name: 'アクティブユーザー数' }]
    const [visibleTab, setVisibleTab] = useState(tabList[0].id);
    const [reset, makeAllReset] = useState(false)
    const [longest, setLongest] = useState();
    const [multiselectedASCode, setMultiSelectedASCode] = useState([]);
    const [activeDropdown, setActiveDropdown] = useState();
    const [asCodeData, setAsCodeData] = useState([]);

    const ref = useRef(null);
    const [props, setProps] = useState();
    const [userCount, setUserCount] = useState();
    const [inactiveUserInfo, setInactiveUserInfo] = useState();
    const [width, setWidth] = useState(0);
    const [padding, setPadding] = useState(0);

    const timePeriod = [{ 'id': 1, 'name': "月別" }, { 'id': 2, 'name': "四半期" }, { 'id': 3, 'name': "半期" }, { 'id': 4, 'name': "年度" }];
    const [selectedTimePeriod, setSelectedTimePeriod] = useState(timePeriod[0]);
    const [timePeriodData, setTimePeriodData] = useState(filterData.byMonth);
    let currentDate = new Date();
    let date = getLast13MonthsWithYear(11);
    const [to, setTo] = useState({ "year": date[0].year, "month": date[0].month });
    const [from, setFrom] = useState({ "year": date[date.length - 1].year, "month": date[date.length - 1].month });
    const [monthly, setMonthly] = useState();
    const [quarterly, setQuarterly] = useState();
    const [halfYearly, setHalfYearly] = useState();
    const [yearly, setYearly] = useState();
    const [selectedFrom, setSelectedFrom] = useState();
    const [selectedTo, setSelectedTo] = useState();
    const [chartData, setChartData] = useState();
    const [inActiveChartData, setInactiveChartData] = useState();

    useEffect(() => {
        getUserList(COMPANY_CODES_URL).then(res => {
            setAsCodeData(res.data.map(item => { return { id: item.id, name: item.name, checked: true, newlyAdded: false, found: true } }).sort((a, b) => parseInt(a.id) - parseInt(b.id)))
            setMultiSelectedASCode(res.data.map(item => { return { id: item.id, name: item.name, checked: true, newlyAdded: false, found: true } }).sort((a, b) => parseInt(a.id) - parseInt(b.id)));

            let longestName = longestString(res.data.map(item => { return { name: item.name } }))
            setLongest(longestName < 15 ? 4 : longestName >= 15 ? 5 : longestName >= 20 ? 6 : longestName >= 25 ? 7 : 8)
        })
        window.scrollTo(0, 0)

        if (!isApigwEnv() && (access_token === '' || access_token === undefined)) {
            history.push("/Logout");
        }

        if (!isAflacMemberOrBranchManagerOrTrainerManagerOrSystemOwner) {
            makeAllReset(!reset)
            return
        }
        setRecentPath(location.pathname);
    }, []);
    useEffect(() => {
        let urlName = visibleTab === 1 ? ACCESS_ANALYSIS_GET_TOTAL_USER_COUNT_FOR_THEME : ACCESS_ANALYSIS_GET_ACTIVE_USER_COUNT_FOR_THEME
        let fromTime = getDurationTime(selectedTimePeriod.id, from, 'from')
        setSelectedFrom(fromTime)
        let toTime = getDurationTime(selectedTimePeriod.id, to, 'to')
        setSelectedTo(toTime)
        let filteredASCode = multiselectedASCode.map(function (a) { return a.id; });

        let params = {
            groupByKey: getPeriod(selectedTimePeriod.id)[0],
            from: fromTime,
            to: toTime,
            agentCodeList: filteredASCode
        };

        visibleTab !== undefined && multiselectedASCode.length > 0 && getUserCountForAnalysis(urlName, params).then(res => {
            setUserCount(res.data.flat())
            setWidth(0)
        }).catch(err => {
            if (err.response.status === 400) {
                EventBus.dispatch('show_snack', { message: err.response.data.message, type: 'error' });
            }
            else {
                EventBus.dispatch('show_snack', { message: '問題が発生しています', type: 'error' });
            }
        })
        multiselectedASCode.length > 0 && visibleTab === 2 && getUserCountForAnalysis(ACCESS_ANALYSIS_GET_INACTIVE_USER_COUNT_FOR_REPORT, params).then(res => {
            setInactiveUserInfo(res.data)
        }).catch(err => {
            if (err.response.status === 400) {
                EventBus.dispatch('show_snack', { message: err.response.data.message, type: 'error' });
            }
            else {
                EventBus.dispatch('show_snack', { message: '問題が発生しています', type: 'error' });
            }
        })


    }, [multiselectedASCode, from, to, visibleTab])

    useEffect(() => {

        var groups = ['aa_year', 'aa_group_by'], grouped = {}, ordered = [], formatData = [], formatArray = [], inActiveFormatData = [],
            fromTime = [from.year, from.month], toTime = [to.year, to.month],
            defaultArray = visibleTab === 1 ?
                { 'date': '', '初期教育': 0, '単品編': 0, '併売編': 0, '総合保障編': 0, 'アフターフォロー編': 0, '団体編': 0, '修了生': 0 }
                : { 'date': '', '初期教育': 0, '単品編': 0, '併売編': 0, '総合保障編': 0, 'アフターフォロー編': 0, '団体編': 0, '非アクティブ人数': 0 };

        userCount !== undefined && userCount.forEach(function (a) {
            groups.reduce(function (o, g, i) {                            // take existing object,
                o[a[g]] = o[a[g]] || (i + 1 === groups.length ? [] : {}); // or generate new obj, or
                return o[a[g]];                                           // at last, then an array
            }, grouped).push(a);
        });
        ordered = !isEmpty(grouped) && sortByKey(grouped)             // sort year

        let years = Object.getOwnPropertyNames(ordered), arr = [],
            fromDate = fromTime && [...fromTime];
        while ((fromDate && parseInt(fromDate[0])) <= (toTime && parseInt(toTime[0]))) {
            arr.push(fromDate[0].toString());
            fromDate[0] = parseInt(fromDate[0]) + 1
        }
        if (arr && arr.length > 0 && years) {
            for (let i = 0; i < arr.concat(years.filter((item) => arr.indexOf(item) < 0)).length; i++) {
                let obj = {};
                if (years.includes(arr[i])) {
                    Object.assign(obj, ordered[arr[i]])
                } else {
                    Object.assign(obj, {})
                }
                formatArray.push(obj)
            }
        }
        switch (selectedTimePeriod.id) {
            case 1:
                if (formatArray && formatArray.length > 0) {
                    formatArray.map((year, index) => {
                        let toDate = (formatArray.length - 1) === index ? parseInt(toTime[1]) : '12';
                        let fromDate = (formatArray.length === 1) || (index === 0) ? parseInt(fromTime[1]) : '1';

                        while (parseInt(fromDate) <= parseInt(toDate)) {
                            let obj = Object.assign({}, defaultArray);
                            let array = year[fromDate]
                            if (array) {
                                for (let value of array) {
                                    Object.assign(obj, { 'date': fromTime[0] + '年' + fromDate + '月' })
                                    'InitialTraineeCount' in value
                                        && Object.assign(obj, { '初期教育': value.InitialTraineeCount })
                                    value.learningThemeName === '単品編'
                                        && Object.assign(obj, { '単品編': value.totalUserCount })
                                    value.learningThemeName === '併売編'
                                        && Object.assign(obj, { '併売編': value.totalUserCount })
                                    value.learningThemeName === '総合保障編'
                                        && Object.assign(obj, { '総合保障編': value.totalUserCount })
                                    value.learningThemeName === 'アフターフォロー編'
                                        && Object.assign(obj, { 'アフターフォロー編': value.totalUserCount })
                                    value.learningThemeName === '団体編'
                                        && Object.assign(obj, { '団体編': value.totalUserCount })
                                    'GraduateTotalUserCount' in value
                                        && Object.assign(obj, { '修了生': value.GraduateTotalUserCount })
                                    'InactiveUserCount' in value
                                        && Object.assign(obj, { '非アクティブ人数': value.InactiveUserCount })
                                }
                            }
                            else {
                                Object.assign(obj, { 'date': fromTime[0] + '年' + fromDate + '月' })
                            }
                            fromDate = parseInt(fromDate) + 1
                            if (visibleTab === 2) {
                                inActiveFormatData.push(obj)
                                let chartObj = { ...obj }
                                delete chartObj['非アクティブ人数']
                                formatData.push(chartObj)
                            } else {
                                formatData.push(obj)
                            }
                        }
                        fromTime[0] = parseInt(fromTime[0]) + 1
                    });
                    setChartData(formatData)
                    visibleTab === 2 && setInactiveChartData(inActiveFormatData)
                }
                break;
            case 2:
                if (formatArray && formatArray.length > 0) {
                    formatArray.map((year, index) => {
                        let toDate = (formatArray.length - 1) === index ? parseInt(toTime[1]) : '4';
                        let fromDate = (formatArray.length === 1) || (index === 0) ? parseInt(fromTime[1]) : '1';
                        while (parseInt(fromDate) <= parseInt(toDate)) {
                            let obj = Object.assign({}, defaultArray);
                            let array = year[fromDate]
                            let fromValue = getDurationTime(2, { year: fromTime[0], month: parseInt(fromDate) }, 'from', true);
                            let toValue = getDurationTime(2, { year: fromTime[0], month: parseInt(fromDate) }, 'to', true);
                            if (array) {
                                for (let value of array) {
                                    Object.assign(obj, { 'date': fromValue.split('-')[1] + '-' + toValue.split('-')[1] + `月（${fromTime[0]} ${fromDate}Q)` })
                                    'InitialTraineeCount' in value
                                        && Object.assign(obj, { '初期教育': value.InitialTraineeCount })
                                    value.learningThemeName === '単品編'
                                        && Object.assign(obj, { '単品編': value.totalUserCount })
                                    value.learningThemeName === '併売編'
                                        && Object.assign(obj, { '併売編': value.totalUserCount })
                                    value.learningThemeName === '総合保障編'
                                        && Object.assign(obj, { '総合保障編': value.totalUserCount })
                                    value.learningThemeName === 'アフターフォロー編'
                                        && Object.assign(obj, { 'アフターフォロー編': value.totalUserCount })
                                    value.learningThemeName === '団体編'
                                        && Object.assign(obj, { '団体編': value.totalUserCount })
                                    'GraduateTotalUserCount' in value
                                        && Object.assign(obj, { '修了生': value.GraduateTotalUserCount })
                                    'InactiveUserCount' in value
                                        && Object.assign(obj, { '非アクティブ人数': value.InactiveUserCount })
                                }
                            }
                            else {
                                Object.assign(obj, { 'date': fromValue.split('-')[1] + '-' + toValue.split('-')[1] + `月 （${fromTime[0]} ${fromDate}Q)` })
                            }
                            fromDate = parseInt(fromDate) + 1
                            if (visibleTab === 2) {
                                inActiveFormatData.push(obj)
                                let chartObj = { ...obj }
                                delete chartObj['非アクティブ人数']
                                formatData.push(chartObj)
                            } else {
                                formatData.push(obj)
                            }
                        }
                        fromTime[0] = parseInt(fromTime[0]) + 1
                    });
                    setChartData(formatData)
                    visibleTab === 2 && setInactiveChartData(inActiveFormatData)
                }
                break;
            case 3:
                if (formatArray && formatArray.length > 0) {
                    // setPadding(0.6)
                    formatArray.map((year, index) => {
                        let toDate = (formatArray.length - 1) === index ? parseInt(toTime[1]) : 2;
                        let fromDate = (formatArray.length === 1) || (index === 0) ? parseInt(fromTime[1]) : 1;
                        while (parseInt(fromDate) <= parseInt(toDate)) {
                            let obj = Object.assign({}, defaultArray);;
                            let array = year[fromDate]
                            let fromValue = getDurationTime(3, { year: fromTime[0], month: parseInt(fromDate) }, 'from', true);
                            let toValue = getDurationTime(3, { year: fromTime[0], month: parseInt(fromDate) }, 'to', true);
                            let current = fromDate === 1 ? `月（${fromTime[0]}上期)` : `月（${fromTime[0]}下期)`;
                            if (array) {
                                for (let value of array) {
                                    Object.assign(obj, { 'date': fromValue.split('-')[1] + '-' + toValue.split('-')[1] + current })
                                    'InitialTraineeCount' in value
                                        && Object.assign(obj, { '初期教育': value.InitialTraineeCount })
                                    value.learningThemeName === '単品編'
                                        && Object.assign(obj, { '単品編': value.totalUserCount })
                                    value.learningThemeName === '併売編'
                                        && Object.assign(obj, { '併売編': value.totalUserCount })
                                    value.learningThemeName === '総合保障編'
                                        && Object.assign(obj, { '総合保障編': value.totalUserCount })
                                    value.learningThemeName === 'アフターフォロー編'
                                        && Object.assign(obj, { 'アフターフォロー編': value.totalUserCount })
                                    value.learningThemeName === '団体編'
                                        && Object.assign(obj, { '団体編': value.totalUserCount })
                                    'GraduateTotalUserCount' in value
                                        && Object.assign(obj, { '修了生': value.GraduateTotalUserCount })
                                    'InactiveUserCount' in value
                                        && Object.assign(obj, { '非アクティブ人数': value.InactiveUserCount })
                                }
                            }
                            else {
                                Object.assign(obj, { 'date': fromValue.split('-')[1] + '-' + toValue.split('-')[1] + current })
                            }
                            if (visibleTab === 2) {
                                inActiveFormatData.push(obj)
                                let chartObj = { ...obj }
                                delete chartObj['非アクティブ人数']
                                formatData.push(chartObj)
                            } else {
                                formatData.push(obj)
                            }
                            fromDate = parseInt(fromDate) + 1

                        }
                        fromTime[0] = parseInt(fromTime[0]) + 1
                    });
                    setChartData(formatData)
                    visibleTab === 2 && setInactiveChartData(inActiveFormatData)
                }
                break;
            case 4:
                if (formatArray && formatArray.length > 0) {
                    // setPadding(0.2)
                    formatArray.map((year, index) => {
                        let fromDate = parseInt(fromTime[0]);
                        let obj = Object.assign({}, defaultArray);
                        let array = year[fromTime[0]]
                        if (array) {
                            for (let value of array) {
                                Object.assign(obj, { 'date': fromTime[0] + '年' })
                                'InitialTraineeCount' in value
                                    && Object.assign(obj, { '初期教育': value.InitialTraineeCount })
                                value.learningThemeName === '単品編'
                                    && Object.assign(obj, { '単品編': value.totalUserCount })
                                value.learningThemeName === '併売編'
                                    && Object.assign(obj, { '併売編': value.totalUserCount })
                                value.learningThemeName === '総合保障編'
                                    && Object.assign(obj, { '総合保障編': value.totalUserCount })
                                value.learningThemeName === 'アフターフォロー編'
                                    && Object.assign(obj, { 'アフターフォロー編': value.totalUserCount })
                                value.learningThemeName === '団体編'
                                    && Object.assign(obj, { '団体編': value.totalUserCount })
                                'GraduateTotalUserCount' in value
                                    && Object.assign(obj, { '修了生': value.GraduateTotalUserCount })
                                'InactiveUserCount' in value
                                    && Object.assign(obj, { '非アクティブ人数': value.InactiveUserCount })
                            }
                        }
                        else {
                            Object.assign(obj, { 'date': fromTime[0] + '年' })
                        }
                        if (visibleTab === 2) {
                            inActiveFormatData.push(obj)
                            let chartObj = { ...obj }
                            delete chartObj['非アクティブ人数']
                            formatData.push(chartObj)
                        } else {
                            formatData.push(obj)
                        }
                        fromTime[0] = parseInt(fromTime[0]) + 1
                    });
                    setChartData(formatData)
                    visibleTab === 2 && setInactiveChartData(inActiveFormatData)
                }
                break;
            default:
                break;
        }
        // console.log('formatData', formatData)
        // console.log('formatArray', formatArray)
        // console.log('order', ordered)
        // console.log('userCount', userCount)
        // console.log('data', data)
        // console.log('from', from)
        // console.log('to', to)
    }, [userCount])  // prepare data for chart and export

    useEffect(() => {
        if (chartData && chartData.length > 0) {
            let dataLength = chartData.length
            let value = selectedTimePeriod.id === 4 ? 2 : 1;
            switch (value) {
                case 1:
                    let widthMonthly = 0
                    for (let i = 0; i < dataLength; i++) {
                        if (i >= 12) {
                            if (dataLength < 15) {
                                widthMonthly += 100;
                            }
                            else  {
                                widthMonthly += 57;
                            }
                        }
                        else {
                            widthMonthly = 800;
                        }
                    }
                    widthMonthly > 0 && setPadding(0.7);
                    widthMonthly > 0 && setWidth(widthMonthly < 800 ? 800 : widthMonthly)
                    break;
                case 2:
                    setPadding(0.6);
                    setWidth(800)
                    break;

                default:
                    setMonthly([from, to])
                    break;
            }
        }
        (width && chartData !== undefined) && setProps({
            data: chartData,
            keys: visibleTab === 1 ? ['初期教育', '単品編', '併売編', '総合保障編', 'アフターフォロー編', '団体編', '修了生'] : ['初期教育', '単品編', '併売編', '総合保障編', 'アフターフォロー編', '団体編'],
            padding: padding,
            height: 600,
            colors: visibleTab === 1 ? ['#DB2F11', '#4876D2', '#45984C', '#56459C', '#DC83A6', '#E28732', '#A95D24'] : ['#DB2F11', '#4876D2', '#45984C', '#56459C', '#DC83A6', '#E28732'],
            width: (ref.current.offsetWidth !== null && ref.current.offsetWidth >= width) ? ref.current.offsetWidth : width,
            type: "人",
            layout: "vertical"
        })
    }, [ref.current, isOpen, multiselectedASCode, visibleTab, width, padding, chartData])  // chart data

    useEffect(() => {
        setLabelAtAnalysisPages('人')
    }, [visibleTab])

    useEffect(() => {
        switch (selectedTimePeriod.id) {
            case 1:
                setMonthly([from, to])
                break;
            case 2:
                setQuarterly([from, to])
                break;
            case 3:
                setHalfYearly([from, to])
                break;
            case 4:
                setYearly([from, to])
                break;

            default:
                setMonthly([from, to])
                break;
        }
    }, [from, to, selectedTimePeriod])

    const handleTimePeriod = (period) => {
        setSelectedTimePeriod({ id: period.value, name: period.textContent });
        let defaultDuration = getLast12MonthsInterval(period.value);        
        switch (period.value) {
            case 1:
                setTimePeriodData(filterData.byMonth)
                setFrom(monthly !== undefined && monthly.length > 0 ? monthly[0] : { "year": defaultDuration[1].year, "month": defaultDuration[1].month });
                setTo(monthly !== undefined && monthly.length > 0 ? monthly[1] : { "year": defaultDuration[0].year, "month": defaultDuration[0].month });
             break;
            case 2:
                setTimePeriodData(filterData.byQuarter)
                setFrom(quarterly !== undefined && quarterly.length > 0 ? quarterly[0] : { "year": defaultDuration[1].year, "month": defaultDuration[1].month });
                setTo(quarterly !== undefined && quarterly.length > 0 ? quarterly[1] : { "year": defaultDuration[0].year, "month": defaultDuration[0].month });
                break;
            case 3:
                setTimePeriodData(filterData.bySemiAnnual)
                setFrom(halfYearly !== undefined && halfYearly.length > 0 ? halfYearly[0] : { "year": defaultDuration[1].year, "month": defaultDuration[1].month });
                setTo(halfYearly !== undefined && halfYearly.length > 0 ? halfYearly[1] : { "year": defaultDuration[0].year, "month": defaultDuration[0].month });
               break;
            case 4:
                setTimePeriodData([])
                setFrom(yearly !== undefined && yearly.length > 0 ? yearly[0] : { "year": currentDate.getFullYear() });
                setTo(yearly !== undefined && yearly.length > 0 ? yearly[1] : { "year": currentDate.getFullYear() });
                break;

            default:
                setTimePeriodData(filterData.byMonth)
                setFrom({ "year": currentDate.getFullYear(), "month": 1 });
                setTo({ "year": currentDate.getFullYear(), "month": 12 });
                break;
        }
    }

    // useEffect(() => {
    //     console.log('props', props)
    // }, [props])
    return (
        <>
            <Sidebar />
            <div id="main-content" className={`${isOpen ? 'content-large' : 'content-small'}`}>
                <div className="main-content-inr" id="user_account">
                    <Row>
                        <Col lg="12" className="cmn-logout-btn-sec">
                            <h3 className="text-left d-flex justify-content-between" id="user_account_page">
                                {t('user account.title')}
                            </h3>
                            <LogoutButton title={t('general.button.logout')} className={'font-14'} id="logout_btn" reset={reset} />
                        </Col>
                    </Row>
                    <div className=" cmn-bg-box">
                        <Row>
                            <Col xl="10" lg="12" className="order-xl-0 order-1 col-xl-10 order-xl-0 d-flex flex-wrap  p-0">
                                <Col xl={isOpen ? 4 : 3} className={` mb-2 ${styles.AS_code_dropdown}`}>
                                    <div className="cmn-bg-box-inr p-3">
                                        <label className="mb-2 font-weight-bold" id="display_format">{t('roleplay count and duration.display_format')}</label>
                                        <GeneralDropdown
                                            isStatic={true}
                                            staticData={timePeriod}
                                            onSelect={(el) => handleTimePeriod(el)}
                                            selectedData={selectedTimePeriod && selectedTimePeriod.name}
                                            placeholder="選択してください"
                                            dropdown_id="time_period_dropdown" />
                                    </div>
                                </Col>
                                <Col xl={isOpen ? 4 : 3} className={`mb-2 ${styles.AS_code_dropdown}`}>
                                    <div className="cmn-bg-box-inr p-3">
                                        <label className="mb-2 font-weight-bold" id="extraction_period">{t('roleplay count and duration.extraction_period')}</label>
                                        <TimePeriodDropdown
                                            data={timePeriodData}
                                            selectedTimePeriod={selectedTimePeriod.id}
                                            from={from}
                                            to={to}
                                            setFrom={setFrom}
                                            setTo={setTo}
                                            dropdown_id="time_picker_dropdown"
                                            timePickerclassName={'timePicker'}
                                        />
                                    </div>
                                </Col>
                                <Col xl={isOpen ? 4 : 3} className={`mb-2 ${styles.AS_code_dropdown}`}>
                                    {/* <Col xl={isOpen ? 4 : longest} className={`mb-2 ${styles.AS_code_dropdown}`}> */}
                                    <div className="cmn-bg-box-inr p-3">
                                        <label className="mb-2 font-weight-bold" id="AS_code">{t('roleplay count and duration.AS_code')}</label>
                                        <MultiselectDropdown
                                            selectAll={true}
                                            isStatic={true}
                                            staticData={asCodeData}
                                            selectAllText="全AS"
                                            dropdown_id="AS_code_dropdown"
                                            placeholder={t('general.text.theme select')}
                                            multiSelectedData={multiselectedASCode} setMultiSelectedData={setMultiSelectedASCode}
                                            activeDropdown={activeDropdown} setActiveDropdown={setActiveDropdown}
                                            message="ASコードが選択されていません" />
                                    </div>
                                </Col>
                            </Col>
                            <Col xl="2" className={`back-btn-box  text-right mx-0`}>
                                <BackButton id={`go_back_btn`} className="back-btn mb-3" title={t('general.button.top')} onClick={() => { history.push('/RecruiterSummary') }} />
                            </Col>
                        </Row>
                        <Row className={`py-4`}>
                            <Col lg="12" >
                                <div className={`${styles.user_account_tab}`}>
                                    <Tab
                                        visibleTab={visibleTab}
                                        setVisibleTab={setVisibleTab}
                                        className="mx-0 mb-32"
                                        tabList={tabList !== undefined && tabList} />
                                    <div className={`${styles.bar_height}`} ref={ref}>
                                        {props && <BarChart {...props} />}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {visibleTab === 1 ?
                                    <Export
                                        data={chartData}
                                        visibleTab={visibleTab}
                                        selectedTimePeriod={selectedTimePeriod}
                                        from={from}
                                        to={to}
                                        timePeriodData={timePeriodData}
                                        multiselectedASCode={multiselectedASCode}
                                        filename={'ユーザー数.csv'}
                                        inactiveUserDetail={false}
                                    />
                                    : <>
                                        <Export
                                            data={inActiveChartData}
                                            visibleTab={visibleTab}
                                            selectedTimePeriod={selectedTimePeriod}
                                            from={from}
                                            to={to}
                                            timePeriodData={timePeriodData}
                                            multiselectedASCode={multiselectedASCode}
                                            filename={'アクティブユーザー数.csv'}//sheet and file name
                                            inactiveUserDetail={false}
                                            title="アクティブユーザー数出力"//button name
                                            className={`ml-3 px-3 w-auto`}
                                        />
                                        <Export
                                            data={inactiveUserInfo}
                                            visibleTab={visibleTab}
                                            selectedTimePeriod={selectedTimePeriod}
                                            from={from}
                                            to={to}
                                            timePeriodData={timePeriodData}
                                            multiselectedASCode={multiselectedASCode}
                                            inactiveUserDetail={true}
                                            filename={`非アクティブユーザー情報.csv`}//sheet and file name
                                            title={t('general.button.inactive number output')}//button name
                                            className={`ml-3 px-3 ${styles.inactive_export}`} id="inactive_export_csv"
                                        />
                                    </>
                                }
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>

    )
}
const stateToProps = state => {
    return {
        isOpen: state.vAgent.isOpen,
        access_token: state.vAgent.access_token,
        label_at_analysis: state.vAgent.label_at_analysis
    }
}

const dispatchToProps = dispatch => {
    return {
        setRecentPath: (recent_path) => {
            dispatch(setRecentPath(recent_path));
        },
        setLabelAtAnalysisPages: (label_at_analysis) => {
            dispatch(setLabelAtAnalysisPages(label_at_analysis));
        }
    }
}
export default connect(stateToProps, dispatchToProps)(UserAccountPage);
