import React, { useState, useEffect } from 'react';
import { CSVLink } from "react-csv";
import GeneralButton from '../../../components/Buttons/GeneralButton'
import { getPeriod, getUTF8Text, timeStampToDate, split, getUserSatus, zeroPad } from '../../../utils/General';

const Export = (props) => {
    const { data, multiselectedASCode, selectedTimePeriod, from, to, timePeriodData, visibleTab, id, className, title, filename, inactiveUserDetail, inactiveUserInfo } = props;
    const [selectedText, setSelectedText] = useState({});
    const [exportData, setExportData] = useState([]);
    useEffect(() => {
        if (selectedTimePeriod.id !== 4) {
            setSelectedText({ from: `${from.year}/${timePeriodData[from.month - 1]}`, to: to ? `${to.year}/${timePeriodData[to.month - 1]}` : "" });
        } else {
            setSelectedText({ from: `${from.year}`, to: `${to.year}` });
        }
    }, [from, to])


    const sumData = (fieldName) => {
        return data.reduce((a, b) => a + (b[fieldName] || 0), 0);
    }

    useEffect(() => {
        if (data) {
            const csvData = [];
            csvData.push([getUTF8Text("Filter By ")])
            const allASCodes = multiselectedASCode.length > 0 && multiselectedASCode.map((x) => x.name).join(', ');
            csvData.push([getUTF8Text("表示形式"), getUTF8Text(getPeriod(selectedTimePeriod.id)[1])])
            csvData.push([getUTF8Text("抽出期間"), getUTF8Text(selectedText.from + "~" + selectedText.to)])
            csvData.push([getUTF8Text("AS コード"), getUTF8Text(allASCodes)])
            csvData.push([])
            if (!inactiveUserDetail) {
                csvData.push([visibleTab === 1 ? getUTF8Text("＜ユーザー数＞") : getUTF8Text("＜アクティブユーザー数＞")])
                visibleTab === 1
                    ? csvData.push(['', getUTF8Text('初期教育'), getUTF8Text('単品編'), getUTF8Text('併売編'), getUTF8Text('総合保障編'), getUTF8Text('アフターフォロー編'), getUTF8Text('団体編'), getUTF8Text('修了生'), getUTF8Text("合計")])
                    : csvData.push(['', getUTF8Text('初期教育'), getUTF8Text('単品編'), getUTF8Text('併売編'), getUTF8Text('総合保障編'), getUTF8Text('アフターフォロー編'), getUTF8Text('団体編'), getUTF8Text("合計"), getUTF8Text("非アクティブ人数")])

                if (data && data.length > 0) {
                    for (let i = 0; i < data.length; i += 1) {
                        visibleTab === 1 ? csvData.push([
                            data[i].date && getUTF8Text(data[i].date),
                            data[i].初期教育 && getUTF8Text(data[i].初期教育),
                            data[i].単品編 && getUTF8Text(data[i].単品編),
                            data[i].併売編 && getUTF8Text(data[i].併売編),
                            data[i].総合保障編 && getUTF8Text(data[i].総合保障編),
                            data[i].アフターフォロー編 && getUTF8Text(data[i].アフターフォロー編),
                            data[i].団体編 && getUTF8Text(data[i].団体編),
                            data[i].修了生 && getUTF8Text(data[i].修了生),
                            (parseInt(data[i].初期教育) + parseInt(data[i].単品編) + parseInt(data[i].併売編) + parseInt(data[i].総合保障編) + parseInt(data[i].アフターフォロー編) + parseInt(data[i].団体編) + parseInt(data[i].修了生))

                        ])
                            : csvData.push([
                                data[i].date && getUTF8Text(data[i].date),
                                data[i].初期教育 && getUTF8Text(data[i].初期教育),
                                data[i].単品編 && getUTF8Text(data[i].単品編),
                                data[i].併売編 && getUTF8Text(data[i].併売編),
                                data[i].総合保障編 && getUTF8Text(data[i].総合保障編),
                                data[i].アフターフォロー編 && getUTF8Text(data[i].アフターフォロー編),
                                data[i].団体編 && getUTF8Text(data[i].団体編),
                                (parseInt(data[i].初期教育) + parseInt(data[i].単品編) + parseInt(data[i].併売編) + parseInt(data[i].総合保障編) + parseInt(data[i].アフターフォロー編) + parseInt(data[i].団体編)),
                                data[i].非アクティブ人数 && getUTF8Text(data[i].非アクティブ人数),
                            ]);
                    }
                }
                csvData.length > 0 && setExportData(csvData)
            } else {
                csvData.push([])
                csvData.push(['＃', getUTF8Text('種類'), getUTF8Text('ASコード'), getUTF8Text('出先コード'), getUTF8Text('募集人名'), getUTF8Text('最新実施日')])
                if (data && data.length > 0) {
                    for (let i = 0; i < data.length; i += 1) {
                        let date = timeStampToDate(data[i].AA_ROLEPLAY_DATE).split('-')
                        csvData.push([
                            getUTF8Text(i + 1),
                            getUTF8Text(getUserSatus(data[i].UserCategory)),
                            getUTF8Text(data[i].ACOMP_COMP_CODE + '/' + data[i].ACOMP_COMP_NAME),
                            getUTF8Text(data[i].BRANCH_CODE + '-' + data[i].BRANCH_NAME),
                            getUTF8Text(data[i].UD_USER_NAME),
                            getUTF8Text((' ' + date[0] + '/' + zeroPad(date[1], 2) + '/' + zeroPad(date[2], 2))),
                        ]);
                    }
                }
                csvData.length > 0 && setExportData(csvData)
            }
        }
    }, [data, multiselectedASCode])

    const exportCSV = {
        filename: filename,
        data: exportData
    }
    return (
        <CSVLink {...exportCSV}>
            <GeneralButton id={`${id ? id : 'export_csv'}`} className={`cmn-btn font-weight-normal ${className}`} title={title ? title : "出力"} />
        </CSVLink>
    )

}
export default Export;