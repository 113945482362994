import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import MainLogo from '../../assets/images/logo.png';
import GeneralTextbox from '../../components/Textboxes/GeneralTextbox';
import GeneralButton from '../../components/Buttons/GeneralButton';
import { setSelectedUserName, setSelectedAgentCompany, setSelectedEmployeeId, setSelectedRole, setSelectedUserId, accessToken, setRequestHeaderUserId, setRequestHeaderGroupId, setSyncStatus } from '../../store/actions';
import ErrorMessage from '../../components/ErrorMessage';
import ForgotPasswordPage from '../ForgotPasswordPage';
import valid_con from '../../assets/images/valid_icon.svg';
import styles from './styles.module.css';
import { me } from '../../api/api';
import error_icon from '../../assets/images/error_icon.png';
import EventBus from '../../components/EventBus/index';
import { isAflacMember, isSystemOwner, isTrainerManagerOrTrainee } from '../../configs/constants'
import { syncForTrainerOrTrainee, syncForAflacMember } from '../../utils/syncState'
import store from '../../store';

const LoginPage = ({ username, setSelectedUserName, agent_company, setSelectedAgentCompany, setSelectedEmployeeId, role, setSelectedRole, setSelectedUserId, recent_path, access_token, accessToken, setRequestHeaderUserId, setRequestHeaderGroupId, setSyncStatus }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [user_id, setUserId] = useState();
    const [usernameRequire, setUserNameRequire] = useState(false);
    const [password, setPassword] = useState();
    const [passwordRequire, setPasswordRequire] = useState(false);
    const [message, setMessage] = useState('');
    // useEffect(() => {
    //     route()
    // }, [access_token, role])

    // useEffect(() => {
    //     access_token === '' && recent_path !== '' && EventBus.dispatch('show_snack', { message: 'トークンの有効期限が切れました', type: "error" })
    // }, [])

    const syntax = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            login()
        }
    }

    const onUserNameChange = (event) => {
        // EventBus.dispatch('close_snack')
        // setMessage('');
        setUserId(event.target.value);
        // event.target.value.length > 13 || event.target.value.length < 1 ? setUserNameRequire(true) : setUserNameRequire(false);
        // password && password === undefined && password.length < 1 ? setPasswordRequire(true) : setPasswordRequire(false);
    }

    const onPasswordChange = (event) => {
        // EventBus.dispatch('close_snack')
        // setMessage('');
        setPassword(event.target.value);
        
        // if (event.target.value.length < 1) {
        //     setPasswordRequire(true)
        // }
        // else if (event.target.value.length > 0) {
        //     setPasswordRequire(false)
        //     EventBus.dispatch('close_snack')
        // }
        // user_id !== undefined && user_id.length > 13 ? setUserNameRequire(true) : setUserNameRequire(false);
    }

    const login = async () => {
        // setTimeout(function () {
        //     EventBus.dispatch('close_snack')
        // }, 2000);
        setRequestHeaderUserId(user_id)
        setRequestHeaderGroupId(password)

        // if ((user_id !== undefined && user_id.length <= 13 && user_id.length > 0) && password) {
        let value = '', status_code = '';
        me().then(res => {
            value = res.data
            status_code = res.status;
            if (status_code === 200 && value !== '' && value.name !== undefined && value.agentCompany.name !== undefined) {
                EventBus.dispatch('close_snack')
                setSelectedUserId(value.id);
                setSelectedUserName(value.name);
                setSelectedEmployeeId(value.empId);
                store.dispatch({type: 'IS_LOGOUT',is_logout : false})
                setSelectedRole(value.role);
                setSelectedAgentCompany(value.agentCompany);
                accessToken('sample token');
                localStorage.removeItem("_expiredTime");
                localStorage.setItem("_isTimeout", false)

                if (isTrainerManagerOrTrainee()) {
                    // syncForTrainerOrTrainee(value, '/Login')
                }
                if (isAflacMember() || isSystemOwner()) {
                    // syncForAflacMember()
                }
                if (value !== '') {
                    console.log('value', value);
                    (recent_path !== '') ? history.replace(recent_path) : history.replace("/RecruiterSummary");
                }
            }
        })

        // else {
        //     setUserNameRequire(true);
        //     setPasswordRequire(true);
        // }

        // } else {
        //     if (user_id === undefined || user_id.length > 13 || user_id.length < 1) {
        //         setUserNameRequire(true);
        //     }

        //     if (password === undefined || password.length < 1) {
        //         setPasswordRequire(true);
        //     }
        // }
    }

    // function route() {
    //     if (access_token !== '') {
    //         (recent_path !== '') ? history.replace(recent_path) : history.replace("/RecruiterSummary");
    //     }

    // }
    return (
        <div id={styles.login_page} className={`w-100 px-0 ${styles.main_content}`}>
            <div className={styles.login_page} >
                <div className={styles.center_container}>
                    <img src={MainLogo} className={styles.logo} id="logo" />
                    <h1 className={`font-18 ${styles.title}`} id="login_info">{t('login_page.title')}</h1>
                    <div className={styles.form_container}>
                        <h5 className={`font-16 ${styles.textbox_label}`} id="login_username">{t('login_page.username.title')}</h5>
                        <GeneralTextbox
                            placeholder={t('login_page.username.placeholder')}
                            autoFocus={true}
                            className={`font-18 RobotoRegular username-box ${styles.text_box} ${(user_id != undefined && user_id.length > 0) ? styles.show_user : ''}  ${usernameRequire ? styles.border_danger : ''}`}
                            onChange={onUserNameChange}
                            id="userId"
                            icon={usernameRequire ? error_icon : valid_con}
                        // onKeyPress={handleKeyDown}
                        />
                        {/* {usernameRequire && message === '' && <ErrorMessage message={t('login_page.username.require')} id="login_username_error" />} */}

                        <h5 className={`font-16 ${styles.textbox_label}`} id="login_password">{t('login_page.password.title')}</h5>
                        <GeneralTextbox
                            inputtype={'password'}
                            placeholder={t('login_page.password.placeholder')}
                            className={`font-18 RobotoRegular password-box ${styles.text_box}  ${(password != undefined && password.length > 0) ? styles.show_pass : ''} ${passwordRequire ? styles.border_danger : ''} `}
                            onChange={onPasswordChange}
                            id="password"
                            icon={passwordRequire ? error_icon : valid_con}
                        // onKeyPress={handleKeyDown} 
                        />
                        {/* {passwordRequire && message === '' && <ErrorMessage message={t('login_page.password.require')} id="login_password_error" />} */}
                        <h5 className={`font-16 ${styles.forgot_password}`}>
                            {/* <ForgotPasswordPage text={t('login_page.forgot password')} setMessage={setMessage} userId={user_id} /> */}
                        </h5>
                        <GeneralButton
                            title={t('general.button.sign_in')}
                            className={`font-16 text-white font-weight-bold ${styles.btn_login}`}
                            onClick={login}
                            id="login" />
                        <div>test-user-id</div>
                        <div>G110digitcode,G6empcode,G5ASTrainer,I3AflacMember</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const stateToProps = state => {
    return {
        user_id: state.vAgent.user_id,
        username: state.vAgent.username,
        employee_id: state.vAgent.employee_id,
        role: state.vAgent.role,
        agent_company: state.vAgent.agent_company,
        recent_path: state.vAgent.recent_path,
        access_token: state.vAgent.access_token
    }
}

const dispatchToProps = dispatch => {
    return {
        setSelectedUserId: (user_id) => {
            dispatch(setSelectedUserId(user_id));
        },
        setSelectedUserName: (username) => {
            dispatch(setSelectedUserName(username));
        },
        setSelectedEmployeeId: (employee_id) => {
            dispatch(setSelectedEmployeeId(employee_id));
        },
        setSelectedRole: (role) => {
            dispatch(setSelectedRole(role));
        },
        setSelectedAgentCompany: (agent_company) => {
            dispatch(setSelectedAgentCompany(agent_company));
        },
        accessToken: (access_token) => {
            dispatch(accessToken(access_token));
        },
        setRequestHeaderUserId: (request_header_user_id) => {
            dispatch(setRequestHeaderUserId(request_header_user_id));
        },
        setRequestHeaderGroupId: (request_header_group_id) => {
            dispatch(setRequestHeaderGroupId(request_header_group_id));
        },
        setSyncStatus: (syncStatus) => {
            dispatch(setSyncStatus(syncStatus));
        }

    }
}
export default connect(stateToProps, dispatchToProps)(LoginPage);