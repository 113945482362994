const staticRoleData = [
    {id:1,name:"Aflac社員"},
    {id:2,name:"代理店マネージャー"},
    {id:3,name:"代理店募集人"},
    {id:4,name:"システムオーナー"}
]
const filterOperation = [
    {id : 1, name:"選択された ユーザー情報 の削除"},
    {id : 2, name:"選択したアイテムをエクスポート"},
]
const fileExtensionData = [
    {id : 1, name:"csv"},
    {id : 2, name:"xlsx"},
]
const userInfoData = [
    {id : 1,empId : "123456",userName : "test1",roleId : 1,email : "test1@gmail.com",companyCode : "test",branchCode : "test"},
    {id : 2,empId : "223456",userName : "test2",roleId : 2,email : "test2@gmail.com",companyCode : "test",branchCode : "test"},
    {id : 3,empId : "323456",userName : "test3",roleId : 3,email : "test3@gmail.com",companyCode : "test",branchCode : "test"},
    {id : 4,empId : "423456",userName : "test4",roleId : 2,email : "test4@gmail.com",companyCode : "test",branchCode : "test"},  
]

const companyInfoData = [
    {id : 1,companyCode : "901191", companyName : "AgentCompany 1",branchCode : "123",branchName : "Branch 1"},
    {id : 2,companyCode : "2610441",companyName : "AgentCompany 2",branchCode : "001",branchName : "Branch 2"},
    {id : 3,companyCode : "503341", companyName : "AgentCompany 3",branchCode : "003",branchName : "Branch 3"},
    {id : 4,companyCode : "730412", companyName : "AgentCompany 4",branchCode : "006",branchName : "Branch 4"},
    {id : 5,companyCode : "500174", companyName : "AgentCompany 5",branchCode : "011",branchName : "Branch 5"},
    {id : 6,companyCode : "211341", companyName : "AgentCompany 6",branchCode : "003",branchName : "Branch 6"}
]
const userColumnsData = [
    { header: "募集人コード", key: "empId" },
    { header: "募集人名", key: "userName" },
    { header: "権限", key: "roleName" },
    { header: "UserPrincipalName", key: "email" },
]
const agencyColumnsData = [
    { header: "代理店コード", key: "companyCode" },
    { header: "代理店名", key: "companyName" },
    { header: "出先コード", key: "branchCode" },
    { header: "出先名", key: "branchName" },
]

export {
    staticRoleData,
    filterOperation,
    userInfoData,
    companyInfoData,
    fileExtensionData,
    userColumnsData,
    agencyColumnsData
} 