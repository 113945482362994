import React, { useState, useEffect,useRef } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Sidebar from '../../../components/Sidebar';
import LogoutButton from '../../../components/Buttons/LogoutButton';
import {GeneralDropdown} from '../../../components/Dropdowns/GeneralDropdown';
import Breadcrumbs from '../../../components/Breadcrumbs';
import {fileExtensionData,userInfoData,staticRoleData} from '../../../configs/staticData'
import { isApigwEnv } from '../../../utils/General';
import { isAflacMember, isTrainerManagerOrTrainee } from '../../../configs/constants';
import MaintenanceScreenExport from '../../../components/Exports/MaintenanceScreenExport';
const UserExport = ({isOpen,access_token}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [reset, makeAllReset] = useState(false);
    const [data, setData] = useState([]);
    const [selectedFileExtension, setSelectedFileExtension] = useState('')
    const columnsData = [
        { header: "募集人コード", key: "empId" },
        { header: "募集人名", key: "userName" },
        { header: "ロール", key: "role" },
        { header: "UserPrincipalName", key: "email" },
        ];

    useEffect(() => {
        window.scrollTo(0, 0)
        if (!isApigwEnv() && (access_token === '' || access_token === undefined)) {
            history.push("/Logout");
        }
        if (isTrainerManagerOrTrainee() || isAflacMember()) {
            makeAllReset(!reset)
            return
        }
    }, []);
    useEffect(() => {
        let formatData = [];
        userInfoData.map((item)=>{
            formatData.push(
                {
                empId: item.empId,
                userName: item.userName,
                role: staticRoleData.filter(roleItem =>roleItem.id === item.roleId)[0].name,
                email : item.email
                }
            );
        })
        setData(formatData);
    }, [userInfoData]);
    
    const handleFileExtension = (event)=>{
        setSelectedFileExtension({id : event.value,name : event.textContent});
    }
    return(
        <>
            <Sidebar />
            <div id="main-content" className={`${isOpen ? 'content-large' : 'content-small'}`}>
                <div className="main-content-inr maintenanceScreen" id="maintenanceScreen">
                    <Row>
                        <Col lg="12" className="cmn-logout-btn-sec">
                            <h3 className="text-left d-flex justify-content-between">
                            エクスポート
                            </h3>
                            <LogoutButton title={t('general.button.logout')} className={'font-14'} id="logout"/>
                        </Col>
                    </Row>                   
                    <Row>
                        <Col xs="12" className="mb-3"><Breadcrumbs homeLink="MaintenanceScreen"/></Col>                      
                        <Col xl="8">
                            <div className="cmn-bg-box">      
                                <div className="cmn-bg-box-inr mb-4">
                                    <div className="form-group row">
                                        <label htmlFor="recruiterCodeLabel" className="col-lg-2 col-form-label">フォーマット:</label>
                                        <div className="col-lg-10">
                                            <GeneralDropdown
                                                isStatic={true}
                                                staticData={fileExtensionData}
                                                onSelect={(el) => handleFileExtension(el)}
                                                selectedData={selectedFileExtension && selectedFileExtension.name}
                                                placeholder="選択してください"
                                                dropdown_id="file_extension_dropdown"/>
                                        </div>
                                    </div>
                                    <Row>
                                        <Col className='text-right'>
                                            <MaintenanceScreenExport selectedFileExtension={selectedFileExtension} columnsData={columnsData} data={data} fileName="User Detail"/>
                                        </Col>
                                    </Row>
                                </div>                                                                         
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}
const stateToProps = state => {
    return {
        isOpen: state.vAgent.isOpen,
        access_token: state.vAgent.access_token
    }
}
export default connect(stateToProps,null)(UserExport)