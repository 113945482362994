import { Bar } from '@nivo/bar'
import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import styles from './styles.module.css';
import store from '../../../store'

const indexBy = "date";
const TotalLabels = ({ bars, yScale }) => {   // returns a list of total value labels for stacked bars
  const labelMargin = 20;
  const [label, setLabel] = useState()                    // space between top of stacked bars and total label

  useEffect(() => {
    setLabel(store.getState().vAgent.label_at_analysis);
  }, [store.getState().vAgent.label_at_analysis])

  return bars.map(({ data: { data, indexValue }, x, width }, i) => {
    const total = Object.keys(data)          // sum of all the bar values in a stacked bar
      .filter(key => key !== indexBy)        //f ilter out whatever your indexBy value is
      .reduce((a, key) => a + data[key], 0);

    return (
      <g
        transform={`translate(${x}, ${yScale(total) - labelMargin})`}
        key={`${indexValue}-${i}`}
      >
        <text
          className="MeiryoRegular"        // add any class to the label here
          x={width / 2}
          y={labelMargin / 2}
          textAnchor="middle"
          alignmentBaseline="central"
          style={{                         // add any style to the label here
            fill: "rgb(51, 51, 51)", fontSize: "8"
          }}
        >
          {total.toLocaleString()}{label !== undefined && label}
        </text>
      </g>
    );
  });
};

const BarChart = (props) => {
  const type = props.type;
  let data = props.data
  let located = useLocation();
  const defaultMaxValue = located.pathname === "/RoleplayCountAndDuration" ? 9000 : 800;
  let rates = [], values = located.pathname === "/RoleplayCountAndDuration" ? [0, 1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000] : [0, 100, 200, 300, 400, 500, 600, 700, 800], ticks = [], max = 0;
  const [tickValue, setTickValue] = useState()
  // const defaultMaxValue = located.pathname === "/RoleplayCountAndDuration" ? 9000 : 1800;
  const interval = located.pathname === "/RoleplayCountAndDuration" ? 1000 : 100;

  function sum(obj) {
    var amount = 0;
    for (var el in obj) {
      if (obj.hasOwnProperty(el) && el !== 'date') {
        amount += parseFloat(obj[el]);
      }
    }
    return amount;
  }

  for (let i = 0; i < data.length; i++) {
    rates[i] = sum(data[i])
  }

  max = rates.length > 0 && Math.max(...rates)
  if (max > 0 && max <= defaultMaxValue) {
    ticks = values
  } else {
    while (values[values.length - 1] < max) {
      values.push(values[values.length - 1] + interval)
      ticks = [...values]
    }
  }

  useEffect(() => {
    ticks.length > 0 && !tickValue && setTickValue(ticks)
  }, [ticks])

  const formatLegendValue = (value) => {
    if (value.length > 8 && value.includes("月")) {
      let first = value.slice(0, value.indexOf("月") + 1)
      let second = value.slice(value.indexOf("月") + 1)
      let finalStr = [];
      finalStr.push(
        <tspan x={3} dy={0} key={1}>{first}</tspan>
      )
      finalStr.push(
        <tspan x={0} dy={15} key={2}>{second}</tspan>
      )
      return finalStr;
    } else {
      return value;
    }
  }

  const colors = props.colors;
  const labels = props.keys;
  const themes = props.data;

  const CustomTooltip = ({id, value,color, data}) => {
    return (
      <div style={{width : "250px",padding : "5px 12px",backgroundColor :"white",boxShadow: "7px 5px 8px 1px rgba(0,0,0,0.10)",zIndex : 1,position : "absolute",right : -50,bottom : 0,textAlign : "center"}}>
            <span style={{padding : '0px 10px',backgroundColor : color,marginRight : '5px'}}></span>
            <strong>{id}-{data.date}:{value}</strong>
        </div>
        
    )
}
  return (
    <div className={` ${styles.scrollbar} styled_scrollbar`} id='bar_chart' >
      <div>
        <Bar
          {...props}
          margin={{ top: 50, right: 30, bottom: 50, left: 80 }}
          layers={["grid", "axes", "bars", TotalLabels, "markers", "legends"]}
          indexBy="date"
          // padding={0.6}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          minValue={0}
          maxValue={tickValue && tickValue[tickValue.length - 1]}
          axisTop={null}
          axisRight={null}
          enableLabel={false}
          axisBottom={{
            tickSize: 5,
            tickPadding: 0,
            tickRotation: 0,
            legendPosition: 'middle',
            legendOffset: 32,
            format: (v) => {
              return themes.length > 8 ? (
                formatLegendValue(v)
              ) : (v);
            }
          }}
          axisLeft={{
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 0,
            legendPosition: 'middle',
            legendOffset: -40,
            tickValues: tickValue && tickValue[tickValue.length - 1] <= defaultMaxValue ? tickValue : 10,
            format: (val) => val.toLocaleString() + type

          }}
          theme={{
            axis: {
              ticks: {
                line: {
                  stroke: "rgba(0, 165, 217, 0.2)"
                },
                text: {
                  fontSize: 8,
                  fontFamily: 'MeiryoRegular',
                  fontWeight: 'bolder'
                },
                minWidth: 50
              }
            },
            grid: {
              line: {
                stroke: "rgba(0, 165, 217, 0.2)",
              }
            }
          }}
          labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          // legends={[
          //   {
          //     dataFrom: 'keys',
          //     anchor: 'bottom',
          //     direction: 'row',
          //     justify: false,
          //     translateX: 0,
          //     translateY: 60,
          //     itemWidth: 120,
          //     itemHeight: 20,
          //     itemsSpacing: 20,
          //     symbolSize: 20,
          //     itemDirection: 'left-to-right',
          //     itemOpacity: 0.85,
          //     legendPosition: 'center',
          //     effects: [
          //       {
          //         on: 'hover',
          //         style: {
          //           itemOpacity: 1
          //         }
          //       }
          //     ]
          //   }
          // ]}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          tooltip={CustomTooltip}
        />
        <div class={`${styles.list} d-flex justify-content-center flex-wrap`}>
          {labels && colors && colors.map((color, i) => {
            return (<div class="d-flex mx-3"><div className={`${styles.square} mr-1`} style={{ "background": color }}></div>{labels[i]}</div>)
          }
          )}
        </div>
      </div>
    </div>
  )

}

export default BarChart;
