const RUN_ENV = process.env.REACT_APP_RUN_ENV;
let baseDirectory = ''
let sidebarBgClass = ''
let environment = ''
let va2PrivateDNS = ''
let mstDBUrl = ''
let redirectMaintenanceScreenURL = ''
switch (process.env.NODE_ENV) {
    case 'production':
        if (RUN_ENV === 'prod') {
            baseDirectory = '/apigw/va2roleplay'
            sidebarBgClass = 'sidebar-bg'
            environment = 'production'
            va2PrivateDNS = 'http://va2-roleplay.aflac.platformerfuji.internal'
            mstDBUrl = 'https://aflac.platformerfuji.com'
            redirectMaintenanceScreenURL = 'https://st-aflac.platformerfuji.com/apigw/va2roleplay/backoffice/admin/'
        }
        if (RUN_ENV === 'stage') {
            baseDirectory = '/apigw/va2roleplay'
            sidebarBgClass = 'sidebar-bg-green'
            environment = 'staging'
            va2PrivateDNS = 'http://va2-roleplay.staging.aflac.platformerfuji.internal'
            mstDBUrl = "https://st-aflac.platformerfuji.com"
            redirectMaintenanceScreenURL = 'https://st-aflac.platformerfuji.com/apigw/va2roleplay/backoffice/admin/'
        }
        if (RUN_ENV === 'dev') {
            baseDirectory = ''
            sidebarBgClass = 'sidebar-bg'
            environment = 'development'
            va2PrivateDNS = ''
            mstDBUrl = 'https://va2-roleplay.japaneast.cloudapp.azure.com'
            redirectMaintenanceScreenURL = 'https://va2-roleplay.japaneast.cloudapp.azure.com/apigw/va2roleplay/backoffice/admin/'
        }
        break
    case 'development':
        baseDirectory = ''
        sidebarBgClass = 'sidebar-bg'
        environment = 'development'
        va2PrivateDNS = ''
        mstDBUrl = 'https://va2-roleplay.japaneast.cloudapp.azure.com'
        redirectMaintenanceScreenURL = 'https://va2-roleplay.japaneast.cloudapp.azure.com/apigw/va2roleplay/backoffice/admin/'
        // mstDBUrl = 'http://localhost:3002'
        break
    default:
        break
}


export {
    environment,
    baseDirectory,
    sidebarBgClass,
    va2PrivateDNS,
    mstDBUrl,
    redirectMaintenanceScreenURL
};
