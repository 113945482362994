import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import AIScoringResult from '.././pages/AIScoringResultPage';
import AIScoringDetail from '../pages/AIScoringResultPage/Detail'
import RecruiterSummaryPage from '../pages/RoleEvaluationPage/RecruiterSummaryPage';
import RecruiterSummaryDetailPage from '../pages/RoleEvaluationPage/RecruiterSummaryPage/RecruiterSummaryDetailPage';
import StartNewRolePlayPage from '.././pages/StartNewRolePlayPage';
import MicrosoftTeamTraningPage from '.././pages/MicrosoftTeamTraningPage';
import LoginPage from '.././pages/LoginPage';
import FeedbackEdit from '../pages/AIScoringResultPage/Feedback';
import CustomerAttributesPage from '../pages/CustomerAttributesPage';
import PageNotFound from '../pages/PageNotFound';
import TrainingPlan from '../pages/TrainingPlanPage';
import EditLearningTheme from '../pages/EditLearningThemePage';
import { baseDirectory } from '../configs/environments'
import EditCheckCriteriaPage from '../pages/EditCheckCriteriaPage';
import KeywordSelection from '../pages/KeywordSelection';
import EditKeywords from '../pages/EditKeywords';
import EditExamScoringResult from '../pages/EditExamScoringResult';
import StartExam from '../pages/StartExamPage'; 
import ExamScoringDetail from '../pages/EditExamScoringResult/Detail';
import SelectionOfRecruitersForTrainingPage from '../pages/SelectionOfRecruitersForTrainingPage';
import FavoriteList from '../pages/FavoriteList';
import SynonymByKeyword from '../pages/SynonymByKeywordPage';
import UserAccountPage from '../pages/UserAccountPage';
import Digitalization from '../pages/DigitalizationPage';
import LogoutPage from '../pages/LogoutPage';
import KeywordAccuracyPage from '../pages/KeywordAccuracyPage';
import RoleplayCountAndDurationPage from '../pages/RoleplayCountAndDurationPage';
import TimeAndCountLearningThemePage from '../pages/TimeAndCountLearningThemePage';
import MaintenanceScreenPage from '../pages/MaintenanceScreenPage'
import userInformationAdd from '../pages/MaintenanceScreenPage/UserInformation/UserInformationAdd'
import UserInformationEditList from '../pages/MaintenanceScreenPage/UserInformation/UserInformationEditList'
import UserImport from '../pages/MaintenanceScreenPage/UserInformation/UserImport'
import UserExport from '../pages/MaintenanceScreenPage/UserInformation/UserExport'
import AgencyImport from '../pages/MaintenanceScreenPage/AgencyInformation/AgencyImport'
import AgencyExport from '../pages/MaintenanceScreenPage/AgencyInformation/AgencyExport'
import AgencyInformationAdd from '../pages/MaintenanceScreenPage/AgencyInformation/AgencyInformationCreate'
import AgencyInformationEdit from '../pages/MaintenanceScreenPage/AgencyInformation/AgencyInformationEditList'
import DisplayOperationLog from '../pages/MaintenanceScreenPage/OperationLog/DisplayOperationLog'
import PreviewBeforeDelete from '../pages/MaintenanceScreenPage/UserInformation/PreviewBeforeDelete'
import AgencyPreviewBeforeDelete from '../pages/MaintenanceScreenPage/AgencyInformation/PreviewBeforeDelete'
import { isApigwEnv } from '../utils/General'

const Routes = () => {
  return (
    <Router basename={baseDirectory}>
      <Switch>
        <Route exact path="/ai_scoring_result" component={AIScoringResult} />
        <Route exact path="/ai_scoring_result/:id" component={AIScoringDetail} />
        <Route exact path="/feedback_edit" component={FeedbackEdit} />
        <Route exact path="/RecruiterSummary" component={RecruiterSummaryPage} />
        <Route exact path="/StartOfNewRolePlay" component={StartNewRolePlayPage} />
        <Route exact path="/RecruiterSummaryDetail" component={RecruiterSummaryDetailPage} />
        <Route exact path="/MicrosoftTeamTraning" component={MicrosoftTeamTraningPage} />
        <Route exact path="/" component={!isApigwEnv() ? LoginPage : RecruiterSummaryPage} />
        <Route exact path="/ConfirmCustomerAttributes" component={CustomerAttributesPage} />
        <Route exact path="/EditCheckCriteria" component={EditCheckCriteriaPage} />
        <Route exact path="/TrainingPlan" component={TrainingPlan} />
        <Route exact path="/EditLearningTheme" component={EditLearningTheme} />
        <Route exact path="/KeywordSelection" component={KeywordSelection} />
        <Route exact path="/EditKeywords" component={EditKeywords} />
        <Route exact path="/EditExamScoringResult" component={EditExamScoringResult} />
        <Route exact path="/exam_scoring_result/:id" component={ExamScoringDetail} />
        <Route exact path="/StartExam" component={StartExam} />
        <Route exact path="/SelectionOfRecruiter" component={SelectionOfRecruitersForTrainingPage} />
        <Route exact path="/FavoriteList" component={FavoriteList} />
        <Route exact path="/SynonymByKeyword" component={SynonymByKeyword} />
        <Route exact path="/UserAccount" component={UserAccountPage} />
        <Route exact path="/Digitalization" component={Digitalization} />
        <Route exact path="/Logout" component={LogoutPage} />
        <Route exact path="/KeywordAccuracy" component={KeywordAccuracyPage} />
        <Route exact path="/RoleplayCountAndDuration" component={RoleplayCountAndDurationPage} />
        <Route exact path="/TimeAndCountLearningTheme" component={TimeAndCountLearningThemePage} />
        <Route exact path="/MaintenanceScreen" component={MaintenanceScreenPage} />
        <Route exact path="/userInformation/add_user_info" component={userInformationAdd} />
        <Route exact path="/userInformation/edit_user_info" component={userInformationAdd} />
        <Route exact path="/userInformation" component={UserInformationEditList} />
        <Route exact path="/userInformation/import_user_info" component={UserImport} />
        <Route exact path="/userInformation/export_user_info" component={UserExport} />
        <Route exact path="/agencyInformation/import_agency_info" component={AgencyImport} />
        <Route exact path="/agencyInformation/export_agency_info" component={AgencyExport} />
        <Route exact path="/agencyInformation/add_agency_info" component={AgencyInformationAdd} />
        <Route exact path="/agencyInformation/edit_agency_info" component={AgencyInformationAdd} />
        <Route exact path="/agencyInformation" component={AgencyInformationEdit} />
        <Route exact path="/operationLog" component={DisplayOperationLog}/>
        <Route exact path="/userInformation/userPreviewdelete" component={PreviewBeforeDelete}/>
        <Route exact path="/userInformation/edit_user_info/editpreviewdelete" component={PreviewBeforeDelete}/>
        <Route exact path="/agencyInformation/agencyPreviewdelete" component={AgencyPreviewBeforeDelete}/>
        <Route exact path="/agencyInformation/edit_agency_info/agencyeditpreviewdelete" component={AgencyPreviewBeforeDelete}/>
        <Route path="*" component={PageNotFound} />
      </Switch>
    </Router>

  );
}


export default Routes;