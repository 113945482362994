const initialState = {
    is_logout:undefined
}

const logout = (
    state = initialState,
    action
) =>{
    switch (action.type) {
        case "IS_LOGOUT":
            return {...state, is_logout:action.is_logout};
        default:
            return state
    }
}
export default logout;
