import React, { useState, useEffect,useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { Row, Col } from 'reactstrap';
import Sidebar from '../../components/Sidebar';
import { setRecentPath } from '../../store/actions';
import LogoutButton from '../../components/Buttons/LogoutButton';
import BackButton from '../../components/Buttons/BackButton';
import GeneralButton from '../../components/Buttons/GeneralButton';
import {GeneralDropdown} from '../../components/Dropdowns/GeneralDropdown';
import {MultiselectDropdown} from '../../components/Dropdowns/MultiSelectDropdown';
import {TimePeriodDropdown} from '../../components/Dropdowns/TimePeriodDropdown';
import Toggle from '../../components/Toggle'
import BarChartHorizontal from '../../components/Charts/BarChartHorizontal';
import {MultiTraineeSelectDropdown} from './MultiTraineeSelectDropdown';
import CSVExport from './CSVExport';
import TimeCSVExport from './TimeCSVExport';
import filterData from '../../staticData/TimePeriod.json';
import {isTrainee,isTrainerManager,isAflacMemberOrBranchManager, isSystemOwner} from '../../configs/constants'
import {COMPANY_CODES_URL, TRAINER_LIST_BY_MULTI_AGENT_COMPANIES,LEARNING_THTMES_BY_MULTI_AGENT_COMPANIES} from '../../configs/urlConstants';
import {
    getLearningThemeOrTraineeForMultiAgentCode, 
    getUserList,
    getLearningThemeCountAndTimeByAgentCode,
    getLearningThemeTimeCountDurationByTrainee,
    getGroupedLearningThemeLists
} from '../../api/api';
import styles from './styles.module.css'
import { checkAverageLearningTheme,getLearningThemeBasedOnASCode,getEmptyAsData,calculateAverageAsCode,calculateAverageTime } from '../../utils/mapState';
import { groupBy,isApigwEnv, groupByKey, sumData, sumDataByObject,millisToHours,getDurationTime, getLast13MonthsWithYear, getCountOverall } from '../../utils/General';
import TAC from '../../staticData/TimeAndCountByTrainee.json'
const TimeAndCountLearningTheme = ({ access_token, isOpen, location,setRecentPath,agent_company,syncStatus }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [reset, makeAllReset] = useState(false);
    const [selectedLearningTheme,setSelectedLearningTheme] =useState({});
    const [filterTheme,setfilterTheme] =useState([]);
    const [learningTheme,setLearningTheme] =useState([]);
    const [allLearningTheme,setAllLearningTheme] =useState([]);
    const [timePeriodData, setTimePeriodData] = useState(filterData.byMonth);
    let date = getLast13MonthsWithYear();
    const [to, setTo] = useState({ "year": date[0].year, "month": date[0].month });
    const [from, setFrom] = useState({ "year": date[date.length - 1].year, "month": date[date.length - 1].month });
    const [activeSlider, setActiveSlider] = useState();
    const [activeDropdown, setActiveDropdown] = useState();
    const [filterSelectedASCode, setFilterSelectedASCode] = useState([]);
    const [asCodeData, setAsCodeData] = useState([]);
    const [multiselectedASCode, setMultiSelectedASCode] = useState([]);
    const [selectedSingleAsCode, setSelectedSingleAsCode] = useState([]);
    const [trainees, setTrainees] = useState([])
    const [multiselectedTrainees, setMultiSelectedTrainees] = useState([]);
    const [traineeActiveDropdown, settraineeActiveDropdown] = useState();
    const [props, setProps] = useState();
    const [numberProps, setSingleProps] = useState();
    const ref = useRef(null);
    const timeRef = useRef(null);
    const excelNumberRef = useRef()
    const excelTimeRef= useRef()
    const [countData, setCountData] = useState([])
    const [timeData, setTimeData] = useState([])
    const [countWidth, setCountWidth] = useState()
    const [countTickValue, setcountTickValue] = useState([])
    const [timeWidth, setTimeWidth] = useState()
    const [timeTickValue, setTimeTickValue] = useState([])

    const [byAgentCodeCountData, setbyAgentCodeCountData] = useState([])
    const [byAgentCodeTimeData, setbyAgentCodeTimeData] = useState([])

    const [traineeCountData, settraineeCountData] = useState([])
    const [traineeTimeData, settraineeTimeData] = useState([])
    
    
    let lastId = 0;
    const autoId = (prefix = 'digit-') => {
        lastId++;
        return `${prefix}${lastId}`;
    }
    const toggle_category = [
        {
            id: 1,
            name: "AS分析",
        },
        {
            id: 2,
            name: "AS比較",
        }
    ]
    useEffect(() => {
        window.scrollTo(0, 0)
        if (!isApigwEnv() && (access_token === '' || access_token === undefined)) {
            history.push("/Logout");
        }
        if (isTrainee()) {
            makeAllReset(!reset)
            return
        }
        setRecentPath(location.pathname);

        getUserList(COMPANY_CODES_URL).then(res => {
            setAsCodeData(res.data.map(item => { return { id: item.id, name: item.name, checked: true, newlyAdded: false, found: true } }).sort((a, b) => parseInt(a.id) - parseInt(b.id)))
            setMultiSelectedASCode(res.data.map(item => { return { id: item.id, name: item.name, checked: true, newlyAdded: false, found: true } }).sort((a, b) => parseInt(a.id) - parseInt(b.id)));
            if(isTrainerManager()){
                setFilterSelectedASCode([{id:agent_company.code,name :agent_company.name}])
            }else{
                setFilterSelectedASCode(res.data.map(item => { return { id: item.id, name: item.name, checked: true, newlyAdded: false, found: true } }).sort((a, b) => parseInt(a.id) - parseInt(b.id)));
            }
            
            
        })
        getGroupedLearningThemeLists().then(res => {
            setAllLearningTheme(res.data);
            // setAllLearningTheme(groupedLearningTheme);
        })
    }, []);
    useEffect(() => {
        if (filterSelectedASCode.length > 0 && allLearningTheme.length>0) {
            var filterByASCode  = allLearningTheme.filter(function(array_el){
                return filterSelectedASCode.filter(function(anotherOne_el){
                    return anotherOne_el.id == array_el.agentCode;
                }).length != 0
                });
            let result = getLearningThemeBasedOnASCode(filterByASCode);
            let data = [];
            result.length > 0 && result.map(item => {
                data.push({id : item.themeId,name : item.themeName})
            })
            let latestResult = checkAverageLearningTheme(data,filterByASCode);
            setLearningTheme(latestResult);
            setSelectedLearningTheme(latestResult[0]);
            setfilterTheme([latestResult[0]]);
        }
    }, [filterSelectedASCode,allLearningTheme])
    useEffect(() => {
        if(isTrainerManager()){
            setFilterSelectedASCode([{id:agent_company.code,name :agent_company.name}])
        }else{
            if (activeSlider === 1) {
                if(selectedSingleAsCode.length>0 && selectedSingleAsCode[0].id === 0 && selectedSingleAsCode.length>0 && selectedSingleAsCode[0].name === '全AS') {
                    selectedSingleAsCode && setFilterSelectedASCode(asCodeData)
                    setMultiSelectedTrainees([])
                }
               else {
                    if (selectedSingleAsCode.length === 0) {
                        setFilterSelectedASCode(asCodeData)
                    } else {
                        selectedSingleAsCode && setFilterSelectedASCode(selectedSingleAsCode)
                        setMultiSelectedTrainees([])
                    }
               
               }
            } else {
                setFilterSelectedASCode(multiselectedASCode);
            }
        }
    }, [activeSlider,selectedSingleAsCode,multiselectedASCode])
    useEffect(() => {
        // let parameters = new URLSearchParams();
        if (filterSelectedASCode.length > 0  && activeSlider === 1 || activeSlider === undefined) {
            let parameters = {agentCodeList: filterSelectedASCode.map(function (a) { return a.id; })}
            filterSelectedASCode.length > 0 &&
            getLearningThemeOrTraineeForMultiAgentCode(TRAINER_LIST_BY_MULTI_AGENT_COMPANIES,parameters).then(res =>{
                setTrainees(res.data.map(item => { return { id: item.id, name: item.name,empId :item.empId, checked: false, newlyAdded: true, found: true } }))
                setMultiSelectedTrainees([]);
            })
        }else{
            setMultiSelectedTrainees([]);
        }
    }, [filterSelectedASCode,activeSlider])
    //get api section
    useEffect(() => {
        getTraineeData();
    }, [multiselectedTrainees])
    useEffect(() => {
        getData();
        getTraineeData();
    }, [from,to])
    useEffect(() => {
        getData();
        getTraineeData();
    }, [filterTheme])
    const getTraineeData = () => {
        const filterLearningTheme = filterTheme.reduce((a, o) => ( a.push(o.id), a), [])
        const filteredASCode = filterSelectedASCode.reduce((a, o) => ( a.push(o.id), a), [])
        const filterTrainees = multiselectedTrainees.reduce((a, o) => ( a.push(o.empId), a), [])
        const fromTime = getDurationTime(1, from, 'from')
        const toTime = getDurationTime(1, to, 'to')
        if(!filterTrainees.length > 0){
            settraineeCountData([]);
            settraineeTimeData([]);
        }else{
            let params = {
                agentCodesList : filteredASCode,
                learningTheme : filterLearningTheme,
                from : fromTime,
                to : toTime,
                empIdList : filterTrainees
            };
            getLearningThemeTimeCountDurationByTrainee(params).then(durres=>{
                let formatData = [],traineeGroupedData;
                 //get trainee api data
                 traineeGroupedData = groupBy(durres.data.countByTraineeId,"UD_EMP_ID");
                //  traineeGroupedData = groupBy(TAC.countByTraineeId,"UD_EMP_ID");
                 filterTrainees.length > 0 && traineeGroupedData.map(item => {
                     let paramObj = {};
                     let groupedByRole = groupByKey(item,"role")
                     Object.assign(paramObj,{'date':item[0].traineeName+"-"+item[0].UD_EMP_ID});
                     Object.entries(groupedByRole).forEach(([key,value]) => {
                         Object.assign(paramObj,{'AI':0});
                         if (key === "1") {
                            let afisExist = paramObj.hasOwnProperty("AF社員");
                            if (afisExist) {
                                Object.assign(paramObj,{'AF社員':paramObj.AF社員+sumData(value,"totCount")});
                            } else {
                                Object.assign(paramObj,{'AF社員':sumData(value,"totCount")});
                            }
                         }else if(key=== "2"){
                             Object.assign(paramObj,{'AS管理者':sumData(value,"totCount")});
                         }
                     })
                     formatData.push(paramObj)
                 })
                 let finalizeCountData = getEmptyAsData(formatData);
                 settraineeCountData(finalizeCountData)
                //  let removeUnselectedTrainees = getRemoveUnselectedTraines(countData,trainees);
                // setCountData([...removeUnselectedTrainees,...finalizeCountData]);
                 let timeformatData = [],timetraineeGroupedData;
                 timetraineeGroupedData = groupBy(durres.data.timeByTraineeId,"UD_EMP_ID");
                //  timetraineeGroupedData = groupBy(TAC.timeByTraineeId,"UD_EMP_ID");
                 filterTrainees.length > 0 && timetraineeGroupedData.map(item => {
                     let paramObj = {};
                     let groupedByRole = groupByKey(item,"role")
                     Object.assign(paramObj,{'date':item[0].traineeName+"-"+item[0].UD_EMP_ID});
                     Object.entries(groupedByRole).forEach(([key,value]) => {
                         Object.assign(paramObj,{'AI':0});
                         if (key === "1") {
                            let afisExist = paramObj.hasOwnProperty("AF社員");
                            if (afisExist) {
                                Object.assign(paramObj,{'AF社員':paramObj.AF社員 + millisToHours(Math.round(sumData(value,"totRoleplayTime")))});
                            } else {
                                Object.assign(paramObj,{'AF社員':millisToHours(Math.round(sumData(value,"totRoleplayTime")))});
                            }
                         }else if(key=== "2"){
                             Object.assign(paramObj,{'AS管理者':millisToHours(Math.round(sumData(value,"totRoleplayTime")))});
                         }
                     })
                     timeformatData.push(paramObj)
                 })
                 let finalizeTimeData = getEmptyAsData(timeformatData);
                 settraineeTimeData(finalizeTimeData);
            })
        }
        
    }
    const getData = () => {
        const filterLearningTheme = filterTheme.reduce((a, o) => ( a.push(o.id), a), [])
        const filteredASCode = filterSelectedASCode.reduce((a, o) => ( a.push(o.id), a), [])
        const fromTime = getDurationTime(1, from, 'from')
        const toTime = getDurationTime(1, to, 'to')
        let params = {
            agentCodesList : filteredASCode,
            learningTheme : filterLearningTheme,
            from : fromTime,
            to : toTime
        };
        if(params.from && params.to &&  params.learningTheme.length > 0 &&  params.agentCodesList.length > 0){
            getLearningThemeCountAndTimeByAgentCode(params).then(res =>{
                let formatData = [],groupedData;
                if (filterSelectedASCode.length !== asCodeData.length) {
                    //get time and count api data
                if(activeSlider === 1){
                    groupedData = groupBy(res.data.countByAgentCode,"branchCode")
                    let paramObj = {};
                    let groupedByRole = groupByKey(res.data.countByAgentCode,"role")
                    Object.assign(paramObj,{'date':"貴社平均​"});
                    Object.entries(groupedByRole).forEach(([key,value]) => {
                        Object.assign(paramObj,{'AI':0});
                        if (key === "1") {
                            let afisExist = paramObj.hasOwnProperty("AF社員");
                            if (afisExist) {
                                let average = calculateAverageAsCode(value);
                                Object.assign(paramObj,{'AF社員':paramObj.AF社員+average});
                            } else {
                                let average = calculateAverageAsCode(value);
                                Object.assign(paramObj,{'AF社員':average});
                            }
                        }else if(key=== "2"){
                            let average = calculateAverageAsCode(value);
                            Object.assign(paramObj,{'AS管理者':average});
                        }
                    })
                    formatData.push(paramObj)
                }else{
                    groupedData =  groupBy(res.data.countByAgentCode,"ascode")
                }
                groupedData.map(item => {
                    let paramObj = {};
                    let groupedByRole = groupByKey(item,"role")
                    Object.assign(paramObj,{'date':activeSlider === 1?item[0].branchName:item[0].asName});
                    Object.entries(groupedByRole).forEach(([key,value]) => {
                        Object.assign(paramObj,{'AI':0});
                        if (key === "1") {
                            let afisExist = paramObj.hasOwnProperty("AF社員");
                            if (afisExist) {
                                Object.assign(paramObj,{'AF社員':paramObj.AF社員+value[0].totCount});
                            } else {
                                Object.assign(paramObj,{'AF社員':value[0].totCount});
                            }
                        }else if(key=== "2"){
                            Object.assign(paramObj,{'AS管理者':value[0].totCount});
                        }
                    })
                    formatData.push(paramObj)
                })
                }
                let overallParams = {};
                let overallgroupedByRole = groupByKey(res.data.countByOverAll,"role")
                Object.assign(overallParams,{'date':"全AS平均"});
                Object.entries(overallgroupedByRole).forEach(([key,value]) => {
                    Object.assign(overallParams,{'AI':0});
                    if (key === "1") {
                        let afisExist = overallParams.hasOwnProperty("AF社員");
                        if (afisExist) {
                            let average = calculateAverageAsCode(value);
                            Object.assign(overallParams,{'AF社員':overallParams.AF社員+average});
                        } else {
                            let average = calculateAverageAsCode(value);
                            Object.assign(overallParams,{'AF社員':average});
                        }
                    }else if(key=== "2"){
                        let average = calculateAverageAsCode(value);
                        Object.assign(overallParams,{'AS管理者':average});
                    }
                })
                formatData.push(overallParams)
                formatData.reverse();
                let finalizeCountData = getEmptyAsData(formatData);
                // setCountData(finalizeCountData);
                setbyAgentCodeCountData(finalizeCountData);
                //for second graph
                let timeformatData = [],timegroupedData;
                //get trainee api data
                if (filterSelectedASCode.length !== asCodeData.length) {
                    //get time and count api data
                    if(activeSlider === 1){
                        timegroupedData =  groupBy(res.data.timeByAgentCode,"branchCode")
                        let paramObj = {};
                        let groupedByRole =  groupByKey(res.data.timeByAgentCode,"role")
                        Object.assign(paramObj,{'date':"貴社平均​"});
                        Object.entries(groupedByRole).forEach(([key,value]) => {
                            Object.assign(paramObj,{'AI':0});
                            if (key === "1") {
                                let afisExist = paramObj.hasOwnProperty("AF社員");
                                if (afisExist) {
                                    let averageTime = calculateAverageTime(value);
                                    Object.assign(paramObj,{'AF社員':paramObj.AF社員+averageTime});
                                } else {
                                    let averageTime = calculateAverageTime(value);
                                    Object.assign(paramObj,{'AF社員':averageTime});
                                }
                            }else if(key=== "2"){
                                let averageTime = calculateAverageTime(value);
                                Object.assign(paramObj,{'AS管理者':averageTime});
                            }
                        })
                        timeformatData.push(paramObj)
                    }else{
                        timegroupedData =  groupBy(res.data.timeByAgentCode,"ascode")
                    }
                    timegroupedData.map(item => {
                        let paramObj = {};
                        let groupedByRole = groupByKey(item,"role")
                        Object.assign(paramObj,{'date':activeSlider === 1?item[0].branchName:item[0].asName});
                        Object.entries(groupedByRole).forEach(([key,value]) => {
                            Object.assign(paramObj,{'AI':0});
                            if (key === "1") {
                                let afisExist = paramObj.hasOwnProperty("AF社員");
                                if (afisExist) {
                                    Object.assign(paramObj,{'AF社員':paramObj.AF社員+ millisToHours(value[0].totRoleplayTime)});
                                } else {
                                    Object.assign(paramObj,{'AF社員':millisToHours(value[0].totRoleplayTime)});
                                }
                            }else if(key=== "2"){
                                Object.assign(paramObj,{'AS管理者':millisToHours(value[0].totRoleplayTime)});
                            }
                        })
                        timeformatData.push(paramObj)
                    })
                }
                let timeoverallParams = {};
                let timeoverallgroupedByRole =  groupByKey(res.data.timeByOverAll,"role")
                Object.assign(timeoverallParams,{'date':"全AS平均"});
                Object.entries(timeoverallgroupedByRole).forEach(([key,value]) => {
                    Object.assign(timeoverallParams,{'AI':0});
                    if (key === "1") {
                        let afisExist = timeoverallParams.hasOwnProperty("AF社員");
                        if (afisExist) {
                            let averageTime = calculateAverageTime(value);
                            Object.assign(timeoverallParams,{'AF社員':timeoverallParams.AF社員+ averageTime});
                        } else {
                            let averageTime = calculateAverageTime(value);
                            Object.assign(timeoverallParams,{'AF社員':averageTime});
                        }
                    }else if(key=== "2"){
                        let averageTime = calculateAverageTime(value);
                        Object.assign(timeoverallParams,{'AS管理者':averageTime});
                    }
                })
                timeformatData.push(timeoverallParams)
                timeformatData.reverse();
                let finalizeTimeData = getEmptyAsData(timeformatData);
                // setTimeData(finalizeTimeData);
                setbyAgentCodeTimeData(finalizeTimeData);

            })
        }
    }
    useEffect(() => {
        setCountData([...byAgentCodeCountData,...traineeCountData])
        setTimeData([...byAgentCodeTimeData,...traineeTimeData])
    }, [byAgentCodeCountData,byAgentCodeTimeData,traineeCountData,traineeTimeData])
    useEffect(() => {
        let height = 300;
        if (countData.length > 3) {
            height = countData.length*80;
        }
        ref.current.scrollTop = ref.current.scrollHeight;
        setProps({
            data:countData,
            keys: ['AI', 'AS管理者', 'AF社員'],
            height: height,
            colors: ['#4876D2', '#E98300', '#FDBC15'],
            width: (ref.current.offsetWidth !== null && ref.current.offsetWidth >= countWidth) ? ref.current.offsetWidth : countWidth,
            type: "timeCount",
            layout: "horizontal",
            tickData:countTickValue,
            stackPadding : countData.length ===1 ?0.7:0.5,
            tickValueText : "回",
            labelMargin : 6,
            offsetWidth : countWidth,
            isInteractive : true
        })
    }, [ref.current, isOpen,countData,countWidth,countTickValue])
    useEffect(() => {
        let width = 0,tickValue = [];
        let tickValueTotal = 0;
        let maxValue = parseInt(sumDataByObject(countData))+10;
        tickValue.push(0);
        do {
            tickValue.push(tickValueTotal+5);
            tickValueTotal += 5;
            width += 50;
        } while (tickValueTotal < maxValue);
        setCountWidth(width);
        setcountTickValue(tickValue);
    }, [countData])
    useEffect(() => {
        let height = 300;
        if (timeData.length > 3) {
            height = timeData.length*80;
        }
        timeRef.current.scrollTop = timeRef.current.scrollHeight;
        setSingleProps({
            data:timeData,
            keys: ['AI', 'AS管理者', 'AF社員'],
            height: height,
            colors: ['#4876D2', '#E98300', '#FDBC15'],
            width: (timeRef.current.offsetWidth !== null && timeRef.current.offsetWidth >= timeWidth) ? timeRef.current.offsetWidth : timeWidth,
            type: "timeCount",
            layout: "horizontal",
            tickData:timeTickValue,
            stackPadding : timeData.length ===1 ?0.7:0.5,
            tickValueText : "時間",
            labelMargin : 6,
            isInteractive : true
        })
    }, [timeRef.current, isOpen,timeData,timeWidth,timeTickValue])
    useEffect(() => {
        let width = 0,tickValue = [];
        let tickValueTotal = 0;
        let maxValue = parseInt(sumDataByObject(timeData));
        maxValue >= 20? maxValue += 5: maxValue =20;
        tickValue.push(0);
        do {
            tickValue.push(tickValueTotal+5);
            tickValueTotal += 5;
            width += 50;
        } while (tickValueTotal < maxValue);
        setTimeWidth(width);
        setTimeTickValue(tickValue);
    }, [timeData])
    // useEffect(() => {
    //     let width = 0,tickValue = [];
    //     let tickValueTotal = 0;
    //     let maxValue = Math.max.apply(Math, timeNCountData.map(function(o) { return o.value; }))+3;
    //     tickValue.push(0);
    //     do {
    //         tickValue.push(tickValueTotal+1);
    //         tickValueTotal += 1;
    //         width += 200;
    //     } while (tickValueTotal < maxValue);
    //     settimeNCountWidth(width);
    //     settimeNCountTickValue(tickValue);
    // }, [timeNCountData])
    // useEffect(() => {
    //     let height = 300;
    //     if (timeNCountData.length > 3) {
    //         height = timeNCountData.length*80;
    //     }
    //     periodRef.current.scrollTop = periodRef.current.scrollHeight;
    //     setPeriodProps({
    //         data:timeNCountData,
    //         keys: ['value'],
    //         height: height,
    //         colors: ['#4876D2'],
    //         width: (ref.current.offsetWidth !== null && ref.current.offsetWidth >= timeNCountWidth) ? ref.current.offsetWidth : timeNCountWidth,
    //         offsetWidth : timeNCountWidth,
    //         type: "period",
    //         layout: "horizontal",
    //         tickData:timeNCountTickValue,
    //         stackPadding : timeNCountData.length === 1 ?0.7:0.4,
    //         tickValueText : "か月",
    //         labelMargin : 20,
    //         isInteractive : false
    //     })
    // }, [periodRef.current, isOpen,timeNCountData,timeNCountWidth,timeNCountTickValue])
    const handleTheme = (themeId,textContent) => {
        setSelectedLearningTheme({id :themeId,name :textContent})
        if (themeId === 0) {
            setfilterTheme(learningTheme.slice(0,3));
        }else{
            setfilterTheme(learningTheme.filter(item=>item.id == themeId));
        }
    }
    const handleSingleAsCode = (themeId,textContent) => {
        if (themeId === 0 && textContent === '全AS') {
            setSelectedSingleAsCode([{id :0,name :textContent}])
        }
        else{
        setSelectedSingleAsCode([{id :themeId,name :textContent}])
        }
    }

    const exportExcel = () => {
        excelNumberRef.current.link.click();
        excelTimeRef.current.link.click();
    }
    return(
        <>
             <Sidebar />
             <div id="main-content" className={`${isOpen ? 'content-large' : 'content-small'}`}>
                <div className="main-content-inr digitalization" id="digitalization">
                    <Row>
                        <Col lg="12" className="cmn-logout-btn-sec">
                            <h3 className="text-left d-flex justify-content-between">
                            学習テーマ別_実施回数・時間
                            </h3>
                            <LogoutButton title={t('general.button.logout')} className={'font-14'} id={autoId()}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <div className="cmn-bg-box ">
                                <Row>
                                    <Col xl="11" className="order-xl-0 order-1">
                                        <Row>
                                            {
                                                (isAflacMemberOrBranchManager() || isSystemOwner()) && 
                                                <Col lg="3" xl="3">
                                                    <div className="cmn-bg-box-inr">
                                                        <div className="AS_code_box">
                                                            <label className="font-weight-bold font-14 mb-0" name="AS_code">AS コード</label>
                                                            <Toggle
                                                                activeSlider={activeSlider}
                                                                setActiveSlider={setActiveSlider}
                                                                className="mr-0"
                                                                message="ASコードが選択されていません"
                                                                isStatic={true}
                                                                staticData = {toggle_category}
                                                                className={`m-0 ${styles.mb_toggle}`}
                                                            />
                                                        </div>
                                                            {
                                                                activeSlider === 1?
                                                                <GeneralDropdown
                                                                    isStatic={true}
                                                                    staticData={asCodeData}
                                                                    onSelect={(el) => handleSingleAsCode(el.value,el.textContent)}
                                                                    selectedData={selectedSingleAsCode.length>0 ? selectedSingleAsCode[0].name:""}
                                                                    placeholder="全AS"
                                                                    dropdown_id="ascode_dropdown_single"
                                                                    filter='true' />
                                                                :<MultiselectDropdown
                                                                    selectAll={true}
                                                                    isStatic={true}
                                                                    staticData={asCodeData}
                                                                    selectAllText="全AS"
                                                                    placeholder="全AS"
                                                                    dropdown_id="ascode_dropdown_multi"
                                                                    multiSelectedData={multiselectedASCode} setMultiSelectedData={setMultiSelectedASCode} 
                                                                    activeDropdown={activeDropdown} setActiveDropdown={setActiveDropdown} />
                                                            }
                                                    </div>
                                                </Col>
                                            }
                                            <Col lg="3" xl="3">
                                                <div className="cmn-bg-box-inr">
                                                    <label className="mb-2 font-weight-bold">学習テーマ</label>
                                                    <GeneralDropdown
                                                        isStatic={true}
                                                        staticData={learningTheme}
                                                        onSelect={(el) => handleTheme(el.value,el.textContent)}
                                                        selectedData={selectedLearningTheme && selectedLearningTheme.name}
                                                        placeholder="選択してください"
                                                        dropdown_id="learning_theme_dropdown" />
                                                </div>
                                            </Col>
                                            {
                                                activeSlider === 1 || isTrainerManager()?
                                                <Col lg="3" xl="3">
                                                    <div className="cmn-bg-box-inr">
                                                        <label className="mb-2 font-weight-bold">募集人選択</label>
                                                            <MultiTraineeSelectDropdown
                                                                selectAll={true}
                                                                isStatic={true}
                                                                staticData={trainees}
                                                                selectAllText="全募集人"
                                                                placeholder="選択してください"
                                                                multiSelectedData={multiselectedTrainees} setMultiSelectedData={setMultiSelectedTrainees}
                                                                activeDropdown={traineeActiveDropdown} setActiveDropdown={settraineeActiveDropdown}
                                                                dropdown_id="trainee_dropdown"
                                                                defaultUnchecked={true}
                                                            />
                                                    </div>
                                                </Col>
                                                :""
                                            }
                                            <Col lg="3" xl="3">
                                                <div className="cmn-bg-box-inr">
                                                    <label className="mb-2 font-weight-bold">入社月</label>
                                                        <TimePeriodDropdown 
                                                            data={timePeriodData}
                                                            from={from}
                                                            to={to}
                                                            setFrom={setFrom}
                                                            setTo={setTo}
                                                            selectedTimePeriod={1}
                                                            dropdownPosition={true}
                                                            dropdown_id="time_picker_dropdown"
                                                        />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col  xl="1" className={`back-btn-box  text-right`}>
                                    <BackButton id={autoId()} className="back-btn mb-3 w-100 font-14" title={t('general.button.top')} onClick={() => { history.push('/RecruiterSummary') }} />
                                    </Col>
                                </Row>
                                <Row className="py-4">
                                    <Col lg={6} sm={12}>
                                        <div className="cmn-bg-box-inr">
                                            <label className="mb-2 font-weight-bold">学習テーマ別_{selectedLearningTheme && selectedLearningTheme.name}_実施回数</label>
                                            <div className={`${styles.bar_height}`} ref={ref}>
                                                {props && <BarChartHorizontal {...props} />}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={6} sm={12}>
                                        <div className="cmn-bg-box-inr">
                                            <label className="mb-2 font-weight-bold">学習テーマ別_{selectedLearningTheme && selectedLearningTheme.name}_実施時間</label>
                                            <div className={`${styles.bar_height}`} ref={timeRef}>
                                                {numberProps && <BarChartHorizontal {...numberProps} />}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                {/* <Row className="pb-4">
                                    <Col lg={12}>
                                        <div className="cmn-bg-box-inr">
                                            <label className="mb-2 font-weight-bold">学習テーマ別_{selectedLearningTheme && selectedLearningTheme.name}_実施期間</label>
                                            <div className={`${styles.bar_height}`} ref={periodRef}>
                                                {periodProps && <BarChartHorizontal {...periodProps} />}
                                            </div>
                                        </div>
                                    </Col>
                                </Row> */}
                                <GeneralButton id="export_csv" className="cmn-btn font-weight-normal" title="出力" onClick={exportExcel}/>
                                <CSVExport
                                    data={countData} 
                                    activeSlider={activeSlider} 
                                    filterSelectedASCode={filterSelectedASCode} 
                                    multiselectedTrainees={multiselectedTrainees}
                                    filterTheme={filterTheme}
                                    from={from}
                                    to={to}
                                    excelRef={excelNumberRef}
                                    fileName={`学習テーマ別_${selectedLearningTheme && selectedLearningTheme.name}_実施回数`}
                                ></CSVExport>
                                <TimeCSVExport 
                                    data={timeData} 
                                    activeSlider={activeSlider} 
                                    filterSelectedASCode={filterSelectedASCode} 
                                    multiselectedTrainees={multiselectedTrainees}
                                    filterTheme={filterTheme}
                                    from={from}
                                    to={to}
                                    excelRef={excelTimeRef} 
                                    fileName={`学習テーマ別_${selectedLearningTheme && selectedLearningTheme.name}_実施時間`}
                                ></TimeCSVExport>
                            </div>
                        </Col>
                    </Row>
                </div>
             </div>
        </>
    )
}
const stateToProps = state => {
    return {
        isOpen: state.vAgent.isOpen,
        access_token: state.vAgent.access_token,
        agent_company: state.vAgent.agent_company,
        syncStatus: state.vAgent.syncStatus
    }
}

const dispatchToProps = dispatch => {
    return {
        setRecentPath: (recent_path) => {
            dispatch(setRecentPath(recent_path));
        }
    }
}

export default connect(stateToProps, dispatchToProps)(TimeAndCountLearningTheme);
