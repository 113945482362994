import React, { useState, useEffect,useRef } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Sidebar from '../../../components/Sidebar';
import LogoutButton from '../../../components/Buttons/LogoutButton';
import GeneralTextbox from '../../../components/Textboxes/GeneralTextbox';
import {filterOperation, agencyColumnsData, companyInfoData} from '../../../configs/staticData'
import Breadcrumbs from '../../../components/Breadcrumbs';
import GeneralButton from '../../../components/Buttons/GeneralButton';
import BackButton from '../../../components/Buttons/BackButton';
import Checkbox from '../../../components/Checkbox';
import { FormControlLabel } from '@material-ui/core';
import Pagination from '../../../components/Pagination';
import { GeneralDropdown } from '../../../components/Dropdowns/GeneralDropdown';
import classes from '../AgencyInformation/styles.module.css';
import { isApigwEnv, padLeadingZeros } from '../../../utils/General';
import { isAflacMember, isTrainerManagerOrTrainee } from '../../../configs/constants';

import SearchTextbox from '../../../components/Textboxes/SearchTextBox';
import search_icon from '../../../assets/images/search_icon.png'
import reset_icon from '../../../assets/images/reset_icon.png'
import { getAgencyFormatData, handlerClickDownloadButton, getDifferenceAgency, isAllItemChecked, isItemChecked } from '../Helper';
import agencyInformationService from '../../../api/agencyInformation.service';
import Alert from '../components/Alert';
import store from '../../../store';

const AgencyInformationEdit = ({isOpen,access_token}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { state } = useLocation();
    const [reset, makeAllReset] = useState(false);

    const [searchKeyword, setSearchKeyword] = useState('')
    const [oldData, setOldData] = useState([]);
    const [companyInfoData, setCompanyInfoData] = useState([]);
    const [orgCompanyInfoData, setOrgCompanyInfoData] = useState([]);
    const [reloading, setReloading] = useState(true);
    const [isDeleteFilter, setIsDeleteFilter] = useState(true);
    const [updateAlert, setUpdateAlert] = useState({isAlert : false,message : '',alertType :'alert-success'});
    const [pageNumber, setPageNumber] = useState(0)
    const [filterOperationData, setFilterOperationData] = useState(filterOperation)
    const [selectedFilterOperation, setSelectedFilterOperation] = useState()

    const [itemPerPage, setItemPerPage] = useState(50)
    const pageVisited = pageNumber * itemPerPage;
    const [companyInfo, setCompanyInfo] = useState(companyInfoData.slice(pageVisited,pageVisited + itemPerPage))
    const [checkedItem, setcheckedItem] = useState(state?.stateCheckedItem?state.stateCheckedItem:[])
    const [submitting, setSubmitting] = useState(true)

    useEffect(() => {
        const updatePageVisited = parseInt(pageNumber * itemPerPage);
        const updateLastPage = parseInt(updatePageVisited+parseInt(itemPerPage))
        let tempData = [...companyInfoData]
        tempData = companyInfoData.slice(updatePageVisited, updateLastPage)
        setCompanyInfo(tempData)

    }, [pageNumber,itemPerPage,companyInfoData])

    useEffect(() => {
        window.scrollTo(0, 0)
        if (!isApigwEnv() && (access_token === '' || access_token === undefined)) {
            history.push("/Logout");
        }
        if (isTrainerManagerOrTrainee() || isAflacMember()) {
            makeAllReset(!reset)
            return
        }
    }, []);

    useEffect(() => {
        searchKeyword.length === 0 && setReloading(true);
     }, [searchKeyword]);

    useEffect(() => {
        reloading && agencyInformationService.getAll().then(res =>{
            if (res.status === 200) {
                setReloading(false)
                // setIsDeleteFilter(true); // no need
                let tempResponseData =[];
                res.data.map(item =>{
                    tempResponseData.push({
                        id : item.id,
                        companyCode : padLeadingZeros(item.companyCode,7),
                        companyName : item.companyName,
                        branchCode : item.branchCode,
                        branchName : item.branchName,
                        created : item.created,
                        updated : item.updated
                    })
                })

                setCompanyInfoData(tempResponseData)
                setOldData(tempResponseData)

                let orgData =[];
                res.data.map(item =>{
                    orgData.push({
                        idOrg : item.id,
                        companyCodeOrg : padLeadingZeros(item.companyCode,7),
                        companyNameOrg : item.companyName,
                        branchCodeOrg : item.branchCode,
                        branchNameOrg : item.branchName
                    })
                })
                setOrgCompanyInfoData(orgData)
            }
        })
    }, [reloading]);

    useEffect(() => {
        setUpdateAlert({isAlert : state?.isAlert,message : state?.message,alertType :'alert-success'})
    }, [state])

    

    const checkNumber = (companyCode) => {
        let isnum = /^\d+$/.test(companyCode);
        if (isnum) {
            return Number(companyCode)
        }
    }

    const searchAgencyInformation = () =>{
        // let filterData = [...companyInfoData];
        let filterData = [...oldData];
        
        let search = searchKeyword.toLowerCase();
        let filterItems = {'companyCode' : search,'companyName':search,'branchCode':search, 'branchName' :search}
        if (searchKeyword.length > 0) {
            console.log('filterData',filterData);
            let filtered =  filterData.filter(item => 
                item?.companyCode?.toString().includes(filterItems.companyCode)
                || item?.companyName?.toLowerCase().includes(filterItems.companyName)
                || item?.branchCode?.toLowerCase().includes(filterItems.branchCode)
                || item?.branchName?.toLowerCase().includes(filterItems.branchName)
            );
            console.log('filtered',filtered);
            setCompanyInfoData(filtered)
        }
    }

    const handleCheckboxChange = (index) => (event) =>{
        let findByItem = companyInfo.find(item => item.id === index);
        if (event.target.checked) {
            setcheckedItem([...new Set([...checkedItem,findByItem])])
        } else {
            setcheckedItem([...checkedItem.filter(item => item.id !== index)])
        }
    }

    const handleAllCheckboxChange = (event) =>{
        let currentEventChecked = event.target.checked;
        if (currentEventChecked) {
            setcheckedItem([...new Set([...checkedItem,...companyInfo])])
        } else {
            setcheckedItem([...checkedItem.filter(item => !companyInfo.includes( item ))])
        }
    }

    const handleAgencyName = (index) =>(event) => {
        const cloneAgencyInfo = [...companyInfo];
        cloneAgencyInfo[index].companyName = event.target.value;
        setCompanyInfo(cloneAgencyInfo);
    }   

    const handleDestinationName = (index) =>(event) => {
        const cloneAgencyInfo = [...companyInfo];
        cloneAgencyInfo[index].branchName = event.target.value;
        setCompanyInfo(cloneAgencyInfo);
    }

    const handleDeleteData = () =>{
        history.push({
            pathname: 'agencyInformation/agencyPreviewdelete',
            state: {data:checkedItem,previousPath : history.location.pathname}
          });
    }

    const resetSearch = () =>{
        setSearchKeyword('');
        setReloading(true);
        setItemPerPage(itemPerPage);
        setPageNumber(0);
    }

    const updateData =()=>{
        console.log('original data',orgCompanyInfoData);
        console.log('change data',companyInfoData);

        let dataDiff =  getDifferenceAgency(orgCompanyInfoData,companyInfoData);
        console.log('dataDiff',dataDiff);
        try {
                let data =[];
                dataDiff.length > 0 && dataDiff.map(item =>{
                    data.push({
                        "companyCode": item.companyCode,
                        "companyName": item.companyName,
                        "branchCode": item.branchCode,
                        "branchName": item.branchName
                    })
                })
                console.log('data',data);
                if(submitting){
                    setSubmitting(false)
                    agencyInformationService.updateMultiAgency(data).then(res=>{
                        console.log('res',res);
                        if (res.status === 200) {
                            setSubmitting(true)
                            setUpdateAlert({isAlert : true,message : res.data.message,alertType : 'alert-success'})
                        }
                    }).catch(error =>{
                        // setSubmitting(true)
                        console.log('error',error);
                        if (error.response.status === 400) {
                            setSubmitting(true);
                            setUpdateAlert({isAlert : true,message : error.response.data.message,alertType :'alert-danger'})
                        }
                    })
                }
                else{
                    console.log("Update process not finish")
                }
        } catch (error) {
            console.log('error')
        }
    }

    const exportAll = (event) =>{
        let formatData = getAgencyFormatData(orgCompanyInfoData);
        handlerClickDownloadButton(event,'csv','SJIS',agencyColumnsData,formatData,"Agency Information")
    }

    const exportSelectedData = (event) =>{
        let formatData = getAgencyFormatData(checkedItem);
        handlerClickDownloadButton(event,'csv','SJIS',agencyColumnsData,formatData,"Agency Information")
    }

    const handlePaginationDropdown =(event) =>{
        setItemPerPage(event.target.value)
        setPageNumber(0)
    }

    const editAgency = (agency) =>{
        store.dispatch({type: 'AGENCY_NAME',agencyData : agency})
        history.push(
            {
            pathname :'/agencyInformation/edit_agency_info'
            }
        );
    }

    return(
        <>
            <Sidebar />
            <div id="main-content" className={`${isOpen ? 'content-large' : 'content-small'}`}>
                <div className="main-content-inr maintenanceScreen" id="maintenanceScreen">
                    <Row>
                        <Col lg="12" className="cmn-logout-btn-sec">
                            <h3 className="text-left d-flex justify-content-between">
                            変更する 代理店 を選択
                            </h3>
                            <LogoutButton title={t('general.button.logout')} className={'font-14'} id="logout"/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="mb-3"><Breadcrumbs homeLink="MaintenanceScreen"/></Col>
                        <Col lg="12">
                            <div className="cmn-bg-box">
                                {
                                    updateAlert.isAlert &&
                                    <Alert message = {updateAlert.message} closeHandle ={()=>setUpdateAlert({...updateAlert,isAlert : false})} alertType="alert-success"/>
                                }
                               <Row className="mb-4">                                   
                                    <Col className={`${classes.btn_gp}`}>
                                        <GeneralButton title="インポート" className={`cmn-btn font-weight-normal mr-3 ${classes.btn_w_120}`} onClick={() => history.push('/agencyInformation/import_agency_info')}/>
                                        <BackButton title="エクスポート" className="cmn-btn font-weight-normal w-auto mr-3 mt-md-0 mt-2" onClick={exportAll}/>
                                        <BackButton title="+ 代理店情報 を追加" className="cmn-btn font-weight-normal w-auto mt-md-0 mt-2" onClick={() => history.push('/agencyInformation/add_agency_info')}/>
                                   </Col>
                               </Row>
                               <div className="cmn-bg-box-inr">
                                   <Row className="smallest-padding-box8 mb-4 align-items-center">                                                                        
                                        <Col lg="3" className="mb-2 mb-lg-0">
                                            <SearchTextbox onChange={(e)=>setSearchKeyword(e.target.value)} text={searchKeyword} icon={search_icon} placeholder="" reset_icon={searchKeyword && searchKeyword.length > 0 && reset_icon} resetOnClick={resetSearch} id="inp_search_keyword"  className={`py-1 ${classes.custom_search_btn} ${classes.input_h_36}`}/>
                                        </Col>
                                        <Col lg="2" md="3" className="mb-2 mb-md-0">
                                            <GeneralButton id="company_info_search_btn" className={`cmn-btn font-weight-normal ${classes.btn_h_36}`} title="検索" onClick={searchAgencyInformation}/>
                                        </Col>   
                                        <Col lg="7" md="9" className="text-right">
                                            {
                                                checkedItem.length > 0 ?
                                                <div>
                                                    <GeneralButton id="user_info_keep_btn" className={`cmn-btn font-weight-normal mr-3 ${classes.btn_export} ${classes.btn_h_36}`} title="選択代理店エクスポート" onClick={exportSelectedData}/>
                                                    <GeneralButton id="user_info_keep_btn" className={`cmn-btn font-weight-normal ${classes.btn_keep} ${classes.btn_h_36}`} title="削除" onClick={handleDeleteData}/>
                                                </div>
                                                :
                                                // <GeneralButton id="user_info_keep_btn" className={`cmn-btn font-weight-normal ml-3 ${classes.btn_disabled} ${classes.btn_h_36}`} title="検索" />
                                                <GeneralButton disabled = {submitting ? false : true} id="user_info_keep_btn" onClick={updateData} className={`cmn-btn font-weight-normal ml-3 ${submitting ? "" :classes.btn_disabled} ${classes.btn_h_36}`} title="保存" />
                                            }
                                            
                                        </Col>                                
                                      
                                   </Row>
                                    
                                    <div className="table-responsive">
                                        <table className={`table table-bordered w-100 mb-4`}>
                                            <thead className={`cmn-table ${classes.head_cells}`}>
                                                <tr >
                                                    <th className='text-center py-3'>
                                                        <FormControlLabel className='mx-0' color='primary' control={<Checkbox name={`userInfoCheckAll`} id={`userInfoAll`} checked={isAllItemChecked(companyInfo,checkedItem)?true:false}  onChange={handleAllCheckboxChange}/>} />
                                                    </th>
                                                    <th scope="col" className='align-middle py-3 pl-4'>代理店コード:</th>
                                                    <th scope="col" className='align-middle py-3 pl-4'>代理店名:</th>
                                                    <th scope="col" className='align-middle py-3 pl-4'>出先コード:</th>
                                                    <th scope="col" className='align-middle py-3 pl-4'>出先名:</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    !reloading ? 
                                                    companyInfo.map((item,index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <th scope="row" className='text-center py-1'>
                                                                    <FormControlLabel className='mx-0' color='primary' control={<Checkbox name={`companyInfoCheck${index}`} checked={isItemChecked(item,checkedItem)?true:false}  onChange={handleCheckboxChange(item.id)}/>} />
                                                                </th>
                                                                <td className="pl-4 py-1">
                                                                <span  style={{cursor : 'pointer',color: "#00A5D9",textDecorationLine : 'underline',lineHeight : '21px'}} onClick={()=>editAgency(item)}>{padLeadingZeros(item.companyCode, 7)}</span>
                                                                </td>
                                                                <td className="px-4 py-1">
                                                                    <GeneralTextbox text={item.companyName} onChange={handleAgencyName(index)} className={`${classes.general_text_box_py_8} ${classes.input_h_36} py-1 border-0`}/>
                                                                </td>
                                                                <td className="pl-4 py-1">
                                                                    {item.branchCode}                                                                
                                                                </td>
                                                                <td className="px-4 py-1">
                                                                    <GeneralTextbox text={item.branchName} onChange={handleDestinationName(index)} className={`${classes.general_text_box_py_8} ${classes.input_h_36} py-1 border-0`}/>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr><td colSpan={6} className="text-center">Loading.....</td></tr>
                                                }
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                    <Row>
                                        <Col>
                                            <div className="text-right">
                                                <div className="d-inline-block">
                                                    <Pagination itemPerPage={itemPerPage} totalData={companyInfoData.length} setPageNumber={setPageNumber}/>
                                                </div>  
                                                <label className='ml-2 mr-3 mb-0'>{companyInfoData.length}件 </label>  
                                                <select className={`${classes.paginate_select_box}`} defaultValue={itemPerPage} onChange={handlePaginationDropdown}>
                                                    <option value={10}>10</option>
                                                    <option value={20}>20</option>
                                                    <option value={50}>50</option>
                                                    <option value={100}>100</option>
                                                    <option value={companyInfoData.length}>全て</option>
                                                </select>                                    
                                                {/* <GeneralButton id="user_info_keep_btn" onClick={()=>setItemPerPage(companyInfoData.length)} className={`${classes.paginator_showall} cmn-btn font-weight-normal mb-0`} title="全件表示" ></GeneralButton> */}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

const stateToProps = state => {
    return {
        isOpen: state.vAgent.isOpen,
        access_token: state.vAgent.access_token
    }
}

export default connect(stateToProps,null) (AgencyInformationEdit);