import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { Link, useHistory, Redirect } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Sidebar from '../../../components/Sidebar';
import LogoutButton from '../../../components/Buttons/LogoutButton';
import GeneralTextbox from '../../../components/Textboxes/GeneralTextbox';
import Breadcrumbs from '../../../components/Breadcrumbs';
import ErrorMessage from '../../../components/ErrorMessage';
import GeneralButton from '../../../components/Buttons/GeneralButton';
import { companyInfoData } from '../../../configs/staticData'
import { isApigwEnv, padLeadingZeros } from '../../../utils/General';
import { isAflacMember, isTrainerManagerOrTrainee } from '../../../configs/constants';
import classes from '../AgencyInformation/styles.module.css';
import encoding from "encoding-japanese";
import agencyInformationService from '../../../api/agencyInformation.service';
import Alert from '../components/Alert';

const AgencyImport = ({ isOpen, access_token }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [reset, makeAllReset] = useState(false);
    const [warning, setWarning] = useState(false);
    const [file, setFile] = useState('');
    const hiddenFileInput = useRef(null)
    const [existData, setExistData] = useState([]);
    const [importAgencyData, setImportAgencyData] = useState([]);
    const fileReader = new FileReader();
    const [fileErrorMsg, setFileErrorMsg] = useState(' .csv ファイルを選択してください。');
    const [submitting, setSubmitting] = useState(true)

    useEffect(() => {
        window.scrollTo(0, 0)
        if (!isApigwEnv() && (access_token === '' || access_token === undefined)) {
            history.push("/Logout");
        }
        if (isTrainerManagerOrTrainee() || isAflacMember()) {
            makeAllReset(!reset)
            return
        }
        agencyInformationService.getAll().then(res => {
            if (res.status === 200) {
                var tempArr = [];
                var len = res.data.length;
                for (var i = 0; i < len; i++) {
                    tempArr.push({
                        companyCode: padLeadingZeros(res.data[i].companyCode, 7),
                        companyName: res.data[i].companyName == undefined ? "" : res.data[i].companyName,
                        branchCode: res.data[i].branchCode,
                        branchName: res.data[i].branchName == undefined ? "" : res.data[i].branchName
                    });
                }
                setExistData(tempArr)
            }
        })
    }, []);

    const handleClick = event => {
        hiddenFileInput.current.click();
      };
    const handleChange = event =>{
        setFile(event.target.files[0])
    }

    const checkProperties = (obj)=>{
        for (var key in obj) {
            if (obj[key] !== null && obj[key] != "")
                return false;
        }
        return true;
    }

    const checkEmptyProperties = (obj)=>{
        console.log(obj, "obj")
        for (var key in obj) {
            let tempData = obj[key].replace(/^[ '"]+|[ '"]+$|( ){2,}/g, '')?.trim()
            if (tempData !== null && tempData != "")
                return false;
        }
        return true;
    }

    const csvFileToArray = string => {
        const csvHeader = string.slice(0, string.indexOf("\n")).split(",").map(item => item.trim());
        const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
        for(var item in csvRows){
            if(csvRows[item].split(",").length<4 || csvHeader?.length !== 4){
                if(csvRows[item].split(",").length == 1){
                    console.log(csvRows[item].split(",")[0].trim().length, " dsfsfdsfd")
                    if(csvRows[item].split(",")[0].trim().length !== 0){
                        setFileErrorMsg("不正なCSVファイルです。空白セルや改行が無いかご確認ください。")
                        setWarning(true);
                        return
                    }
                }
                else{
                    setFileErrorMsg("不正なCSVファイルです。空白セルや改行が無いかご確認ください。")
                    setWarning(true);
                    return
                }
                // setFileErrorMsg("不正なCSVファイルです。空白セルや改行が無いかご確認ください。")
                // setWarning(true);
                // return
            }
            else{
                let checkEmptyData =checkEmptyProperties(csvRows[item].split(","))

                if(checkEmptyData){
                    setFileErrorMsg("不正なCSVファイルです。空白セルや改行が無いかご確認ください。")
                    setWarning(true);
                    return
                }
            }
        }

        const array = csvRows.map(i => {
            console.log("i", i)
            if (i !== '') {
                const values = i.split(",");
                const obj = csvHeader.reduce((object, header, index) => {
                    object[header] = values[index]?.trim()
                    return object;
                }, {});
                let checkProp = checkProperties(obj);
                if (!checkProp) {
                    return obj;
                }
            }
        });

        let checkExistImportData = array.every(element => element == undefined)
        if(checkExistImportData){
            setFileErrorMsg("インポートするデータが存在しません。")
            setWarning(true);
        }
        else{
            if(csvHeader.length === 4 && csvHeader.includes("代理店コード") && csvHeader.includes("代理店名") && csvHeader.includes("出先コード") && csvHeader.includes("出先名")){
                if(array.length <= 1501 && array.length > 0){
                    var tempImportArr = [];
                        var len = array.length;
                        for (var i = 0; i < len; i++) {
                            if(array[i] !== undefined){
                                tempImportArr.push({
                                    代理店コード: array[i]?.代理店コード? array[i]?.代理店コード.replace(/^[ '"]+|[ '"]+$|( ){2,}/g, '').trim() : array[i]?.代理店コード,
                                    代理店名: array[i]?.代理店名?array[i].代理店名.replace(/^[ '"]+|[ '"]+$|( ){2,}/g, '').trim() : array[i]?.代理店名,
                                    出先コード: array[i]?.出先コード?array[i].出先コード.replace(/^[ '"]+|[ '"]+$|( ){2,}/g, '').trim() : array[i]?.出先コード,
                                    出先名: array[i]?.出先名?array[i].出先名.replace(/^[ '"]+|[ '"]+$|( ){2,}/g, '').trim() : array[i]?.出先名
                                });
                            }
                        }
                        tempImportArr.length > 0 && setWarning(false)
                    setImportAgencyData(tempImportArr); // test
                }else{
                    setFileErrorMsg("インポート数の上限値である1500を超えているためインポートできません。")
                    setWarning(true);
                }
            }
            else{
                setFileErrorMsg("ヘッダーに項目名が設定されていません。")
                setWarning(true);
            }
        }
    };

    const handleSubmit = async (evt) => {
        evt.preventDefault();

        let fileName =file ? file.name.split(".") : ""
        if(!file || fileName.length > 2 || (file && fileName.pop() != 'csv')){//check if file extension is csv
            setFileErrorMsg(" .csv ファイルを選択してください。")
            setWarning(true);
            return;
        }
        if (file) {
            fileReader.onload = function (event) {
                var codes = new Uint8Array(event.target.result);
                var unicodeArray = encoding.convert(codes, {
                    to: 'UNICODE',
                    from: 'AUTO'
                });
                var codestoString = encoding.codeToString(unicodeArray);
                csvFileToArray(codestoString);
            };

            fileReader.readAsArrayBuffer(file);
        }
    }

    const headerKeys = Object.keys(Object.assign({}, ...importAgencyData));

    const AllFieldEqualCheck = (importItem) => {
        for (const item of existData) {
            if (item.companyCode.toString() === importItem.代理店コード.toString()
                && item.companyName === importItem.代理店名
                && item.branchCode.toString() === importItem.出先コード.toString()
                && item.branchName === importItem.出先名) {
                return true
            }
        }
    }

    const UpdateCheck = (importItem) => {
        for (const item of existData) {
            if (item.companyCode.toString() === importItem.代理店コード.toString() && item.branchCode.toString() === importItem.出先コード.toString()
                && (item.companyName != importItem.代理店名 || item.branchName != importItem.出先名)
                ) {
                return true
            }
        }
    }

    const InsertCheck = (importItem) => {
        for (const item of existData) {
            if ((item.companyCode.toString() != importItem.代理店コード.toString() || item.branchCode.toString() != importItem.出先コード.toString())
                && importItem.代理店コード.length === 7
                && importItem.出先コード.length === 3
                ) {
                return true
            }
        }
    }

    const checkNumber = (importItem) => {
        let isnum = /^\d+$/.test(importItem);
        if (!isnum) {
            return true
        }
    }

    //importData parameter
    const checkExistingData = (importItem) => {
        if (importItem && existData.length > 0) {
            if (importItem.代理店コード === '' || importItem.出先コード === '') {
                return "エラー"
            }
            else if(checkNumber(importItem.代理店コード) || checkNumber(importItem.出先コード)){
                return "エラー"
            }
            else if (importItem.代理店コード.length != 7 || importItem.出先コード.length != 3) {
                return "エラー"
            }
            else if (AllFieldEqualCheck(importItem)) {
                return "スキップ"
            }
            else if (UpdateCheck(importItem)) {
                return "更新"
            }
            else if (InsertCheck(importItem)) {
                return "新規"
            }
        }
    }

    const ShowErrorMessage = (errorMsg, importItem) => {
        if (errorMsg == "スキップ" || errorMsg == "エラー") {
            if (importItem.代理店コード === '') {
                return "代理店コードを記載する必要があります"
            }
            else if(checkNumber(importItem.代理店コード)) {
                return "代理店コードは７桁の数字でなければなりません"
            }
            else if(importItem.代理店コード.length != 7){
                return "代理店コードは７桁の数字でなければなりません"
            }
            else if (importItem.出先コード === '') {
                return "出先コードを記載する必要があります"
            }
            else if(checkNumber(importItem.出先コード)){
                return "出先コードは3桁の数字でなければなりません"
            }
            else if(importItem.出先コード.length != 3) {
                return "出先コードは3桁の数字でなければなりません"
            }else{
                return "変更は発生しませんでした"
            }
        }
        else {
            return "-"
        }
    }

    const importData = ()=>{
        try {
            if(submitting){
                setSubmitting(false)
                agencyInformationService.importAgency(file).then(res =>{
                    if (res.status === 200) {
                        setSubmitting(true)
                        history.push("/agencyInformation",{
                            isAlert : true,
                            message : res.data.message
                        })
                    }
                }).catch(error=>{
                    if(error.response.data.code === 400){
                        setSubmitting(true)
                        setFileErrorMsg(error.response.data.message)
                        setWarning(true);
                    }
                    else
                    {
                        setSubmitting(true)
                        setFileErrorMsg(error.response.data.message)
                        setWarning(true);
                    }
                })
            }
        } catch (error) {
            console.log('error');
        }

    }

    const checkImportEmptyRow = (importItem) => {
        if((importItem?.代理店コード?.length <= 0 || importItem?.代理店コード === undefined) 
        && (importItem?.出先コード?.length <= 0 || importItem?.出先コード === undefined)
        && (importItem?.代理店名?.length <= 0 || importItem?.代理店名 === undefined)
        && (importItem?.出先名?.length <= 0 || importItem?.出先名 === undefined)){
            return false
        }
        else{
            return true
        }
    }

    return (
        <>
            <Sidebar />
            <div id="main-content" className={`${isOpen ? 'content-large' : 'content-small'}`}>
                <div className="main-content-inr maintenanceScreen" id="maintenanceScreen">
                    <Row>
                        <Col lg="12" className="cmn-logout-btn-sec">
                            <h3 className="text-left d-flex justify-content-between">
                                インポート
                            </h3>
                            <LogoutButton title={t('general.button.logout')} className={'font-14'} id="logout" />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="mb-3"><Breadcrumbs homeLink="MaintenanceScreen" /></Col>
                        <Col xl="12">
                            <div className="cmn-bg-box">
                                {
                                    warning &&
                                    <Alert message ={fileErrorMsg} closeHandle ={()=>setWarning(false)} alertType="alert-danger"/>
                                }
                                <div className="cmn-bg-box-inr px-0">
                                    {
                                        !importAgencyData.length > 0 &&
                                        <div className="px-4">
                                            <form onSubmit={handleSubmit}>
                                                <div className="form-group row">
                                                    <label htmlFor="recruiterCodeLabel" className="col-sm-3 col-form-label">インポートするファイル:</label>
                                                    <div className="col-sm-9">
                                                        <div  className={classes.custom_file_input} onClick={handleClick}>
                                                            <span className={classes.custom_choose_file}>ファイルを選択</span>
                                                            <span className='ml-2'>{file ? file.name:'No File Choosen'}</span>
                                                        </div>
                                                        <input
                                                            type="file"
                                                            ref={hiddenFileInput}
                                                            onChange={handleChange}
                                                            className="d-none"
                                                            accept=".csv"
                                                        />
                                                        {/* <GeneralTextbox id="import_company_info" onChange={handleFile} accept=".csv" inputtype={"file"} required={true} className={classes.file_input_btn} /> */}
                                                    </div>
                                                </div>
                                                {
                                                    !importAgencyData.length > 0 &&
                                                    <Row>
                                                        <Col className='text-right'>
                                                            <GeneralButton title="実行" text={file} type="submit" className="cmn-btn font-weight-normal" />
                                                        </Col>
                                                    </Row>
                                                }
                                            </form>
                                        </div>
                                    }

                                    {
                                        importAgencyData.length > 0 &&
                                        <>
                                            {/* <hr className={`${classes.hr_line}`} /> */}
                                            <div className="px-4">
                                                {/* <p>以下の列をインポートします。 代理店コード, 代理店名, 出先コード, 出先名</p> */}
                                                {/* <p>プレビュー</p> */}
                                                <h4 className="mb-3 font-18">プレビュー</h4>
                                                <div className={`cmn-scroll-bar cmn-table-scroll mb-4 ${classes.custom_tb_height}`}>
                                                    <table className="table table-bordered mb-0">
                                                        <thead className="cmn-table">
                                                            <tr >
                                                                <th key={'status_header'}></th>
                                                                {
                                                                    headerKeys.map((key, index) => (
                                                                        <th key={index}>{key}</th>
                                                                    ))
                                                                }
                                                                <th key={'error_msg_header'}>インポートエラー​</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {checkExistingData()}
                                                            {importAgencyData.map((item) => (
                                                                (item && checkImportEmptyRow(item)) &&
                                                                <tr key={item?.id}>
                                                                    <td key={`status`}>
                                                                        {
                                                                            checkExistingData(item)
                                                                        }
                                                                    </td>
                                                                    {item && Object.values(item).map((val, index) => (
                                                                        <td key={index}>{val}</td>
                                                                    ))}
                                                                    <td key={`error_msg`}>
                                                                        {
                                                                            ShowErrorMessage(checkExistingData(item), item)
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div className='text-right'>
                                                    <GeneralButton disabled = {submitting ? false : true} title="インポート実行" type="button" className={`cmn-btn font-weight-normal w-auto px-4 ${submitting ? "" :classes.btn_disabled}`} onClick={importData}/>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

const stateToProps = state => {
    return {
        isOpen: state.vAgent.isOpen,
        access_token: state.vAgent.access_token
    }
}
export default connect(stateToProps, null)(AgencyImport)
