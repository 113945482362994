import React, { useState, useEffect,useRef } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Sidebar from '../../../components/Sidebar';
import LogoutButton from '../../../components/Buttons/LogoutButton';
import GeneralTextbox from '../../../components/Textboxes/GeneralTextbox';
import Breadcrumbs from '../../../components/Breadcrumbs';
import GeneralButton from '../../../components/Buttons/GeneralButton';
import { isAflacMember, isTrainerManagerOrTrainee } from '../../../configs/constants';
import { isApigwEnv } from '../../../utils/General';
import classes from '../UserInformation/styles.module.css';
import encoding from "encoding-japanese";
import userInformationService from '../../../api/userInformation.service';
import { staticRoleData } from '../../../configs/staticData';
import Alert from '../components/Alert';

const UserImport = ({isOpen,access_token}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [reset, makeAllReset] = useState(false);
    const [warning, setWarning] = useState({isAlert : false,message:''});
    const [file, setFile] = useState();
    const hiddenFileInput = useRef(null)
    const [existData, setExistData] = useState([]);
    const [importUserData, setImportUserData] = useState([]);
    const [submitting, setSubmitting] = useState(true);
    const fileReader = new FileReader();
    useEffect(() => {
        window.scrollTo(0, 0)
        if (!isApigwEnv() && (access_token === '' || access_token === undefined)) {
            history.push("/Logout");
        }
        if (isTrainerManagerOrTrainee() || isAflacMember()) {
            makeAllReset(!reset)
            return
        }
        userInformationService.getAll().then(res =>{
            if (res.status === 200) {
                var tempArr = [];
                var len = res.data.length;
                for (var i = 0; i < len; i++) {
                    tempArr.push({
                        branchCode: res.data[i].branchCode,
                        branchName: res.data[i].branchName,
                        companyId: res.data[i].companyId,
                        companyName: res.data[i].companyName,
                        created: res.data[i].created,
                        empId: res.data[i].empId,
                        id: res.data[i].id,
                        roleId: res.data[i].roleId,
                        roleName: res.data[i].roleName,
                        status: res.data[i].status,
                        updated: res.data[i].updated,
                        upn: res.data[i].upn,
                        userName: res.data[i].userName == undefined ? "" : res.data[i].userName
                    });
                }
                console.log(tempArr, "temp arr")
                setExistData(tempArr)
            }
        })
    }, []);
    const handleClick = event => {
        hiddenFileInput.current.click();
      };
    const handleChange = event =>{
        setFile(event.target.files[0])
    }
    const checkProperties = (obj)=>{
        for (var key in obj) {
            if (obj[key] !== null && obj[key] != "")
                return false;
        }
        return true;
    }

    const checkEmptyProperties = (obj)=>{
        console.log(obj, "obj")
        for (var key in obj) {
            let tempData = obj[key].replace(/^[ '"]+|[ '"]+$|( ){2,}/g, '')?.trim()
            if (tempData !== null && tempData != "")
                return false;
        }
        return true;
    }

    const csvFileToArray = string => {
        const csvHeader = string.slice(0, string.indexOf("\n")).split(",").map(item => item.trim());
        const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
        for(var item in csvRows){
            if(csvRows[item].split(",").length<4 || csvHeader?.length !== 4){
                if(csvRows[item].split(",").length == 1){
                    if(csvRows[item].split(",")[0].trim().length !== 0){
                        setWarning({isAlert : true,message : "不正なCSVファイルです。空白セルや改行が無いかご確認ください。"})
                        return
                    }
                }
                else{
                    setWarning({isAlert : true,message : "不正なCSVファイルです。空白セルや改行が無いかご確認ください。"})
                    return
                }

                // setWarning({isAlert : true,message : "不正なCSVファイルです。空白セルや改行が無いかご確認ください。"})
                // return
            }
            else{
                let checkEmptyData =checkEmptyProperties(csvRows[item].split(","))

                if(checkEmptyData){
                    setWarning({isAlert : true,message : "不正なCSVファイルです。空白セルや改行が無いかご確認ください。"})
                    return
                }
            }
        }

        const array = csvRows.map(i => {
          if (i !== '') {
            const values = i.split(",");
            const obj = csvHeader.reduce((object, header, index) => {
              object[header] = values[index]?.trim()
              return object;
            }, {});
              let checkProp = checkProperties(obj);
              if (!checkProp) {
                return obj;   
              }
          }
        });
        let checkExistImportData = array.every(element => element == undefined)
        if(checkExistImportData){
            setWarning({isAlert : true,message : "インポートするデータが存在しません。"})
        }
        else{
            if (array.length <= 1501) {
                if (csvHeader.length ===4 && csvHeader.indexOf("募集人コード") > -1 && csvHeader.indexOf("募集人名") > -1 && csvHeader.indexOf("権限") > -1 && csvHeader.indexOf("UserPrincipalName") > -1) {
                    array.length > 0 && setWarning({...warning,isAlert:false})
                    setImportUserData(array);
                }else{
                    setWarning({isAlert : true,message : "ヘッダーに項目名が設定されていません。"})
                }
            }else{
                setWarning({isAlert : true,message : "インポート数の上限値である1500を超えているためインポートできません。"})
            }
        }
      };
    const handleSubmit = async (evt) => {
        evt.preventDefault();

        let fileName =file ? file.name.split(".") : ""
        if(!file || fileName.length > 2 || (file && fileName.pop() != 'csv')){//check if file extension is csv
            setWarning({isAlert : true,message : ".csv ファイルを選択してください。"})
            return;
         }
        if (file) {
            fileReader.onload = function (event) {
                var codes = new Uint8Array(event.target.result);
                var unicodeArray = encoding.convert(codes, {
                    to: 'UNICODE',
                    from: 'AUTO'
                });
                var codestoString = encoding.codeToString(unicodeArray);
                csvFileToArray(codestoString);
            };

            fileReader.readAsArrayBuffer(file);
        }
    }
    const headerKeys = Object.keys(Object.assign({}, ...importUserData));

    const checkempId = (importItem) =>{
        return existData.some(element => element.empId === importItem.募集人コード)
    }
    const checkUpn = (importItem) =>{
        return existData.some(element => element.upn === importItem.UserPrincipalName)
    }

    const checkUserRole = (importItem) =>{
        let findRoleByName = staticRoleData.find(item => item.name === importItem.権限);
        return findRoleByName ? true:false;
    }

    const equals = (importItem) =>{
        let filterEqual = existData.some(element =>
            element.empId === importItem.募集人コード
            && element.upn === importItem.UserPrincipalName
            && element.userName === importItem.募集人名
            && staticRoleData.filter(roleItem => roleItem.id === element.roleId)[0]?.name === importItem.権限
            )
        return filterEqual;
    }
    const checkExcelempIdDuplicate = (importItem) =>{
        let getDuplicateCount = importUserData.length> 0 ?importUserData.filter(item => item && item.募集人コード === importItem.募集人コード).length:0;
        return getDuplicateCount >1 ?true:false;
    }
    const checkExcelUpnDuplicate = (importItem) =>{
        let getDuplicateCount = importUserData.length> 0 ?importUserData.filter(item => item && item.UserPrincipalName === importItem.UserPrincipalName).length:0;
        return getDuplicateCount >1 ?true:false;
    }
    const checkExistingData =(importItem)=>{
        if (importItem && existData.length >0) {
            if (importItem.募集人コード === "" || importItem.UserPrincipalName === "") {
                // console.log('empId is empty or upn is empty');
                return "error"
            }else if (importItem.権限 === ""|| !checkUserRole(importItem)) {
                // console.log('role is empt or role name is not include in system');
                return "error"
            }else if(equals(importItem)){
                // console.log('all item are same')
                return "skip"
            }else if(checkExcelempIdDuplicate(importItem)){
                // console.log('check empId duplicate')
                return "error"
            }else if(checkExcelUpnDuplicate(importItem)){
                // console.log('check upn duplicate')
                return "error"
            }else if(!checkempId(importItem) && checkUpn(importItem)){
                // console.log('diff empId but upn same');
                return "error"
            }else if(checkempId(importItem) && checkUpn(importItem)){
                //  console.log('empId same and upn same');
                return "error"
            }else{
                if (checkempId(importItem)) {
                    // console.log('empId is exist')
                    return "update";
                }else{
                    // console.log('empId is not exist')
                    return "new";
                }
            }
        }
    }

    const importData = ()=>{
        try {
            setSubmitting(false);
            userInformationService.importUser(file).then(res =>{
                if (res.status === 200) {
                    setSubmitting(true);
                    history.push("/userInformation",{
                        isAlert : true,
                        message : res.data.message
                    })
                }
            }).catch(error=>{
                if (error.response.data.code === 400) {
                    setSubmitting(true);
                    setWarning({isAlert : true,message : error.response.data.message});
                }
            })
        } catch (error) {
            console.log('error');
        }
    }

    const checkImportEmptyRow = (importItem) => {
        if((importItem?.募集人コード?.replace(/^[ '"]+|[ '"]+$|( ){2,}/g, '').trim().length <= 0 || importItem?.募集人コード === undefined) 
        && (importItem?.UserPrincipalName?.replace(/^[ '"]+|[ '"]+$|( ){2,}/g, '').trim().length <= 0 || importItem?.UserPrincipalName === undefined)
        && (importItem?.募集人名?.replace(/^[ '"]+|[ '"]+$|( ){2,}/g, '').trim().length <= 0 || importItem?.募集人名 === undefined)
        && (importItem?.権限?.replace(/^[ '"]+|[ '"]+$|( ){2,}/g, '').trim().length <= 0 || importItem?.権限 === undefined)){
            return false
        }
        else{
            return true
        }
    }

    return(
        <>
            <Sidebar />
            <div id="main-content" className={`${isOpen ? 'content-large' : 'content-small'}`}>
                <div className="main-content-inr maintenanceScreen" id="maintenanceScreen">
                    <Row>
                        <Col lg="12" className="cmn-logout-btn-sec">
                            <h3 className="text-left d-flex justify-content-between">
                            インポート
                            </h3>
                            <LogoutButton title={t('general.button.logout')} className={'font-14'} id="logout"/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="mb-3"><Breadcrumbs homeLink="MaintenanceScreen"/></Col>
                        <Col xl="12">
                            <div className="cmn-bg-box">
                                {
                                    warning.isAlert &&
                                    <Alert message ={warning.message} closeHandle ={()=>setWarning({isAlert:false,message:''})} alertType="alert-danger"/>
                                }
                                <div className="cmn-bg-box-inr px-0">
                                    {
                                        !importUserData.length > 0 &&
                                        <div className="px-4">
                                            <form onSubmit={handleSubmit}>
                                                <div className="form-group row">
                                                    <label htmlFor="empIdLabel" className="col-sm-3 col-form-label">インポートするファイル:</label>
                                                    <div className="col-sm-9">
                                                    <div  className={classes.custom_file_input} onClick={handleClick}>
                                                        <span className={classes.custom_choose_file}>ファイルを選択</span>
                                                        <span className='ml-2'>{file ? file.name:'No File Choosen'}</span>
                                                    </div>
                                                    <input
                                                        type="file"
                                                        ref={hiddenFileInput}
                                                        onChange={handleChange}
                                                        className="d-none"
                                                        accept=".csv"
                                                    />
                                                        {/* <GeneralTextbox id="import_user_info" onChange={handleFile} accept=".csv" inputtype={"file"}  required={true} className={classes.file_input_btn}/> */}
                                                    </div>
                                                </div>
                                                <Row>
                                                    <Col className='text-right'>
                                                        <GeneralButton title="実行" text={file} type="submit" className="cmn-btn font-weight-normal"/>
                                                    </Col>
                                                </Row>
                                            </form>
                                        </div>
                                    }
                                    {
                                        importUserData.length > 0 &&
                                        <>
                                            <div className="px-4">
                                                <h4 className="mb-3 font-18">プレビュー</h4>
                                                <div className={`cmn-scroll-bar table-responsive cmn-table-scroll mb-4 ${classes.custom_tb_height}`}>
                                                <table className="table table-bordered mb-0">
                                                    <thead className="cmn-table">
                                                        <tr>
                                                            <th key={'status_header'}></th>
                                                            {
                                                                headerKeys.map((key,index) => (
                                                                <th key={index}>{key}</th>
                                                                ))
                                                            }
                                                            <th key={'error_msg_header'}>インポートエラー​</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {importUserData.map((item) => (
                                                            (item && checkImportEmptyRow(item)) &&
                                                            <tr key={item?.id}>
                                                                <td key={`status`}>
                                                                    {
                                                                        checkExistingData(item) === "new"?
                                                                            "新規"
                                                                        :checkExistingData(item) === "update"?
                                                                            "更新"
                                                                        :checkExistingData(item) === "error"?
                                                                            "エラー"
                                                                        :"スキップ"
                                                                    }
                                                                </td>
                                                                {item && Object.values(item).map((val,index) => (
                                                                    <td key={index}>{val}</td>
                                                                ))}
                                                                <td key={`error_msg`}>
                                                                    {
                                                                        (checkExistingData(item) === "skip" || checkExistingData(item) === "error")?
                                                                            equals(item)?
                                                                            '-'
                                                                            :item.募集人コード === ""?
                                                                                "募集人コードを記載する必要があります"
                                                                            :item.UserPrincipalName === ""?
                                                                                "UPNを記載する必要があります"
                                                                            :(item.権限 === "" || !checkUserRole(item))?
                                                                                "権限が正しく設定されていません"
                                                                            :checkExcelempIdDuplicate(item)?
                                                                                "「募集人コード」が重複しています"
                                                                            :checkExcelUpnDuplicate(item)?
                                                                                "「UserPrincipalName」が重複しています"
                                                                            :(!checkempId(item) && checkUpn(item))?
                                                                                "同じUPNを使用している募集人が既にいるため"
                                                                            :checkUpn(item)?
                                                                                "同じUPNを使用している募集人が既にいるため"
                                                                            :"-"
                                                                        :"-"
                                                                    }
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                </div>
                                                <div className='text-right'>
                                                    <GeneralButton title="インポート実行" disabled = {submitting ? false : true} type="button" className={`cmn-btn ${submitting? "" :classes.btn_disabled} font-weight-normal w-auto px-4`} onClick={importData}/>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}
const stateToProps = state => {
    return {
        isOpen: state.vAgent.isOpen,
        access_token: state.vAgent.access_token
    }
}
export default connect(stateToProps,null)(UserImport)
