import React, { useState, useEffect,useRef }from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from "react-router-dom";
import Sidebar from '../../../components/Sidebar';
import LogoutButton from '../../../components/Buttons/LogoutButton';
import Breadcrumbs from '../../../components/Breadcrumbs';
import Pagination from '../../../components/Pagination';
import GeneralButton from '../../../components/Buttons/GeneralButton';
import classes from '../AgencyInformation/styles.module.css';
import { isAflacMember, isTrainerManagerOrTrainee } from '../../../configs/constants';
import operationsLogService from '../../../api/operationslog.service';
import { isApigwEnv } from '../../../utils/General';
import {formatDateJP} from '../../../utils/DateFormat';

const DisplayOperationLog = ({isOpen,access_token}) => {
    const { t } = useTranslation();  
    const history = useHistory();
    const [reset, makeAllReset] = useState(false);
    const [operationLogData, setOperationLogData] = useState([]);
    const [pageNumber, setPageNumber] = useState(0)
    const [itemPerPage, setItemPerPage] = useState(50)
    const [totalCount, setTotalCount] = useState(0)
    const pageVisited = pageNumber * itemPerPage;
    const [reloading, setReloading] = useState(true)
    const [operationLogInfo, setOperationLogInfo] = useState(operationLogData.slice(pageVisited,pageVisited + itemPerPage))
    useEffect(() => {
        const updatePageVisited = pageNumber * itemPerPage;
        setOperationLogInfo(operationLogData)
    }, [pageNumber,itemPerPage,operationLogData])

    console.log('pagenum',pageNumber)

    useEffect(() => {
        window.scrollTo(0, 0)
        if (!isApigwEnv() && (access_token === '' || access_token === undefined)) {
            history.push("/Logout");
        }
        if (isTrainerManagerOrTrainee() || isAflacMember()) {
            makeAllReset(!reset)
            return
        }
    }, []);
    useEffect(() => {
        setReloading(true);
        operationsLogService.getAll(itemPerPage,pageNumber+1).then(res =>{
            setReloading(false)
            console.log('res',res);
            setOperationLogData(res.data.operations)
            setTotalCount(res.data.total)
            // setHasMore(false)
        })
    }, [pageNumber,itemPerPage]);
    
    const handlePaginationDropdown =(event) =>{
        setItemPerPage(event.target.value)
        setPageNumber(0)
    }

    // const [count, setCount] = useState({
    //     prev: 0,
    //     next: 10
    //   })
    //   const [hasMore, setHasMore] = useState(true);
    //   const [current, setCurrent] = useState(operationLogData.slice(count.prev, count.next))
    //   const getMoreData = () => {    
    //     if (current.length === operationLogData.length) {
    //       setHasMore(false);
    //       return;
    //     }
    //     setTimeout(() => {
    //       setCurrent(current.concat(operationLogData.slice(count.prev + 10, count.next + 10)))
    //     }, 2000)
    //     setCount((prevState) => ({ prev: prevState.prev + 10, next: prevState.next + 10 }))
    //     console.log("testing getMoredata:")
    //   }
    return(
      
        <>
            <Sidebar />
            <div id="main-content" className={`${isOpen ? 'content-large' : 'content-small'}`}>
                <div className="main-content-inr maintenanceScreen" id="maintenanceScreen">
                    <Row>
                        <Col lg="12" className="cmn-logout-btn-sec">
                            <h3 className="text-left d-flex justify-content-between">
                            表示する操作ログを選択
                            </h3>
                            <LogoutButton title={t('general.button.logout')} className={'font-14'} id="logout"/>
                        </Col>
                    </Row> 
                    <Row>
                        <Col className="mb-3"><Breadcrumbs homeLink="MaintenanceScreen"/></Col>
                        <Col lg="12">                       
                                    <div className="cmn-bg-box">
                                        {/* <Row className='mb-4'>
                                            <Col lg="3" xl="3">
                                                <div className="cmn-bg-box-inr p-3">
                                                    <label className="mb-2 font-weight-bold">操作時刻</label>
                                                        <TimePeriodDropdown 
                                                            data={timePeriodData}
                                                            from={from}
                                                            to={to}
                                                            setFrom={setFrom}
                                                            setTo={setTo}
                                                            selectedTimePeriod={1}
                                                            dropdownPosition={false}
                                                            dropdown_id="time_picker_dropdown"
                                                        />
                                                </div>
                                            </Col>
                                        </Row> */}
                                        <div className="cmn-bg-box-inr">
                                            <div className="table-responsive">                                          
                                                <table className={`table table-bordered w-100 mb-4`}>
                                                    <thead className={classes.head_cells}>
                                                        <tr>                                                
                                                        <th scope="col" width="300" className='align-middle py-3 pl-4 text-nowrap'>操作時刻</th>
                                                        <th scope="col" width="190" className='align-middle py-3 pl-4 text-nowrap' >ユーザー</th>
                                                        <th scope="col" width="190" className='align-middle py-3 pl-4 text-nowrap'>コンテンツタイプ</th>
                                                        <th scope="col" className='align-middle py-3 pl-4 text-nowrap'>操作ログ</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            !reloading ? 
                                                            operationLogInfo.length > 0 && 
                                                            operationLogInfo.map((item,index) => {
                                                                return (
                                                                    <tr key={index}>                                                                
                                                                        <td className='align-middle pl-4 text-nowrap'>
                                                                            {formatDateJP(item.created)}
                                                                        </td>
                                                                        <td className='align-middle pl-4 text-nowrap'>
                                                                            {item.userName}
                                                                        </td>
                                                                        <td className='align-middle pl-4 text-nowrap'>
                                                                            {item.category}                                                                
                                                                        </td>
                                                                        <td className='align-middle pl-4 text-nowrap'>
                                                                            {item.note}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                            :<tr><td colSpan={4} className="text-center">Loading.....</td></tr>
                                                        }
                                                        
                                                    </tbody>
                                                </table>                                           
                                            </div>
                                            <Row>
                                                <Col md={8} lg={12} className="">
                                                <div className="text-right">
                                                    <div className="d-inline-block">
                                                        <Pagination itemPerPage={itemPerPage} totalData={totalCount} setPageNumber={setPageNumber}/>
                                                    </div>        
                                                    <label className='ml-2 mr-3 mb-0'>{totalCount}件 </label>                                    
                                                    {/* <GeneralButton id="user_info_keep_btn" onClick={()=>setItemPerPage(totalCount)} className={`cmn-btn font-weight-normal ${classes.paginator_showall} mb-0`} title="全件表示" ></GeneralButton> */}
                                                    <select className={`${classes.paginate_select_box}`} defaultValue={itemPerPage} onChange={handlePaginationDropdown}>
                                                    <option value={10}>10</option>
                                                    <option value={20}>20</option>
                                                    <option value={50}>50</option>
                                                    <option value={100}>100</option>
                                                    {/* <option value={totalCount}>全て</option> */}
                                                </select>    

                                                    </div>
                                                </Col>
                                                {/* <Col className="text-right">
                                                    <GeneralButton id="user_info_keep_btn" className="cmn-btn font-weight-normal" title="実行" ></GeneralButton>
                                                </Col>  */}
                                            </Row>  
                                        </div>
                                    </div>
                        </Col>
                    </Row>  
                </div>
            </div>
        </>
    )
}

const stateToProps = state => {
    return {
        isOpen: state.vAgent.isOpen,
        access_token: state.vAgent.access_token
    }
}
export default connect(stateToProps,null)(DisplayOperationLog);