import React,{useState,useEffect} from 'react';
import {Typography,Breadcrumbs,Link} from '@material-ui/core';
import {withRouter} from 'react-router-dom';
import store from '../../store';
import { useLocation } from "react-router-dom";
import vectore_icon from '../../assets/images/maintenance_icon/vector_icon.png'
// import {breadCrumbts} from '../../configs/staticData'
import classes from '../../components/Breadcrumbs/styles.module.css'

 const BasicBreadcrumbs = props => {
  const {history,location :{pathname},homeLink,createMode} = props;
  const pathnames = pathname.split("/").filter(x => x);
  const [breadCrumbs, setbreadCrumbs] = useState([]);
  const storeUserData = store.getState().maintenanceScreen.userData;
  const storeAgencyData = store.getState().maintenanceScreen.agencyData;
  const {state} = useLocation();
  useEffect(() => {
    setbreadCrumbs(
      [
        {
            name : "MaintenanceScreen",
            jptext : " メンテナンス画面 "
        },
        {
            name : "userInformation",
            jptext : "ユーザー情報"
        },
        {
            name : "add_user_info",
            jptext : createMode?"ユーザー情報 を追加":"ユーザー情報を変更"
        },
        {
            name : "edit_user_info",
            jptext : `募集人コード "${storeUserData.empId}"`
        },
        {
            name : "import_user_info",
            jptext : " インポート"
        },
        {
            name : "export_user_info",
            jptext : " エクスポート"
        },
        {
            name : "agencyInformation",
            jptext : "代理店情報 "
        },
        {
            name : "add_agency_info",
            jptext : createMode?"代理店 を追加 ":"代理店 を追加 "
        },
        {
          name : "edit_agency_info",
          jptext : `代理店コード "${storeAgencyData?.companyCode}"`
        },
        {
            name : "import_agency_info",
            jptext : " インポート"
        },
        {
            name : "export_agency_info",
            jptext : " エクスポート"
        },
        {
            name: "operationLog",
            jptext: " 操作ログ"
        },
        {
          name:"userPreviewdelete",
          jptext:"選択したユーザー情報を削除"
      },
        {
            name:"agencyPreviewdelete",
            jptext:"選択した代理店情報を削除"
        },
        {
            name:"editpreviewdelete",
            jptext:"選択したユーザー情報を削除"
        },
        {
          name:"agencyeditpreviewdelete",
          jptext:"選択した代理店情報を削除"
      }
      ]
    )
  }, [storeUserData,pathname,createMode])
  
  return (
      <Breadcrumbs aria-label="breadcrumb" separator= {<img src={vectore_icon} />}>
        <Link className={`${classes.link_txt_color}`} onClick={() => history.push(`/${homeLink}`)}>
        ホーム 
        </Link>
        {
          pathnames.map((name,index) => {
            const routeTo = `/${pathnames.slice(0,index+1).join("/")}`;
            const isLast = index === pathnames.length -1 ;
            const jpName = breadCrumbs.length > 0 && breadCrumbs.filter(item => item.name === name)[0].jptext;
            return isLast ?
            <Typography className={`${classes.current_txt_color}`} key={index}> {jpName}</Typography>
            :
            <Link className={`${classes.link_txt_color}`} key={index} onClick={() => history.push(routeTo)}> { jpName } </Link>
          })
        }
        {/* <Link
          underline="hover"
          color="inherit"
          href="/getting-started/installation/"
        >
          メンテナンス画面
        </Link>
        <Typography color="text.primary">ユーザー情報</Typography> */}
      </Breadcrumbs>
  );
}
export default withRouter(BasicBreadcrumbs);
