const initialState = {
    userData:{},
    userSelectedData :[],
    agencyData:{}
}

const maintenanceScreen = (
    state = initialState,
    action
) =>{
    switch (action.type) {
        case "USER_SELECTED_DATA":
            return {...state, userSelectedData:action.userSelectedData};
        case "USER_NAME":
            return {...state, userData:action.userData};
        case "AGENCY_NAME":
        return {...state, agencyData:action.agencyData};
        default:
            return state
    }
}
export default maintenanceScreen;