import service from './service';

const getAll =(limit,pageno) =>{
    return service({
        url : `/backend/maintenance/operations?limit=${limit}&pageNum=${pageno}`,
        method : 'get'
    })
}

const operationsLogsService = {
    getAll
}

export default operationsLogsService;