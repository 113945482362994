import React from 'react';

import './styles.css'

const GeneralButton = ({ onClick, title, className, style, value, id, disabled ,type=''}) => {    
    return (
        <>
            {disabled && disabled === true ?
                <button className={` ${className}`} style={style} onClick={onClick} value={value} id={id} disabled> {title}</button >
                : <button className={` ${className}`} style={style} onClick={onClick} value={value} id={id} type={type}> {title}</button>
            }
        </>
    )
}

export default GeneralButton;