import React, { useState, useEffect,useRef } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Sidebar from '../../components/Sidebar';
import LogoutButton from '../../components/Buttons/LogoutButton';
import Breadcrumbs from '../../components/Breadcrumbs'
import addIcon from '../../assets/images/maintenance_icon/addicon.svg'
import addPersonIcon from '../../assets/images/maintenance_icon/person_add.svg'
import addBusinessIcon from '../../assets/images/maintenance_icon/add_business.svg'
import editIcon from '../../assets/images/maintenance_icon/editicon.svg'
import viewIcon from '../../assets/images/maintenance_icon/viewlogicon.svg'
import classes from '../MaintenanceScreenPage/styles.module.css'
import { isApigwEnv } from '../../utils/General';
import { isAflacMember, isTrainerManagerOrTrainee } from '../../configs/constants';
const MaintenanceScreenPage = ({isOpen,access_token}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [reset, makeAllReset] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0)
        if (!isApigwEnv() && (access_token === '' || access_token === undefined)) {
            history.push("/Logout");
        }
        if (isTrainerManagerOrTrainee() || isAflacMember()) {
            makeAllReset(!reset)
            return
        }
    }, []);
    return(
        <>
            <Sidebar />
            <div id="main-content" className={`${isOpen ? 'content-large' : 'content-small'}`}>
                <div className="main-content-inr maintenanceScreen" id="maintenanceScreen">
                    <Row>
                        <Col lg="12" className="cmn-logout-btn-sec">
                            <h3 className="text-left d-flex justify-content-between">
                            代理店情報の編集
                            </h3>
                            <LogoutButton title={t('general.button.logout')} className={'font-14'} id="logout"/>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                        {/* <Breadcrumbs homeLink="MaintenanceScreen"/> */}
                            <div className="cmn-bg-box ">                                
                                <div className="cmn-bg-box-inr mb-4">
                                    <table className="table table-bordered mb-0">
                                        <thead className="cmn-table table">
                                            <tr>
                                                <th className="pl-4 py-3">メンテナンス画面</th>
                                                <th width={250} colSpan={2} className="text-center py-3">アクション</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td scope="row" className="pl-4">ユーザー情報</td>
                                                <td width={120} class="text-center">
                                                    <Link to={`/userInformation/add_user_info`} className={`font-weight-bold ${classes.link_txt_color}`}>
                                                        {/* <span><img src={addPersonIcon} className={`${classes.img_size}`} alt="add_agency_info" /></span> */}
                                                        <span>+</span>
                                                        <span className={`ml-1 ${classes.text_underline}`}>追加</span>
                                                    </Link>
                                                </td>
                                                <td width={130} class="text-center">
                                                    <Link to={`/userInformation`} className={`font-weight-bold ${classes.link_txt_color}`}>
                                                        {/* <span><img src={editIcon} className={`${classes.img_size}`} alt="add_agency_info" /></span> */}
                                                        <span className={`${classes.text_underline}`}>一覧表示・変更</span>
                                                    </Link>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td scope="row" className="pl-4">代理店情報</td>
                                                <td width={120} class="text-center">
                                                    <Link to={`/agencyInformation/add_agency_info`} className={`font-weight-bold ${classes.link_txt_color}`}>
                                                        {/* <span><img src={addBusinessIcon} className={`${classes.img_size}`} alt="add_agency_info" /></span>追加 */}
                                                        <span>+</span>
                                                        <span className={`ml-1 ${classes.text_underline}`}>追加</span>
                                                    </Link>
                                                </td>
                                                <td width={130} class="text-center">
                                                    <Link to={`/agencyInformation`} className={`font-weight-bold ${classes.link_txt_color}`}>
                                                        {/* <span><img src={editIcon} className={`${classes.img_size}`} alt="add_agency_info" /></span>変更 */}
                                                        <span className={`${classes.text_underline}`}>一覧表示・変更</span>
                                                    </Link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="cmn-bg-box-inr">
                                    <table className="table table-bordered mb-0">
                                        <thead className="cmn-table table">
                                            <tr>
                                                <th className="pl-4 py-3">管理</th>
                                                <th width={250} className="text-center py-3">アクション</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td scope="row" className="pl-4">操作ログ</td>
                                                <td class="text-center">
                                                    <Link to={'/operationLog'} className={`font-weight-bold ${classes.link_txt_color}`}>
                                                        {/* <span><img className={`${classes.img_size}`} src={viewIcon} alt="add_agency_info" /></span>表示 */}
                                                        <span className={`${classes.text_underline}`}>表示</span>
                                                    </Link>
                                                </td>
                                            </tr>                                           
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}
const stateToProps = state => {
    return {
        isOpen: state.vAgent.isOpen,
        access_token: state.vAgent.access_token
    }
}
export default connect(stateToProps,null)(MaintenanceScreenPage)