import React, { useState, useEffect, useReducer } from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import Sidebar from "../../../components/Sidebar";
import { useTranslation } from "react-i18next";
import LogoutButton from "../../../components/Buttons/LogoutButton";
import GeneralButton from "../../../components/Buttons/GeneralButton";
import BackButton from "../../../components/Buttons/BackButton";
import GeneralTextbox from "../../../components/Textboxes/GeneralTextbox";
import Breadcrumbs from "../../../components/Breadcrumbs";
import classes from "../AgencyInformation/styles.module.css";
import ErrorMessage from "../../../components/ErrorMessage";
import { isApigwEnv, padLeadingZeros } from "../../../utils/General";
import {
  isAflacMember,
  isTrainerManagerOrTrainee,
} from "../../../configs/constants";
import agencyInfoApi from "../../../api/agencyInformation.service";
import Alert from "../components/Alert";
import store from '../../../store'

const AgencyInformationCreate = ({ isOpen, access_token }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const  location = useLocation();
  const storeAgencyData = store.getState().maintenanceScreen.agencyData;
  const [reset, makeAllReset] = useState(false);
  let initialState = {
    company_code: "",
    company_name: "",
    branch_Code: "",
    branch_Name: "",
  };
  const formReducer = (state, event) => {
    if (event.reset) {
      return initialState;
    }
    return {
      ...state,
      [event.name]: event.value,
    };
  };
  const [formData, setFormData] = useReducer(formReducer, initialState);
  const [submitting, setSubmitting] = useState(false);

  const [companyCodeRequired, setAgencyCodeRequired] = useState(false);
  const [companyNameRequired, setAgencyNameRequired] = useState(false);
  const [branchCodeRequired, setDestinationCodeRequired] = useState(false);
  const [branchNameRequired, setDestinationNameRequired] = useState(false);
  const [createMode, setCreateMode] = useState(location.pathname.includes("add_agency_info"));
  const [updateAlert, setUpdateAlert] = useState({
    isAlert: false,
    message: "",
  });
  const [errors, setErrors] = useState({
    companyCode: "この項目は必須です。",
    branchCode: "この項目は必須です。",
  });
  const [updateId, setUpdateId] = useState();
  const [checkExisting, setCheckExisting] = useState(false);
  const [oldAgencyData, setOldAgencyData] = useState()

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!isApigwEnv() && (access_token === "" || access_token === undefined)) {
      history.push("/Logout");
    }
    if (isTrainerManagerOrTrainee() || isAflacMember()) {
      makeAllReset(!reset);
      return;
    }
  }, []);

  const handleChange = (event) => {
    const re = /^[0-9\b]+$/;
    let { name, value } = event.target;
    let numeric_input = ["company_code", "branch_Code"];
    if (numeric_input.includes(name)) {
      if (value != "" && !re.test(value)) {
        return false;
      }
      setFormData({
        name: name,
        value: value,
      });
      return;
    }
    setFormData({
      name: name,
      value: value,
    });
  };

  useEffect(() => {
    console.log("store agency dada ", storeAgencyData)
    if (!createMode && storeAgencyData && storeAgencyData?.id) {
        setUpdateId(storeAgencyData?.id);
        setFormData({
          name: "company_code",
          value: padLeadingZeros(storeAgencyData?.companyCode,7),
        });
        setFormData({
          name: "company_name",
          value: storeAgencyData?.companyName,
        });
        setFormData({
          name: "branch_Code",
          value: storeAgencyData?.branchCode,
        });
        setFormData({
          name: "branch_Name",
          value: storeAgencyData?.branchName,
        });
    }
    let data ={
      "companyCode": storeAgencyData?.companyCode,
      "companyName": storeAgencyData?.companyName,
      "branchCode": storeAgencyData?.branchCode,
      "branchName": storeAgencyData?.branchName
    }
    setOldAgencyData(data)
}, [storeAgencyData]);

  useEffect(() => {
      if(formData.company_code.length > 0 && formData.branch_Code.length > 0 && (oldAgencyData.companyCode != formData.company_code || oldAgencyData.branchCode != formData.branch_Code)){
        checkValidation(formData)
      }
      else{
        setCheckExisting(false)
        setAgencyCodeRequired(false);
        setDestinationCodeRequired(false);
      }
  },[formData.company_code, formData.branch_Code])


  const checkValidation = (formData) => {
    try {
      let data = {
        companyCode: formData.company_code,
        branchCode: formData.branch_Code,
      };

      agencyInfoApi
        .checkValidation(data)
        .then((res) => {
          if (res.status === 200) {
            console.log("validation api res" + res);
            setCheckExisting(false)
            setAgencyCodeRequired(false);
            setDestinationCodeRequired(false);
          }
        })
        .catch((error) => {
          console.log("error =>", error.response);
          if (error.response.status === 400) {
            setCheckExisting(true)
            setAgencyCodeRequired(true);
            setDestinationCodeRequired(true);
            setErrors({
              ...errors,
              companyCode: error.response.data.acCode,
              branchCode: error.response.data.acCode,
            });
          }
        });
    } catch (error) {
      console.log("error", error);
    }
  }

  const showUpdateMessage = (data) =>{
    if(oldAgencyData.companyCode === data.companyCode && oldAgencyData.companyName === data.companyName 
        && oldAgencyData.branchCode === data.branchCode && oldAgencyData.branchName === data.branchName){
        return true
    }
    return false
  }

  const checkCompanyCodeUpdate = (data) => {
    if(oldAgencyData.companyCode != data.companyCode){
        return (`代理店コード ${oldAgencyData?.companyCode == undefined ? '' : oldAgencyData?.companyCode} が ${data?.companyCode == undefined ? '' : data?.companyCode} に変更されました。\n`)
    }else{
        return ''
    }
  }

  const checkCompanyNameUpdate = (data) => {
    if(oldAgencyData.companyName != data.companyName){
        return (`代理店名 ${oldAgencyData?.companyName == undefined ? '' : oldAgencyData?.companyName } が ${data?.companyName === undefined ? '' : data?.companyName} に変更されました。\n`)
    }else{
        return ''
    }
  }

  const checkBranchCodeUpdate = (data) => {
    if(oldAgencyData.branchCode != data.branchCode){
      return (`出先コード ${oldAgencyData?.branchCode == undefined ? '' : oldAgencyData?.branchCode } が ${data?.branchCode === undefined ? '' : data?.branchCode} に変更されました。\n`)
    }else{
        return ''
    }
  }

  const checkBranchNameUpdate = (data) => {
    if(oldAgencyData.branchName != data.branchName){
        return (`出先名 ${oldAgencyData?.branchName == undefined? '' : oldAgencyData?.branchName} が ${data?.branchName == undefined? '' : data?.branchName} に変更されました。`)
    }else{
        return ''
    }
  }

  const handleSubmit = (type) => (event) => {
    event.preventDefault();
    let err_arr = {};

    if (formData.company_code.length == "") {
      err_arr.companyCode = "この項目は必須です。";
    } else if (
      formData.company_code.length > 0 &&
      formData.company_code.length != 7
    ) {
      err_arr.companyCode = "代理店コードは７桁の数字でなければなりません。";
    }

    if (formData.branch_Code.length == "") {
      err_arr.branchCode = "この項目は必須です。";
    } else if (
      formData.branch_Code.length > 0 &&
      formData.branch_Code.length != 3
    ) {
      err_arr.branchCode = "出先コードは3桁の数字でなければなりません。";
    }
    setErrors({
      ...errors,
      ...err_arr,
    });

    let companyReqCheck =
      formData.company_code === "" || formData.company_code.length != 7 || checkExisting
        ? true
        : false;
    let branchReqCheck =
      formData.branch_Code === "" || formData.branch_Code.length != 3 || checkExisting
        ? true
        : false;

    setAgencyCodeRequired(companyReqCheck);

    setDestinationCodeRequired(branchReqCheck);

    let data = {
      companyCode: formData.company_code,
      companyName: formData.company_name,
      branchCode: formData.branch_Code,
      branchName: formData.branch_Name,
    };

    if (!companyReqCheck && !branchReqCheck && !checkExisting) {
      //form submit api call
      console.log("create", createMode);
      if (createMode) {
        try {
          agencyInfoApi
            .create(data)
            .then((res) => {
              if (res.status === 200) {
                console.log("create api res" + res);
                if (type === "keep") {
                  history.push('/agencyInformation',{
                    isAlert : true,
                    message : res.data.message
                  })
                  setCreateMode(true);
                } else if (type === "keepandanother") {
                  setFormData({
                    reset: true,
                  });
                  setCreateMode(true);
                  setUpdateAlert({
                    isAlert: true,
                    message: `代理店コード ${
                      data.companyCode + "-" + data.branchCode
                    } を追加しました。別の代理店を以下から追加できます。`,
                  });
                } else {
                  setUpdateId(res.data.id);
                  setCreateMode(false);
                  setUpdateAlert({
                    isAlert: true,
                    message: `代理店コード ${data.companyCode} を追加しました。以下で再度編集できます。`,
                  });
                }
                setOldAgencyData(data)
                setAgencyCodeRequired(false);
                // setAgencyNameRequired(false);
                setDestinationCodeRequired(false);
              }
            })
            .catch((error) => {
              console.log("error =>", error.response);
              if (error.response.status === 400) {
                if (error.response?.data?.acCode) {
                  setAgencyCodeRequired(true);
                  setDestinationCodeRequired(true);
                  setErrors({
                    ...errors,
                    companyCode: error.response?.data?.acCode,
                    branchCode: error.response?.data?.acCode,
                  });
                }
              }
            });
        } catch (error) {
          console.log("error", error);
        }
      } else {
        try {
          // need to pass parameter id
          agencyInfoApi
            .update(data, updateId)
            .then((res) => {
              if (res.status === 200) {
                if (type === "keep") {
                  history.push('/agencyInformation',{
                    isAlert : true,
                    message : res.data.message
                  })
                  setCreateMode(true);
                } else if (type === "keepandanother") {
                  setFormData({
                    reset: true,
                  });
                  setCreateMode(true);
                  setUpdateAlert({isAlert : true,message : showUpdateMessage(data) ? `${res.data?.message}` : `${checkCompanyCodeUpdate(data)} ${checkCompanyNameUpdate(data)} ${checkBranchCodeUpdate(data)} ${checkBranchNameUpdate(data)} 別の代理店情報を以下から追加できます。`})
                  setOldAgencyData(data)
                  history.push(
                    {
                    pathname :'/agencyInformation/add_agency_info'
                    }
                );
                } else {
                  setCreateMode(false);
                  setUpdateAlert({isAlert : true,message : showUpdateMessage(data) ? `${res.data?.message}` : `${checkCompanyCodeUpdate(data)} ${checkCompanyNameUpdate(data)} ${checkBranchCodeUpdate(data)} ${checkBranchNameUpdate(data)}  以下で再度編集できます。`})
                  setOldAgencyData(data)
                }
              }
            })
            .catch((error) => {
              console.log("error =>", error.response);
              if (error.response.status === 400) {
                if (error.response?.data?.acCode) {
                  setAgencyCodeRequired(true);
                  setDestinationCodeRequired(true);
                  setErrors({
                    ...errors,
                    companyCode: error.response?.data?.acCode,
                    branchCode: error.response?.data?.acCode,
                  });
                  // setErrors({...errors,branchCode : error.response?.data?.acCode})
                }else{
                  setAgencyCodeRequired(false);
                  setDestinationCodeRequired(false);
                }
              }
            });
        } catch (error) {
          console.log("error", error);
        }
      }
    }
  };

  const handleDeleteData =() =>{
    let data = [
        storeAgencyData
    ]
    history.push({
        pathname: '/agencyInformation/edit_agency_info/agencyeditpreviewdelete',
        state: {data:data, previousPath : history.location.pathname}
    });
  }

  return (
    <>
      <Sidebar />
      <div
        id="main-content"
        className={`${isOpen ? "content-large" : "content-small"}`}
      >
        <div
          className="main-content-inr maintenanceScreen"
          id="maintenanceScreen"
        >
          <Row>
            <Col lg="12" className="cmn-logout-btn-sec">
              <h3 className="text-left d-flex justify-content-between">
                {createMode ?
                "代理店 を追加"
                :
                "選択した代理店情報を変更"
                }
              </h3>
              <LogoutButton title="ログアウト" className={"font-14"} id="logout" />
            </Col>
          </Row>
          <Row>
            <Col xs="12" className="mb-3">
              <Breadcrumbs homeLink="MaintenanceScreen"  createMode={createMode}/>
            </Col>
            <Col xl="8">
              <div className="cmn-bg-box ">
                {updateAlert.isAlert && (
                  <Alert message = {updateAlert.message} closeHandle ={()=>setUpdateAlert({...updateAlert,isAlert : false})} alertType="alert-success"/>
                )}
                <div className="cmn-bg-box-inr mb-4">
                  <form onSubmit={handleSubmit}>
                    {(companyCodeRequired ||
                      branchCodeRequired) && (
                      <ErrorMessage
                        message={"下記のエラーを修正してください"}
                        className="mb-3"
                      />
                    )}
                    <div className="form-group row">
                      <label
                        htmlFor="companyCodeLabel"
                        className="col-sm-2 col-form-label"
                      >
                        代理店コード:
                      </label>
                      <div className="col-sm-10">
                        <GeneralTextbox
                          text={formData?.company_code}
                          onChange={handleChange}
                          name="company_code"
                          className={`${classes.textinput_size}`}
                          id="company_code"
                        />
                        {companyCodeRequired && (
                          <ErrorMessage message={errors.companyCode} />
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="companyNameLabel"
                        className="col-sm-2 col-form-label"
                      >
                        代理店名:
                      </label>
                      <div className="col-sm-10">
                        <GeneralTextbox
                          text={formData?.company_name}
                          onChange={handleChange}
                          name="company_name"
                          className={`${classes.textinput_size}`}
                          id="company_name"
                        />
                        {/* {companyNameRequired && (
                          <ErrorMessage message={errors.companyName} />
                        )} */}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="branchCodeLabel"
                        className="col-sm-2 col-form-label"
                      >
                        出先コード:
                      </label>
                      <div className="col-sm-10">
                        <GeneralTextbox
                          text={formData?.branch_Code}
                          onChange={handleChange}
                          name="branch_Code"
                          className={`${classes.textinput_size}`}
                          id="branch_Code"
                        />
                        {branchCodeRequired && (
                          <ErrorMessage message={errors.branchCode} />
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="branchNameLabel"
                        className="col-sm-2 col-form-label"
                      >
                        出先名:
                      </label>
                      <div className="col-sm-10">
                        <GeneralTextbox
                          text={formData?.branch_Name}
                          onChange={handleChange}
                          name="branch_Name"
                          className={`${classes.textinput_size}`}
                          id="branch_Name"
                        />
                        {/* {branchNameRequired && (
                          <ErrorMessage
                            message={errors.branchName}
                          />
                        )} */}
                      </div>
                    </div>
                    {submitting ? (
                      <p>Loading...</p>
                    ) : (
                      <Row>
                        <Col className={`${classes.btn_gp} text-md-right`}>
                          <GeneralButton
                            title="保存"
                            type="submit"
                            onClick={handleSubmit("keep")}
                            className="cmn-btn font-weight-normal mr-3"
                          />
                          <BackButton
                            title="保存してもう一つ追加"
                            type="submit"
                            onClick={handleSubmit("keepandanother")}
                            className="cmn-btn font-weight-normal px-3 w-auto mr-3 my-md-0 my-2"
                          />
                          <BackButton
                            title="保存して編集を続ける"
                            type="submit"
                            onClick={handleSubmit("keepandcontinueedit")}
                            className="cmn-btn font-weight-normal px-3 w-auto"
                          />
                        </Col>
                      </Row>
                    )}
                  </form>
                </div>
                {
                    !createMode &&
                    <GeneralButton id="user_info_keep_btn" className={`cmn-btn font-weight-normal ${classes.btn_keep} ${classes.btn_h_36}`} title="削除" onClick={handleDeleteData}/>
                } 
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
const stateToProps = (state) => {
  return {
    isOpen: state.vAgent.isOpen,
    access_token: state.vAgent.access_token,
  };
};
export default connect(stateToProps, null)(AgencyInformationCreate);
