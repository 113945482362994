import React, { useState, useEffect } from 'react';
import MainLogo from '../../assets/images/logo.png';
import styles from './styles.module.css';
import store from '../../store/';
import { environment } from '../../configs/environments'
import { useTranslation } from 'react-i18next';
import { deleteCookie } from '../../utils/General';
import baseUrl from '../../api/baseUrl';
import axios from 'axios';
import EventBus from '../../components/EventBus/index';
import { useHistory } from 'react-router-dom';

const LogoutPage = ({recent_path}) => {
    const { t } = useTranslation();
    const history = useHistory();

    const [firstLine, setFirstLine] = useState();
    const [secondLine, setSecondLine] = useState();
    const [aflacMemberUrl, setAflacMemberUrl] = useState();
    const [otherurl, setOtherUrl] = useState();

    useEffect(() => {

        window.scrollTo(0, 0)
        // if (environment === 'production' || environment === 'staging') {
        //     axios({
        //         url: baseUrl,
        //         method: 'POST',
        //         headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        //         data: 'session_logout'
        //     }).then((response) => {
        //         deleteSessions()
        //         onChanged()
        //         EventBus.dispatch('close_snack')
        //     }).catch((error) => {
        //         EventBus.dispatch('show_snack', { message: 'ログアウト失敗しました。', type: "error" })
        //         onChanged()
        //         // history.push(recent_path);
        //     });
        // } else {
            deleteSessions()
            onChanged()
        // }
    }, [])

    const deleteSessions = () => {
        store.dispatch({ type: 'IS_LOGOUT' , is_logout:true});
        store.dispatch({ type: 'RESET_STORE_VAGENT' });
        store.dispatch({ type: 'RESET_NEW_ROLEPLAY' });
        store.dispatch({ type: 'RESET_NEW_EXAM' });
        store.dispatch({ type: 'RESET_AI_SCORE_RESULT' });
        store.dispatch({ type: 'RESET_EXAM_SCORE_RESULT' });
        store.dispatch({ type: 'resetSpeechToText' });
        store.dispatch({ type: 'RESET_START_CHAT' });        
        deleteCookie('tabCount', '/');
        deleteCookie('aanet-sess-mvp1', '/');         // delete aanet cookie
        deleteCookie('aanet-sess-mvp1', '/apigw/va2roleplay/');

    }
    const onChanged = () => {
        const condition = localStorage.getItem("_condition");
        if (condition === 'logout') {
            setFirstLine('ログアウトしました​。');
            setSecondLine('再度ログインする場合は、下記よりログインしてください。');
        } else if (condition === 'timeout') {
            setFirstLine('セッションがタイムアウトしました.')
            setSecondLine('下記より再度ログインしてください.')
        }

        if (environment === 'staging') {
            setOtherUrl("https://st-a-line.aflac.co.jp/web01/asp/ServletAspMain?contents-id=122")
            setAflacMemberUrl("https://si4ata01.aflac.aflac.co.jp/web01/asp/ServletAspMain?contents-id=122")
        } else if (environment === 'production') {
            setAflacMemberUrl('https://edges.aflac.aflac.co.jp/web01/asp/ServletAspMain?contents-id=122')
            setOtherUrl('https://a-line.aflac.co.jp/web01/asp/ServletAspMain?contents-id=122')
        } else {
            setOtherUrl("https://st-a-line.aflac.co.jp/web01/asp/ServletAspMain?contents-id=122")
            setAflacMemberUrl("https://si4ata01.aflac.aflac.co.jp/web01/asp/ServletAspMain?contents-id=122")
        }
    }

    return (
        <div id={styles.login_page} className={`w-100 px-0 ${styles.main_content}`}>
            <div className={styles.login_page} >
                <div className={styles.center_container}>
                    <img src={'https://picturesquestion640.blob.core.windows.net/va2-roleplay-public/logo.png'} className={styles.logo} alt="mainLogo" id="logo" />
                    <h1 className={`font-16 ${styles.title}`} id="logout_info">{firstLine !== undefined && firstLine}<br />{secondLine !== undefined && secondLine}</h1>
                    <div className='d-flex justify-content-center mb-2'><a href={otherurl === undefined ? "#" : otherurl} className={`font-16 ${styles.link}`} id="other_member">{t('logout.associates')}</a></div>
                    <div className='d-flex justify-content-center mb-2'><a href={aflacMemberUrl === undefined ? "#" : aflacMemberUrl} className={`font-16 ${styles.link}`} id="aflac_member">{t('logout.AF employee')}</a></div>

                </div>
            </div>
        </div>
    )
}

export default LogoutPage;
