import { Bar } from '@nivo/bar'
import React, { useState, useEffect } from 'react';
import styles from './styles.module.css';
import {getChartXAxisValueFormat} from '../../../utils/mapState'

const indexBy = "date";
const TotalLabels = (tickValueText,labelMargin,allData)=>({ bars, yScale,xScale }) => {   // returns a list of total value labels for stacked bars
  
  return bars.map(({ data: { data, indexValue }, x,y, width }, i) => {
    const total = Object.keys(data)          // sum of all the bar values in a stacked bar
      .filter(key => key !== indexBy)        //f ilter out whatever your indexBy value is
      .reduce((a, key) => parseFloat(a) + parseFloat(data[key]), 0);
      const parseIntTotal = parseInt(total);
      const xData = parseIntTotal > 3? xScale(total):45
      const yData = allData.length >2 ?y+50:y+60
    return (
      <g
      transform={`translate(${xData},${yData})`}
      key={`${indexValue}-${i}`}
    >
      <text
        className="font-14 MeiryoRegular"        // add any class to the label here
        y={0}
        x={parseInt(labelMargin) / 2}
        textAnchor="middle"
        alignmentBaseline="central"
        style={{                         // add any style to the label here
          fill: "rgb(51, 51, 51)"
        }}
      >
        合計 : {total.toFixed(1)}{tickValueText}
      </text>
    </g>
    );
  });
};

const BarChartHorizontal = (props) => {
  const legendType = props.type;
  let data = props.data
  let stackPadding  = props.stackPadding
  let tickValueText  = props.tickValueText
  let tickData = props.tickData
  let labelMargin = props.labelMargin
  let isInteractive = props.isInteractive
  const [tickValue, setTickValue] = useState()
  let leftWidth = Math.max(...(data.map(el => el.date.length)));
  leftWidth >= 10?leftWidth = 150:leftWidth = 100;
  useEffect(() => {
    setTickValue(tickData)
  }, [tickData])
  const formatValue = (value,text)=>{
      return value+text;
  }

  const formatLegendValue =(value) => {
    let splitValue = value.split("-");
    if(splitValue[0].length > 10){
      let first = splitValue[0].slice(0,10)
      let second = splitValue[0].slice(10)
      let finalStr = [];
      finalStr.push(
        <tspan x={0} dy={0} key={1}>{first}</tspan>
      )
      finalStr.push(
        <tspan x={0} dy={15} key={2}>{second}</tspan>
      )
      return finalStr;
    }else{
      return splitValue[0];
    }
  }

  const colors = props.colors;
  const labels = props.keys;

  const CustomTooltip = ({id, value,color, data}) => {
    let initializeValue = getChartXAxisValueFormat(data,props.data)
    return (
        <div style={{padding : '5px 12px',backgroundColor : 'white',boxShadow: "7px 5px 8px 0px rgba(0,0,0,0.20)"}}>
            <span style={{padding : '0px 10px',backgroundColor : color,marginRight : '10px'}}></span>
            <strong>{id}-{initializeValue}:{value}</strong>
        </div>
        
    )
}

  return (
    <div className={` ${styles.scrollbar} styled_scrollbar`} id='' >
      <div>
      <Bar
        {...props}
        margin={{ top: 10, right: 30, bottom: legendType === "period"?40:30, left: leftWidth }}
        layers={["grid", "axes", "bars",legendType === "period"?"": TotalLabels(tickValueText,labelMargin,data),"markers", "legends"]}
        indexBy="date"
        padding={stackPadding}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        minValue={0}
        maxValue={tickValue && tickValue[tickValue.length - 1]}
        axisTop={null}
        axisRight={null}
        enableLabel={true}
        enableGridX={true}
        enableGridY={false}
        gridXValues={tickValue}
        label={d => d.value > 0 ?`${legendType === "period"?d.value:d.value.toFixed(1)}${tickValueText}`:""}
        axisBottom={{
          tickSize: 5,
          tickPadding: 0,
          tickRotation: 0,
          legendPosition: 'middle',
          tickValues : tickValue,
          legendOffset: 32,
          format: value => formatValue(value,tickValueText)
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 10,
          tickRotation: 0,
          legendPosition: 'middle',
          legendOffset: -40,
          // format: (val) =>val !== undefined ? val.toLocaleString():""
          format: (val) =>val !== undefined ? formatLegendValue(val):""

        }}
        theme={{
          axis: {
            ticks: {
              line: {
                stroke: "rgba(0, 165, 217, 0.2)"
              },
              text: {
                fontSize: 12,
                fontFamily: 'MeiryoRegular'
              },
              minWidth: 50
            }
          },
          grid: {
            line: {
              stroke: "rgba(0, 165, 217, 0.2)",
            }
          }
        }}
        labelTextColor="white"
        isInteractive={isInteractive}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        tooltip={CustomTooltip}
      />
      {
        legendType === "period"?"":
          <div class={`${styles.list} d-flex justify-content-center flex-wrap`}>    
            {labels && colors && colors.map((color, i) => {
              return (<div class="d-flex mx-3"><div className={`${styles.square} mr-1`} style={{ "background": color }}></div>{labels[i]}</div>)
            }
            )}
          </div>
      }
      </div>
    </div>
  )

}

export default BarChartHorizontal;
